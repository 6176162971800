import { Criticality } from '@dbel/react-commons/types';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';

export interface CriticalityRatingDotProps {
  criticality: Criticality;
}

export const CriticalityRatingDot = styled(CircleIcon, {
  shouldForwardProp: (prop) => (prop as keyof CriticalityRatingDotProps) !== 'criticality',
})<CriticalityRatingDotProps>(
  ({ criticality }) =>
    ({ theme }) =>
      theme.unstable_sx({ fontSize: 'large', color: criticality.color })
);
