import {
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useLazyGetProjectByIdQuery,
} from '@dbel/react-commons/api';
import { feature } from '@turf/turf';
import { ProjectSettings, CreateProjectInput, ProjectAreaGeometry, ProjectType } from '@dbel/shared/types';
import { Polygon } from 'geojson';
import { cloneDeep } from 'lodash';
import { createContext, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useProjectIdFromUrl } from '../../hooks/useProjectIdFromUrl';
import { RoomCalculation } from './DIN18041';
import { DEFAULT_ROOM_CALCULATION, ROOM_ACOUSTIC_CALCULATION_VERSION_1_1_0 } from './DIN18041/types';
import { DEFAULT_ABSORPTION_PRESET } from './DIN18041/types/AborptionPreset';

const SAVE_TIME_OUT = 1 * 1000;

const convertRoomCalculationVersion1ToVersion11 = (rawData: {}) => {
  /* pre 1.1.0 = 1.0 convert CustomAbsorption to a preset */

  if ('ceilingCustomAbsorption' in rawData && 'wallCustomAbsorption' in rawData) {
    const { ceilingCustomAbsorption, wallCustomAbsorption } = rawData;

    const returnObj = {
      ...(rawData as unknown as RoomCalculation),
      ceilingCustomAbsorptionPreset: {
        absorptionValues: ceilingCustomAbsorption,
        name: DEFAULT_ABSORPTION_PRESET.name,
      },
      wallCustomAbsorptionPreset: { absorptionValues: wallCustomAbsorption, name: DEFAULT_ABSORPTION_PRESET.name },

      version: ROOM_ACOUSTIC_CALCULATION_VERSION_1_1_0,
    } as RoomCalculation;
    return returnObj;
  }
  throw Error('migration failed');
};

const migrateRoomCalculation = (rawData: unknown): RoomCalculation => {
  try {
    const rawDataObject = rawData as {};

    if ('version' in rawDataObject) {
      const { version } = rawDataObject;

      if (version === ROOM_ACOUSTIC_CALCULATION_VERSION_1_1_0) {
        return rawDataObject as RoomCalculation;
      }
    } else {
      return convertRoomCalculationVersion1ToVersion11(rawDataObject);
    }
  } catch (e) {
    console.error('error loading Room Acoustic Calculation');
  }
  return rawData as RoomCalculation;
};

export enum RoomCalculationError {
  NO_PROJECT_FOR_ID,
}

export type RoomAcousticCalculatorContextType = {
  roomCalculation: RoomCalculation;
  updateRoomCalculation: (roomCalculation: RoomCalculation) => void;
  createRoomAcousticProject: (name: string) => Promise<string>;
  roomCalculationError: RoomCalculationError;
  isLoadingRoomAcoustic: boolean;
};

export const RoomAcousticCalculatorContext = createContext<RoomAcousticCalculatorContextType | null>(null);

interface RoomAcousticCalculatorProviderProps {
  children: ReactNode;
}

export const RoomAcousticCalculatorProvider = ({ children }: RoomAcousticCalculatorProviderProps) => {
  const [roomCalculation, setRoomCalculation] = useState<RoomCalculation | undefined>(DEFAULT_ROOM_CALCULATION);
  const [roomCalculationError, setRoomCaluclationError] = useState<RoomCalculationError | null>(null);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [createProject] = useCreateProjectMutation();
  const [updateProject] = useUpdateProjectMutation();

  const projectId = useProjectIdFromUrl();

  const [getProjectById, { data: project, isError, isLoading: isLoadingRoomAcoustic }] = useLazyGetProjectByIdQuery();

  const timerRef = useRef(null);

  const saveData = useCallback(
    (updatedRoomCalculation: RoomCalculation) => {
      const newProject = cloneDeep(project);
      const metaData: Record<string, unknown> = {
        ROOM_ACOUSTICS_DATA: updatedRoomCalculation,
      };
      newProject.metaData = metaData;
      updateProject({ projectId: newProject.key, project: newProject }).unwrap();
    },
    [project, updateProject]
  );

  const updateRoomCalculation = useCallback(
    (updatedRoomCalculation: RoomCalculation) => {
      setRoomCalculation(updatedRoomCalculation);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => saveData(updatedRoomCalculation), SAVE_TIME_OUT);
    },
    [saveData]
  );

  useEffect(() => {
    if (isError && projectId !== undefined) {
      setRoomCaluclationError(RoomCalculationError.NO_PROJECT_FOR_ID);
    }
  }, [isError, projectId]);

  useEffect(
    () => () => {
      clearTimeout(timerRef.current);
    },
    []
  );

  useEffect(() => {
    if (project !== undefined) {
      const savedProjectRoomCalculation = project.metaData['ROOM_ACOUSTICS_DATA'] as unknown;
      if (savedProjectRoomCalculation !== undefined && !dataLoaded) {
        const migratedRoomCalculation = migrateRoomCalculation(savedProjectRoomCalculation);
        setRoomCalculation(migratedRoomCalculation);
        setDataLoaded(true);
      }
    }
  }, [project, dataLoaded]);

  // lazy project loading
  useEffect(() => {
    if (project === undefined && projectId !== undefined) {
      const awaitGetProject = async () => {
        await getProjectById(projectId);
      };

      awaitGetProject();
    }
  }, [projectId, getProjectById, project]);

  const createRoomAcousticProject = useCallback(
    async (name: string) => {
      const metaData: Record<string, unknown> = {
        ROOM_ACOUSTICS_DATA: roomCalculation,
      };

      const initialUIState = {
        uiState: undefined,
      } as Pick<ProjectSettings, 'uiState'>;

      const newProject: CreateProjectInput = {
        name,
        boundingBox: feature<Polygon>({
          coordinates: [
            [
              [0, 0],
              [0, 0],
              [0, 0],
              [0, 0],
            ],
          ],
          type: 'Polygon',
        } as ProjectAreaGeometry),
        type: ProjectType.ROOM_ACOUSTICS,
        metaData,
        settings: initialUIState,
      };

      const createdProject = await createProject({ projectInput: newProject }).unwrap();
      return createdProject.key;
    },
    [createProject, roomCalculation]
  );

  return (
    <RoomAcousticCalculatorContext.Provider
      value={{
        roomCalculation,
        updateRoomCalculation,
        createRoomAcousticProject,
        roomCalculationError,
        isLoadingRoomAcoustic,
      }}
    >
      {children}
    </RoomAcousticCalculatorContext.Provider>
  );
};
