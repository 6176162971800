import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export type DrawerPropertiesTabsType = 'PROPERTIES' | 'IMMISSION';

export const CustomTab = styled(Tab)(({ theme }) =>
  theme.unstable_sx({ p: 0, textTransform: 'none', height: '42px', minHeight: '42px', px: 1 })
);

export const CustomTabs = styled(Tabs)(({ theme }) =>
  theme.unstable_sx({
    mt: 0,
    width: '100%',
    borderBottom: 1,
    borderBottomColor: '#2C2E424D',
    height: '42px',
    minHeight: '42px',
  })
);
