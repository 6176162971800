import { Expression } from 'mapbox-gl';

export const HOVER_FEATURE_STATE_ACTIVE: Expression = ['boolean', ['feature-state', 'hover'], false];
export const SELECTED_FEATURE_STATE_ACTIVE: Expression = ['boolean', ['feature-state', 'selected'], false];
export const HIGHLIGHTED_FEATURE_STATE_ACTIVE: Expression = ['boolean', ['feature-state', 'highlighted'], false];

export const FILL_COLOR_FEATURE_STATE_VALUE: Expression = ['string', ['feature-state', 'fillColor'], '#fff'];
export const HEIGHT_FEATURE_STATE_VALUE: Expression = ['number', ['feature-state', 'height'], 0];

export const HAS_FILL_COLOR_FEATURE_STATE: Expression = [
  'boolean',
  ['==', ['typeof', ['feature-state', 'fillColor']], 'string'],
  false,
];

export const ALL_RECEIVERS_ARE_IGNORED_FEATURE_STATE_VALUE: Expression = [
  'boolean',
  ['feature-state', 'allReceiversAreIgnored'],
  false,
];
