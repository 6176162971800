import { NoiseProtectionEvaluation } from '@dbel/react-commons/types';
import { TaLaermAreaType } from '@dbel/shared/types';

export const TaLaermPresets: Record<TaLaermAreaType, NoiseProtectionEvaluation> = {
  INDUSTRIAL: {
    fassade: {
      DAY: { norm: 70, goal: 64, evaluationOffset: -2 },
      NIGHT: { norm: 70, goal: 64, evaluationOffset: -2 },
    },
  },
  COMMERCIAL: {
    fassade: {
      DAY: { norm: 65, goal: 59, evaluationOffset: -2 },
      NIGHT: { norm: 50, goal: 44, evaluationOffset: -2 },
    },
  },
  URBAN: {
    fassade: {
      DAY: { norm: 63, goal: 57, evaluationOffset: -2 },
      NIGHT: { norm: 45, goal: 39, evaluationOffset: -2 },
    },
  },
  CORE_VILLAGE_MIXED: {
    fassade: {
      DAY: { norm: 60, goal: 54, evaluationOffset: -2 },
      NIGHT: { norm: 45, goal: 39, evaluationOffset: -2 },
    },
  },
  RESIDENTIAL_SMALL_HOUSING: {
    fassade: {
      DAY: { norm: 55, goal: 49, evaluationOffset: -2 },
      NIGHT: { norm: 40, goal: 34, evaluationOffset: -2 },
    },
  },
  PURELY_RESIDENTIAL: {
    fassade: {
      DAY: { norm: 50, goal: 44, evaluationOffset: -2 },
      NIGHT: { norm: 35, goal: 29, evaluationOffset: -2 },
    },
  },
  SPA_HOSPITALS_NURSING: {
    fassade: {
      DAY: { norm: 45, goal: 39, evaluationOffset: -2 },
      NIGHT: { norm: 35, goal: 29, evaluationOffset: -2 },
    },
  },
};
