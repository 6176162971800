import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { PROJECT_ITEM_PROPERTIES_PANEL_WIDTH } from '../components/mapbox/panels/ProjectItemPropertiesRightPanel';
import { MainMenu } from '../components/menu/main-menu/MainMenu';
import { RootState, useSelector } from '../store/store';
import { ProductSubscriptionBanner } from './ProductSubscriptionBanner';

export const MAIN_MENU_HEIGHT = 60;

const Header = styled('header')(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.modal - 1,
    p: '10px',
    pointerEvents: 'none',
  })
);

const Main = styled('main')(({ theme }) =>
  theme.unstable_sx({
    pt: `${MAIN_MENU_HEIGHT}px`,
  })
);

export const AllDBelPagesLayout = () => {
  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);

  return (
    <>
      <ProductSubscriptionBanner />
      <Box sx={{ position: 'relative' }}>
        <Header
          sx={{
            right: projectItemForPropertiesPanel !== undefined ? PROJECT_ITEM_PROPERTIES_PANEL_WIDTH : 0,
          }}
        >
          <MainMenu />
        </Header>
        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
};
