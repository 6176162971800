import { FurnishingType } from './Furnishing';
import { MaterialType } from './Materials';

export type Absorption = [number, number, number, number, number, number];

const massiveAbsorption: Absorption = [0.02, 0.02, 0.03, 0.03, 0.04, 0.06]; // DIN G1 #3
const concreteAbsorption: Absorption = [0.01, 0.01, 0.02, 0.02, 0.03, 0.03]; // DIN G1 #9
const woodAbsorption: Absorption = [0.04, 0.04, 0.05, 0.06, 0.06, 0.06]; // DIN G1 #20
const windowAbsorption: Absorption = [0.28, 0.2, 0.11, 0.06, 0.03, 0.02]; // DIN G1 #10
const plasterAbsorption: Absorption = [0.27, 0.17, 0.1, 0.09, 0.11, 0.12]; // DIN G1 #25
const carpetAbsorption: Absorption = [0.02, 0.04, 0.07, 0.19, 0.29, 0.35]; // DIN G1 #14
const linoleumAbsorption: Absorption = [0.02, 0.02, 0.03, 0.03, 0.04, 0.04]; // DIN G1 #19
const parquetAbsorption: Absorption = [0.08, 0.05, 0.06, 0.06, 0.06, 0.06]; // Custom
const plasterPerforatedAbsorption: Absorption = [0.4, 0.45, 0.5, 0.45, 0.4, 0.3]; // DIN G1 #43

const emptyRoomAbsorption: Absorption = [0.05, 0.05, 0.05, 0.05, 0.05, 0.05]; // custom
const partlyFilledRoomAbsorption: Absorption = [0.1, 0.1, 0.1, 0.1, 0.1, 0.1]; // custom
const fullRoomAbsorption: Absorption = [0.2, 0.2, 0.2, 0.2, 0.2, 0.2]; // custom

export enum AbsorberType {
  STANDARD = 'STANDARD',
  CUSTOM = 'CUSTOM',
}

export enum AbsorptionHeight {
  MM65 = 'MM65',
  MM200 = 'MM200',
  MM400 = 'MM400',
}

export const AborptionHeights = [AbsorptionHeight.MM65, AbsorptionHeight.MM200, AbsorptionHeight.MM400];

export const AirAbsorption: Absorption = [0.0, 0.0, 0.0, 0.002, 0.007, 0.026]; // custom
export const PeopleAbsorption: Absorption = [0.05, 0.1, 0.2, 0.35, 0.5, 0.65]; //  People -> Table G2 #5

const MM65Absorption: Absorption = [0.3, 0.55, 0.8, 0.8, 0.7, 0.75]; // custom
const MM200Absorption: Absorption = [0.55, 0.7, 0.75, 0.7, 0.7, 0.75]; // custom
const MM400Absorption: Absorption = [0.6, 0.7, 0.7, 0.75, 0.7, 0.75]; // custom

export const CustomWallAbsorption: Absorption = [0.2, 0.65, 1, 1, 1, 0.95]; // custom

export const MaterialAbsorptions: Record<MaterialType, Absorption> = {
  MASSIVE: massiveAbsorption,
  CONCRETE: concreteAbsorption,
  WOOD: woodAbsorption,
  WINDOW: windowAbsorption,
  PLASTER: plasterAbsorption,
  CARPET: carpetAbsorption,
  LINOLEUM: linoleumAbsorption,
  PARQUET: parquetAbsorption,
  PLASTER_PERFORATED: plasterPerforatedAbsorption,
};

export const FurnishingAbsorption: Record<FurnishingType, Absorption> = {
  EMPTY: emptyRoomAbsorption,
  PARTIAL: partlyFilledRoomAbsorption,
  FULL: fullRoomAbsorption,
};

export const ResultAbsorptions: Record<AbsorptionHeight, Absorption> = {
  MM65: MM65Absorption,
  MM200: MM200Absorption,
  MM400: MM400Absorption,
};
