import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {
  SizedPointEmissionModel,
  SizedAreaEmissionModel,
  SizedEmissionModelSizes,
  sizedEmissionModelValues,
} from '@dbel/shared/emission-models';
import { SoundSource } from '@dbel/shared/types';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useUpdateSoundSourceMutation } from '@dbel/react-commons/api';
import { useTranslation } from 'react-i18next';
import { useProjectIdFromUrl } from '../../../../hooks/useProjectIdFromUrl';
import { openProjectItemPropertiesPanel } from '../../../../store/slices/map';
import { useDispatch } from '../../../../store/store';

export interface PropertyDropDownProps {
  soundSource: SoundSource;
}

export const PropertyDropDown = ({ soundSource }: PropertyDropDownProps) => {
  const projectId = useProjectIdFromUrl();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [updateSoundSource] = useUpdateSoundSourceMutation();

  const onDropDownChanged = useCallback(
    async (item: SelectChangeEvent<'SMALL' | 'MEDIUM' | 'LARGE'>) => {
      const updatedSoundSource = cloneDeep(soundSource);
      (updatedSoundSource.properties.emissionModel as SizedPointEmissionModel | SizedAreaEmissionModel).size = item
        .target.value as SizedEmissionModelSizes;
      await updateSoundSource({ projectId, soundSource: updatedSoundSource });
      dispatch(openProjectItemPropertiesPanel(updatedSoundSource));
    },
    [dispatch, projectId, soundSource, updateSoundSource]
  );

  const { facet, size } = soundSource.properties.emissionModel as SizedPointEmissionModel | SizedAreaEmissionModel;

  return (
    <>
      <FormControl variant="standard" fullWidth>
        <InputLabel shrink>
          {t(`pages.architect.project.drawer.soundSourcePropertiesPanel.${facet}.size.title`)}
        </InputLabel>
        <Select value={size} onChange={onDropDownChanged} displayEmpty>
          {sizedEmissionModelValues.map((item) => (
            <MenuItem key={item} value={item}>
              {t(`pages.architect.project.drawer.soundSourcePropertiesPanel.${facet}.size.values.${item}`)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>&nbsp;</FormHelperText>
      </FormControl>
    </>
  );
};
