import { Button, DialogContentText, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormCreator } from '@dbel/react-commons/components';
import { FormFieldValues, FormField } from '@dbel/react-commons/types';
import { SimpleDialog } from './SimpleDialog';

export interface SimpleDialogWithInputProps {
  title: string;
  body: string;
  open: boolean;
  onClose?: () => void;
  onCallback?: (values: FormFieldValues) => void;
  formField: FormField;
}

const FORM_ID = 'simple_form';

export const SimpleDialogWithInput = ({
  title,
  open,
  body,
  formField,
  onClose,
  onCallback,
}: SimpleDialogWithInputProps) => {
  const [submitButtonActive, setSubmitButtonActive] = useState(false);
  const { t } = useTranslation();

  const formIsValid = useCallback((isValid: boolean) => {
    setSubmitButtonActive(isValid);
  }, []);

  return (
    <SimpleDialog
      title={title}
      open={open}
      onClose={onClose}
      okButton={
        <Button color="primary" type="submit" form={FORM_ID} disabled={!submitButtonActive} sx={{ mr: 2 }}>
          {t('common.buttonLabels.save')}
        </Button>
      }
    >
      <Stack spacing={2} direction="column">
        <DialogContentText>{body}</DialogContentText>
        <FormCreator formId={FORM_ID} formFieldData={formField} formValidator={formIsValid} onSubmit={onCallback} />
      </Stack>
    </SimpleDialog>
  );
};
