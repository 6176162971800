import { TabContext } from '@mui/lab';
import { Divider, Stack } from '@mui/material';
import {
  useGetProjectByIdQuery,
  useDeleteBuildingMutation,
  useUpdateBuildingMutation,
  useGetBuildingsQuery,
} from '@dbel/react-commons/api';
import { DeleteButton } from '@dbel/react-commons/components';
import { cloneDeep } from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Building, Project } from '@dbel/shared/types';
import { useProjectIdFromUrl } from '../../../../hooks/useProjectIdFromUrl';
import { closeProjectItemPropertiesPanel } from '../../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../../store/store';
import { ImmissionsTab } from './ImmissionsTab';
import { CustomTab, CustomTabs, DrawerPropertiesTabsType } from './index';
import { TitleFormField } from './properties-tab/TitleFormField';
import { PropertiesTab } from './PropertiesTab';

export interface BuildingPropertiesPanelProps {
  building: Building;
}

function getBuildingPlaceholder(building: Building, project: Project) {
  // first 10 id digits for OSM buildings, project name for user created buildings
  // TODO: does this make sense?
  return building.properties.source === 'OSM' ? String(building.id).slice(0, 10) : project.name;
}

export const BuildingPropertiesPanel = ({ building }: BuildingPropertiesPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectId = useProjectIdFromUrl();
  const { data: project } = useGetProjectByIdQuery(projectId);

  const mapMode = useSelector((state: RootState) => state.map.mapMode);

  const [deleteBuilding] = useDeleteBuildingMutation();
  const [updateBuilding] = useUpdateBuildingMutation();
  const { data: buildings } = useGetBuildingsQuery(projectId);

  // if the projectItemForPropertiesPanel is not in the buildings list anymore, close panel
  useEffect(() => {
    if (!buildings) return;
    const foundBuilding = buildings.find((item: Building) => item.id === building.id);
    if (!foundBuilding) {
      dispatch(closeProjectItemPropertiesPanel());
    }
  }, [buildings, dispatch, building.id]);

  const [activeTab, setActiveTab] = useState<DrawerPropertiesTabsType>(
    mapMode === 'RESULTS' && !building.properties.isProjectBuilding ? 'IMMISSION' : 'PROPERTIES'
  );
  const handleOnChangeTab = useCallback((_: SyntheticEvent, newActiveTabIndex: DrawerPropertiesTabsType) => {
    setActiveTab(newActiveTabIndex);
  }, []);

  const handleOnClickDeleteButton = useCallback(() => {
    dispatch(closeProjectItemPropertiesPanel());
    deleteBuilding({ projectId, buildingId: building.id });
  }, [building, dispatch, deleteBuilding, projectId]);

  const onTitleFormFieldChanged = useCallback(
    (title: string) => {
      const buildingForUpdate = cloneDeep(building);

      if (title.trim() === '') {
        delete buildingForUpdate.properties.name;
      } else {
        buildingForUpdate.properties.name = title;
      }

      updateBuilding({ projectId, building: buildingForUpdate });

      // TODO: needed?
      //  dispatch(openProjectItemPropertiesPanel(buildingForUpdate));
    },
    [building, projectId, updateBuilding]
  );

  useEffect(() => {
    if (building.properties.isProjectBuilding) {
      setActiveTab('PROPERTIES');
    } else {
      setActiveTab(mapMode === 'RESULTS' ? 'IMMISSION' : 'PROPERTIES');
    }
  }, [building.properties.isProjectBuilding, mapMode]);

  return (
    <>
      <Stack direction="column" spacing={1.5} sx={{ height: '100%', alignItems: 'flex-start' }}>
        <TitleFormField
          feature={building}
          placeHolder={getBuildingPlaceholder(building, project)}
          onSubmit={onTitleFormFieldChanged}
        />

        <Divider />

        <TabContext value={activeTab}>
          <CustomTabs value={activeTab} onChange={handleOnChangeTab}>
            <CustomTab label={t('pages.architect.project.drawer.buildingTab.propertiesTabTitle')} value="PROPERTIES" />

            {!building.properties.isProjectBuilding && (
              <CustomTab label={t('pages.architect.project.drawer.buildingTab.immissionTabTitle')} value="IMMISSION" />
            )}
          </CustomTabs>

          <PropertiesTab feature={building} forTab="PROPERTIES" />
          <ImmissionsTab building={building} forTab="IMMISSION" />
        </TabContext>

        {(mapMode === 'MAP_SURROUNDING' || mapMode === 'PROJECT_BUILDINGS') && (
          <>
            <Divider />

            <DeleteButton
              label={t('pages.architect.project.drawer.removeBuildingButton')}
              onClick={handleOnClickDeleteButton}
            />
          </>
        )}
      </Stack>
    </>
  );
};
