import { ComponentType, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import LoadingScreen from './components/LoadingScreen';
import { AllAccountPagesLayout } from './pages/admin/AllAccountPagesLayout';
import { AllDBelPagesLayout } from './pages/AllDBelPagesLayout';
import { AllPagesLayout } from './pages/AllPagesLayout';
import { AllMapBasedPagesLayout } from './pages/architect/project/AllMapBasedPagesLayout';
import { AllErrorPagesLayout } from './pages/error/AllErrorPagesLayout';
import { AllRoomAcousticsPagesLayout } from './pages/roomAcousticCalculator/AllRoomAcousticsPagesLayout';
import { AllSignUpPagesLayout } from './pages/signup/AllSignUpPagesLayout';
import { AllTelemetryPagesLayout } from './pages/telemetry/AllTelemetryPagesLayout';

const Loadable = (Component: ComponentType<any>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboard pages

export enum EditProjectUrlPathParams {
  PROJECT_ID = 'projectId',
}

// project pages
const ProjectsPage = Loadable(lazy(() => import('./pages/architect/projects/ProjectsPage')));
const CreateProjectPage = Loadable(lazy(() => import('./pages/architect/project/create/CreateProjectPage')));
const EditProjectPage = Loadable(lazy(() => import('./pages/architect/project/edit/EditProjectPage')));
const EditProjectSettingsPage = Loadable(lazy(() => import('./pages/architect/project/settings/SettingsProjectPage')));

// telemetry pages
const LocationListPage = Loadable(lazy(() => import('./pages/telemetry/locationList/LocationListPage')));
const LocationAddPage = Loadable(lazy(() => import('./pages/telemetry/locationAdd/LocationAddPage')));
const LocationDetailsPage = Loadable(lazy(() => import('./pages/telemetry/locationDetails/LocationDetailsPage')));
const DeviceAddPage = Loadable(lazy(() => import('./pages/telemetry/deviceAdd/DeviceAddPage')));
const DeviceListPage = Loadable(lazy(() => import('./pages/telemetry/deviceList/DeviceListPage')));
const DeviceDetailsPage = Loadable(lazy(() => import('./pages/telemetry/deviceDetails/DeviceDetailsPage')));
const ReportListPage = Loadable(lazy(() => import('./pages/telemetry/reportList/ReportListPage')));

// Admin / Account
const OrganisationListPage = Loadable(lazy(() => import('./pages/admin/organisations/OrganisationListPage')));
const OrganisationDetailPage = Loadable(lazy(() => import('./pages/admin/organisations/OrganisationDetailPage')));
const OrganisationAddPage = Loadable(lazy(() => import('./pages/admin/organisations/OrganisationAddPage')));
const AccountDetailPage = Loadable(lazy(() => import('./pages/admin/account/AccountDetailPage')));

const PricingPage = Loadable(lazy(() => import('./pages/signup/PricingPage')));
const CreatingAccountPage = Loadable(lazy(() => import('./pages/signup/CreatingAccountPage')));
const CreateAccountPage = Loadable(lazy(() => import('./pages/signup/CreateAccountPage')));

// error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/error/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/error/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/error/ServerError')));
const PaymentRequired = Loadable(lazy(() => import('./pages/error/PaymentRequired')));
const Forbidden = Loadable(lazy(() => import('./pages/error/Forbidden')));

const RoomAcousticCalculator = Loadable(lazy(() => import('./pages/roomAcousticCalculator/RoomAcousticCalculator')));

const routes = (
  <Routes>
    <Route element={<AllPagesLayout />}>
      <Route
        path="/"
        element={
          <AuthGuard>
            <AllDBelPagesLayout />
          </AuthGuard>
        }
      >
        <Route index element={<Navigate to="projects" />} />

        <Route path="projects" element={<ProjectsPage projectType={['ARCHITECT', 'ROOM_ACOUSTICS']} />} />

        <Route path="architect">
          <Route index element={<Navigate to="projects" />} />
          <Route path="projects" element={<ProjectsPage projectType="ARCHITECT" />} />
          <Route path="project" element={<AllMapBasedPagesLayout />}>
            <Route index element={<Navigate to="../projects" />} />
            <Route path="create" element={<CreateProjectPage />} />
            <Route path={`:${EditProjectUrlPathParams.PROJECT_ID}`} element={<EditProjectPage />}>
              <Route path="settings/*" element={<EditProjectSettingsPage />} />
            </Route>
          </Route>
        </Route>

        <Route path="roomacoustics">
          <Route index element={<Navigate to="projects" />} />
          <Route path="projects" element={<ProjectsPage projectType="ROOM_ACOUSTICS" />} />
          <Route path="project" element={<AllRoomAcousticsPagesLayout />}>
            <Route index element={<Navigate to="../projects" />} />
            <Route path="create" element={<RoomAcousticCalculator />} />
            <Route path={`:${EditProjectUrlPathParams.PROJECT_ID}`} element={<RoomAcousticCalculator />} />
          </Route>
        </Route>

        <Route path="telemetry" element={<AllTelemetryPagesLayout />}>
          <Route index element={<Navigate to="locations" />} />
          <Route path="locations" element={<LocationListPage />} />
          <Route path="location">
            <Route index element={<Navigate to="../locations" />} />
            <Route path="add" element={<LocationAddPage />} />
            <Route path=":locationId" element={<LocationDetailsPage />} />
            <Route path=":locationId/add-device" element={<DeviceAddPage />} />
          </Route>
          <Route path="devices" element={<DeviceListPage />} />
          <Route path="device">
            <Route index element={<Navigate to="../devices" />} />
            <Route path=":deviceId" element={<DeviceDetailsPage />} />
          </Route>
          <Route path="reports" element={<ReportListPage />} />
        </Route>

        <Route path="account/*" element={<AccountDetailPage />} />

        <Route path="admin" element={<AllAccountPagesLayout />}>
          <Route index element={<Navigate to="organisations" />} />
          <Route path="organisations" element={<OrganisationListPage />} />
          <Route path="organisation">
            <Route index element={<Navigate to="../../organisations" />} />
            <Route path="add" element={<OrganisationAddPage />} />
            <Route path=":accountKey" element={<OrganisationDetailPage />} />
          </Route>
        </Route>
        <Route element={<AllSignUpPagesLayout />}>
          <Route path="pricingPage" element={<PricingPage />} />
          <Route path="createAccount" element={<CreateAccountPage />} />
          <Route path="signup" />

          <Route path="creatingAccount" element={<CreatingAccountPage />} />
        </Route>
      </Route>

      <Route path="authentication/login" element={<AuthGuard />} />

      <Route element={<AllErrorPagesLayout />}>
        <Route path="401" element={<AuthorizationRequired />} />
        <Route path="402" element={<PaymentRequired />} />
        <Route path="403" element={<Forbidden />} />
        <Route path="404" element={<NotFound />} />
        <Route path="500" element={<ServerError />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>
  </Routes>
);

export default routes;
