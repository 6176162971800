import { DrawLayerStyle } from '../MapBoxMapDrawLayer';
import { SOUND_SOURCE_COLOR, SOUND_SOURCE_SELECTED_COLOR } from './colors/colors';
import {
  SOUND_SOURCE_LAYER_STYLE_TYPE_LINE,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POINT,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_SELECTED,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON_OUTLINE,
} from './SoundSourcesLayerStyles';

export const SOUND_SOURCES_DRAW_LAYER_STYLE: DrawLayerStyle = [
  { id: 'gl-draw-polygon-fill', ...SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON },
  { id: 'gl-draw-polygon-stroke', ...SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON_OUTLINE },
  { id: 'gl-draw-polygon-line', ...SOUND_SOURCE_LAYER_STYLE_TYPE_LINE },
  {
    id: 'gl-draw-point-inactive',
    ...SOUND_SOURCE_LAYER_STYLE_TYPE_POINT,
    ...{ filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'false'], ['==', 'meta', 'feature']] },
  },
  {
    id: 'gl-draw-point-active',
    ...SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_SELECTED,
    ...{ filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['==', 'meta', 'feature']] },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 8,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 5,
      'circle-color': SOUND_SOURCE_COLOR,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': SOUND_SOURCE_SELECTED_COLOR,
    },
  },
  {
    id: 'gl-draw-point-selected-stroke-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'feature']],
    paint: {
      'circle-radius': 12,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-selected-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'feature']],
    paint: {
      'circle-radius': 8,
      'circle-color': SOUND_SOURCE_SELECTED_COLOR,
    },
  },
];
