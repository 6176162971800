import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

const MainContainer = styled('div')(({ theme }) =>
  theme.unstable_sx({
    height: '100vh',
  })
);

export const AllPagesLayout = () => (
  <>
    <MainContainer>
      <Outlet />
    </MainContainer>
  </>
);
