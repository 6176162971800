import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_PREFIX = 'telemetry';

interface TelemetryState {
  showOnlyLocationsWithActiveSession: boolean;
  showOnlyDevicesWithActiveSession: boolean;
}

const initialState: TelemetryState = {
  showOnlyLocationsWithActiveSession: true,
  showOnlyDevicesWithActiveSession: true,
};

const slice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    resetState: () => initialState,
    setShowOnlyLocationsWithActiveSession: (state, action: PayloadAction<boolean>) => {
      state.showOnlyLocationsWithActiveSession = action.payload;
    },
    setShowOnlyDevicesWithActiveSession: (state, action: PayloadAction<boolean>) => {
      state.showOnlyDevicesWithActiveSession = action.payload;
    },
  },
});

export const {
  resetState: resetTelemetryState,
  setShowOnlyLocationsWithActiveSession,
  setShowOnlyDevicesWithActiveSession,
} = slice.actions;

export default slice.reducer;
