import { DrawLayerStyle } from '../MapBoxMapDrawLayer';
import { PROJECT_PLAN_BORDER_COLOR } from './colors/colors';

export const PROJECT_PLAN_DRAW_LAYER_STYLE: DrawLayerStyle = [
  {
    id: 'plan-polygon-fill',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': 'transparent',
    },
  },
  {
    id: 'plan-polygon-border',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'line-color': PROJECT_PLAN_BORDER_COLOR,
    },
  },
  {
    id: 'plan-rotate-point-icon',
    type: 'symbol',
    filter: ['all', ['==', 'meta', 'midpoint'], ['==', '$type', 'Point'], ['has', 'heading']],
    layout: {
      'icon-image': 'rotatePlanControlIcon',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading'],
    },
  },
  {
    id: 'plan-scale-point-icon',
    type: 'symbol',
    filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['has', 'heading']],
    layout: {
      'icon-image': 'scalePlanControlIcon',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading'],
    },
  },
];
