import { CommerceCharacteristics } from '@dbel/shared/types';
import i18n from 'i18next';

export const formatFrequency = (value: number): string => {
  if (value >= 1000) {
    return `${value / 1000}${i18n.t('common.units.kilo')}`;
  }
  return String(value);
};

export const formatHour = (num: number) => {
  if (num <= 9) {
    return `0${num}:00`;
  }
  return `${num}:00`;
};

export const toFixedNumber = (number: number, digits: number): number => {
  const pow = (0 || 10) ** digits;
  return Math.round(number * pow) / pow;
};

export const toSteppedNumber = (input: number, step: number): number => Math.round(input / step) * step;

export const formatNumberToPercent = (value: number): string => String(value * 100).concat('%');

export const formatCommerceCharacteristics = (commerceProperties: CommerceCharacteristics): [string, string] => {
  const { openingHours } = commerceProperties;

  const formatFromTo = (fromHourValue: number, toHourValue: number): string => {
    if (!fromHourValue || !toHourValue) {
      return i18n.t('pages.architect.project.settings.openingHoursFromToClosed');
    }

    return i18n.t('pages.architect.project.settings.openingHoursFromTo', {
      from: new Date(1, 1, 1, fromHourValue),
      to: new Date(1, 1, 1, toHourValue),
      formatParams: {
        from: { hour: '2-digit', minute: '2-digit' },
        to: { hour: '2-digit', minute: '2-digit' },
      },
    });
  };

  const openingHoursWeekdayText = formatFromTo(openingHours.weekdays?.from, openingHours.weekdays?.to);
  const openingHoursSundayText = formatFromTo(openingHours.sundays?.from, openingHours.sundays?.to);

  return [openingHoursWeekdayText, openingHoursSundayText];
};

export const getInitials = (name = ''): string =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');
