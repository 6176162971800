import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Building, SoundSource } from '@dbel/shared/types';
import { PropertyField } from '@dbel/react-commons/components';

export type TitlePropertyFieldFeatureType = Building | SoundSource;

export interface TitlePropertyFieldProps {
  feature: TitlePropertyFieldFeatureType;
  readonly?: boolean;
}

export const TitlePropertyField = ({ readonly, feature }: TitlePropertyFieldProps) => {
  const { t } = useTranslation();

  const value = useMemo(() => {
    if ('soundSourceType' in feature.properties) {
      const { emissionModel } = feature.properties;
      const translatedTitle = emissionModel
        ? t(`common.soundSourceLabels.${emissionModel.facet}`)
        : t('pages.architect.project.drawer.titleLabel');
      return translatedTitle;
    }
    return feature.properties.name;
  }, [feature, t]);

  return (
    <>
      <PropertyField
        readonly={readonly}
        size="medium"
        label={t('pages.architect.project.drawer.titleLabel')}
        value={value}
        inputPropsSx={{ style: { fontSize: 16, fontWeight: '700' } }}
      />
    </>
  );
};
