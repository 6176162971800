import ReplayIcon from '@mui/icons-material/Replay';
import { Accordion, Box, Button, Stack, Typography } from '@mui/material';
import { Feature } from 'geojson';
import {
  projectReceiversApi,
  useGetBuildingsQuery,
  useGetNoiseGridReceiversQuery,
  useGetNoiseProtectionAreaReceiversQuery,
  useGetProjectByIdQuery,
} from '@dbel/react-commons/api';
import {
  AccordionContent,
  AccordionHeader,
  DropDown,
  DropDownItem,
  MapBoxOverlayContainer,
  NoiseEmissionsResultsLayer,
  OutsideNoiseResultsLayer,
  ReceiversLayer,
} from '@dbel/react-commons/components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Building, RatingLevelEvaluation } from '@dbel/shared/types';
import { useReceiverHooks } from '@dbel/react-commons/hooks';
import { CriticalityTimeSpan } from '@dbel/react-commons/types';
import { cloneDeep } from 'lodash';
import { sumDecibel } from '@dbel/shared/util';
import { LineChart } from '@mui/x-charts/LineChart';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import BuildingsAndWallsLayer from '../layers/BuildingsAndWallsLayer';
import SoundSourcesLayer from '../layers/SoundSourcesLayer';
import { ResultsSummary } from '../panels/ResultsSummary';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import {
  closeProjectItemPropertiesPanel,
  openProjectItemPropertiesPanel,
  openReceiverDetailsInPropertiesPanel,
  setOutsideNoiseCriticalityTimeSpan,
} from '../../../store/slices/map';
import { CriticalityTimeSpanSwitcher } from '../../criticality/CriticalityTimeSpanSwitcher';
import { useTelemetryHook } from './useTelemetryHook';
import { useReceiverTools } from '../../../hooks/useReceiverTools';
import { TaLaermPresets } from '../../../NoiseProtectionEvaluationPresets';

const ISO_BAND_STEPS_WIDTH_IN_DB = 1;
const ISO_BAND_STEPS_MAX_DB_VALUE = 150;

const getIsoBandBreakPoints = () => [
  0,
  ...Array.from(Array(ISO_BAND_STEPS_MAX_DB_VALUE).keys(), (n) => ISO_BAND_STEPS_WIDTH_IN_DB * n),
];

const showCaseSoundSourceIds = [
  '8ac8c0fa-370b-4fb5-b287-5f6a1493a30d',
  'bb8c516c-bb2a-485d-afe0-ccab01baeac8',
  '2db9bbde-8e40-410c-8ef7-25d184f21f54',
  '1aae3d2a-b631-45db-be14-49233705f254',
];
const showCaseProjectIDs = [
  '37d7ef00-feb5-4286-861e-84c29bc722bf',
  'd0a1a93d-6e00-47fb-b8f8-e189805fb2dc',
  'd428d310-c0bc-4f98-b6cb-fb3a7e7314b6',
  'da5cdb16-b973-42d6-8026-7cd73a7c748b',
];

const SHOWCASE_RERENDER_TIMEOUT = 950;
const DEFAULT_RERENDER_TIMEOUT = 2500;

const ratingLevelKey: RatingLevelEvaluation['key'] = 'rating-level';

export const ResultsMapMode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectId: string = useProjectIdFromUrl();

  const isShowCaseProject = showCaseProjectIDs.includes(projectId); // this is for showcasing DNM

  const appMode = useSelector((state: RootState) => state.map.appMode);
  const viewMode = useSelector((state: RootState) => state.map.viewMode);
  const { accountKey, token } = useSelector((state: RootState) => state.account.user);
  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);
  const outSideNoiseCriticalityTimeSpan = useSelector((state: RootState) => state.map.outsideNoiseCriticalityTimeSpan);
  const noiseEmissionsCriticalityTimeSpan = useSelector(
    (state: RootState) => state.map.noiseEmissionsCriticalityTimeSpan
  );
  const focusedReceiverIdInPropertiesPanel = useSelector(
    (state: RootState) => state.map.focusedReceiverIdInPropertiesPanel
  );

  const { data: noiseProtectionAreaReceivers } = useGetNoiseProtectionAreaReceiversQuery(projectId);
  const { data: buildings } = useGetBuildingsQuery(projectId);
  const { data: project } = useGetProjectByIdQuery(projectId);
  const { data: noiseGridReceivers } = useGetNoiseGridReceiversQuery(projectId);

  const { initValue, values, status, devices, selectedDevice, setSelectedDevice } = useTelemetryHook(
    isShowCaseProject,
    projectId,
    accountKey,
    token
  );
  const { handleOnUpdateReceiverAndRecalculate } = useReceiverHooks(projectId);
  const { getInfluencersForReceiver } = useReceiverTools();

  const currentTimeSpan = outSideNoiseCriticalityTimeSpan === 'DAY' ? 'day' : 'night';

  const [lockGrid, setLockGrid] = useState(false);

  const handleClickFeature = useCallback(
    (feature?: Feature) => {
      if (feature) {
        dispatch(openProjectItemPropertiesPanel(feature));
      } else {
        dispatch(closeProjectItemPropertiesPanel());
      }
    },
    [dispatch]
  );

  const criticalityTimeSpanChanged = useCallback(
    (criticalityTimeSpan: CriticalityTimeSpan) => {
      dispatch(setOutsideNoiseCriticalityTimeSpan(criticalityTimeSpan));
    },
    [dispatch]
  );

  const handleClickReceiver = useCallback(
    (clickedReceiver: Feature) => {
      if (!clickedReceiver) {
        dispatch(openReceiverDetailsInPropertiesPanel(undefined));
        return;
      }
      const receiver = noiseProtectionAreaReceivers.find((r) => r.id === clickedReceiver.id);

      const buildingForReceiver = buildings.find(
        (building: Building) => building.properties.key === receiver.properties.relations.buildingKey
      );

      // TODO: ignore receiver which do not have an associated valid building until BE returns correct data
      if (!buildingForReceiver) return;

      dispatch(openProjectItemPropertiesPanel(buildingForReceiver));
      dispatch(openReceiverDetailsInPropertiesPanel(String(clickedReceiver.id)));
    },
    [buildings, dispatch, noiseProtectionAreaReceivers]
  );

  const handleOnClickRecalculate = useCallback(() => {
    switch (appMode) {
      case 'NOISE_EMISSIONS':
        dispatch(projectReceiversApi.util.invalidateTags(['NoiseProtectionAreaReceiver']));
        break;
      case 'OUTSIDE_NOISE':
        dispatch(projectReceiversApi.util.invalidateTags(['NoiseGridReceiver']));
        break;
      default:
    }
  }, [appMode, dispatch]);

  useEffect(() => {
    if (!isShowCaseProject) return () => {};

    setLockGrid(true);
    const timer = setTimeout(() => {
      setLockGrid(false);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [isShowCaseProject, noiseGridReceivers]);

  const mappedNoiseGridReceivers = useMemo(() => {
    if (!noiseGridReceivers) return [];
    if (!isShowCaseProject) return noiseGridReceivers;

    if (lockGrid) return [];
    if (!values || values.length === 0) return noiseGridReceivers;

    const mappedReceiver = noiseGridReceivers?.map((receiver) => {
      const clonedReceiver = cloneDeep(receiver);

      const influencer = getInfluencersForReceiver(clonedReceiver, currentTimeSpan);
      // init Value is the first measuring value
      const diff = initValue - values.at(-1);

      const replaceValueInInfluencerList = influencer.map((item) => {
        if (showCaseSoundSourceIds.includes(item.soundSourceKey)) {
          return { soundSourceKey: item.soundSourceKey, ratingLevel: item.ratingLevel - diff };
        }
        return item;
      });
      const sum = sumDecibel(...replaceValueInInfluencerList.map((item) => item.ratingLevel));

      const ratingLevels = receiver.properties.evaluations?.[ratingLevelKey] as
        | RatingLevelEvaluation['resultType']
        | undefined;

      const clonedRatingLevels = cloneDeep(ratingLevels);
      clonedRatingLevels[currentTimeSpan] = sum;
      clonedReceiver.properties.evaluations[ratingLevelKey] = clonedRatingLevels;

      return clonedReceiver;
    });
    return mappedReceiver;
  }, [noiseGridReceivers, lockGrid, isShowCaseProject, values, getInfluencersForReceiver, currentTimeSpan, initValue]);

  // MARK: Mapped Telemetry devices
  const mappedDevices: DropDownItem[] = useMemo(() => {
    if (!devices) return [];
    return devices.map((item) => ({ value: item.client_id, label: item.client_id } as DropDownItem));
  }, [devices]);

  // MARK: Mapped devices
  const selectDeviceById = (id: string) => {
    const foundDevice = devices.find((item) => item.client_id === id);
    if (foundDevice) {
      setSelectedDevice(foundDevice);
    }
  };

  return (
    <>
      <Button
        onClick={handleOnClickRecalculate}
        startIcon={<ReplayIcon />}
        sx={{
          position: 'absolute',
          top: 70,
          left: 70,
          zIndex: 1000,
          textTransform: 'uppercase',
        }}
      >
        {t('pages.architect.project.summaryPanel.recalcButtonLabel')}
      </Button>

      {appMode === 'NOISE_EMISSIONS' && viewMode === '2D' && (
        <>
          <ResultsSummary />
          <SoundSourcesLayer receivers={noiseProtectionAreaReceivers} />
          <NoiseEmissionsResultsLayer
            taLaermPresets={TaLaermPresets}
            viewMode={viewMode}
            projectId={projectId}
            criticalityTimeSpan={noiseEmissionsCriticalityTimeSpan}
            selectedFeatureId={projectItemForPropertiesPanel?.id}
            handleClickFeature={handleClickFeature}
            noiseProtectionEvaluation={TaLaermPresets[project.settings.taLaerm.defaultAreaType]}
          />
          <ReceiversLayer
            taLaermPresets={TaLaermPresets}
            projectId={projectId}
            selectedFeature={projectItemForPropertiesPanel}
            criticalityTimeSpan={noiseEmissionsCriticalityTimeSpan}
            selectedReceiverId={focusedReceiverIdInPropertiesPanel}
            handleClickFeature={handleClickReceiver}
            onUpdateReceiver={handleOnUpdateReceiverAndRecalculate}
            noiseProtectionEvaluation={TaLaermPresets[project.settings.taLaerm.defaultAreaType]}
            isEditable
          />
        </>
      )}

      {appMode === 'NOISE_EMISSIONS' && viewMode !== '2D' && (
        <>
          <ResultsSummary />
          <SoundSourcesLayer isEditable={false} />
          <NoiseEmissionsResultsLayer
            viewMode={viewMode}
            projectId={projectId}
            taLaermPresets={TaLaermPresets}
            criticalityTimeSpan={noiseEmissionsCriticalityTimeSpan}
            selectedFeatureId={projectItemForPropertiesPanel?.id}
            handleClickFeature={handleClickFeature}
            noiseProtectionEvaluation={TaLaermPresets[project.settings.taLaerm.defaultAreaType]}
          />
        </>
      )}

      {appMode === 'OUTSIDE_NOISE' && (
        <>
          <OutsideNoiseResultsLayer
            projectId={projectId}
            gridConfig={{ breakpoints: getIsoBandBreakPoints(), timeSpan: outSideNoiseCriticalityTimeSpan }}
            receivers={mappedNoiseGridReceivers}
            waittimeAfterDownload={isShowCaseProject ? SHOWCASE_RERENDER_TIMEOUT : DEFAULT_RERENDER_TIMEOUT}
          />
          <SoundSourcesLayer projectSoundSources={false} />
          <BuildingsAndWallsLayer
            isEditable={false}
            semiTransparentBuildings={false}
            surroundingBuildingsClickable={false}
          />
          <Stack direction="column" spacing={2} sx={{ position: 'absolute', top: 120, left: 10, width: 300 }}>
            <MapBoxOverlayContainer sx={{ overflow: 'hidden' }}>
              <Box sx={{ p: 1 }}>
                <Accordion defaultExpanded>
                  <AccordionHeader>{t('pages.architect.project.panels.criticalElementsTitle')}</AccordionHeader>
                  <AccordionContent>
                    <CriticalityTimeSpanSwitcher
                      criticalityTimeSpan={outSideNoiseCriticalityTimeSpan}
                      modus="DAY_NIGHT"
                      onChange={criticalityTimeSpanChanged}
                    />
                  </AccordionContent>
                </Accordion>
              </Box>
            </MapBoxOverlayContainer>
          </Stack>
          {isShowCaseProject && (
            <MapBoxOverlayContainer sx={{ overflow: 'hidden', position: 'absolute', bottom: 30, left: 10 }}>
              <Typography sx={{ fontSize: 10, ml: 2, mt: 2 }}>Status:{status}</Typography>
              <DropDown
                data={mappedDevices}
                selectedItem={selectedDevice?.client_id ?? ''}
                inputLabel="Selected Device"
                onChangeValue={selectDeviceById}
                sx={{ ml: 2, mt: 2, width: 470 }}
              />
              <LineChart
                series={[
                  {
                    data: values,
                  },
                ]}
                width={500}
                height={250}
                sx={{ m: 0, p: 0 }}
              />
            </MapBoxOverlayContainer>
          )}
        </>
      )}
    </>
  );
};
