import { LayerStyle } from '@dbel/react-commons/types';
import { COMMON_FILL_OPACITY, COMMON_OUTLINE_OPACITY } from './expressions/commonExpressions';
import {
  SOUND_SOURCE_FILL_BORDER_COLOR,
  SOUND_SOURCE_FILL_COLOR,
  SOUND_SOURCE_HIGHLIGHTED_SYMBOL_OPACITY,
  SOUND_SOURCE_SELECTED_SYMBOL_OPACITY,
  SOUND_SOURCE_SYMBOL_OPACITY,
} from './expressions/soundSourceExpressions';

export const SOUND_SOURCE_LAYER_STYLE_TYPE_POINT: LayerStyle = {
  type: 'symbol',
  filter: ['==', '$type', 'Point'],
  layout: {
    'icon-image': 'soundSourceSymbol',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': [0, -14],
  },
  paint: {
    'icon-opacity': SOUND_SOURCE_SYMBOL_OPACITY,
  },
};

export const SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_SELECTED: LayerStyle = {
  type: 'symbol',
  filter: ['==', '$type', 'Point'],
  layout: {
    'icon-image': 'soundSourceSymbolSelected',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': [0, -14],
  },
  paint: {
    'icon-opacity': SOUND_SOURCE_SELECTED_SYMBOL_OPACITY,
  },
};

export const SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_HIGHLIGHTED: LayerStyle = {
  type: 'symbol',
  filter: ['==', '$type', 'Point'],
  layout: {
    'icon-image': 'soundSourceSymbolHighlighted',
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'icon-offset': [0, -14],
  },
  paint: {
    'icon-opacity': SOUND_SOURCE_HIGHLIGHTED_SYMBOL_OPACITY,
  },
};

export const SOUND_SOURCE_LAYER_STYLE_TYPE_LINE: LayerStyle = {
  type: 'line',
  filter: ['==', '$type', 'LineString'],
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': SOUND_SOURCE_FILL_COLOR,
    'line-width': 4,
    'line-opacity': COMMON_FILL_OPACITY,
  },
};

export const SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON: LayerStyle = {
  type: 'fill',
  filter: ['==', '$type', 'Polygon'],
  paint: {
    'fill-color': SOUND_SOURCE_FILL_COLOR,
    'fill-opacity': COMMON_FILL_OPACITY,
  },
};

export const SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON_OUTLINE: LayerStyle = {
  type: 'line',
  filter: ['==', '$type', 'Polygon'],
  layout: {
    'line-cap': 'round',
    'line-join': 'round',
  },
  paint: {
    'line-color': SOUND_SOURCE_FILL_BORDER_COLOR,
    'line-width': 2,
    'line-opacity': COMMON_OUTLINE_OPACITY,
  },
};
