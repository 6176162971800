import { Button, Snackbar, Stack, Typography } from '@mui/material';
import { LineString, transformScale, length } from '@turf/turf';
import { AnyLayer } from 'mapbox-gl';
import {
  useGetImageFeatureQuery,
  useUpdateImageFeatureMutation,
  useDeleteImageFeatureMutation,
  dBelApiConfig,
} from '@dbel/react-commons/api';
import {
  useMapBox,
  MapBoxImageSource,
  MapBoxMapLayer,
  MapBoxOverlayContainer,
  MapBoxAlertMessage,
} from '@dbel/react-commons/components';
import { FormField, FormFieldValues } from '@dbel/react-commons/types';
import { Feature } from 'geojson';
import i18n from 'i18next';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isImageFeature } from '@dbel/shared/types';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { ReactComponent as ScalePlanIcon } from '../../../icons/toolbars/ScalePlan.svg';
import {
  resetMapDrawMode,
  setProjectPlanEditable,
  setScaleModeProjectPlan,
  setUploadProjectPlanDialogOpen,
  switchToMapDrawMode,
} from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import { SimpleDialogWithInput } from '../../dialogs/SimpleDialogWithInput';
import UploadPlanDialog from '../../dialogs/UploadPlanDialog';
import { ProjectPlanTransparencyToolbar } from '../toolbars/ProjectPlanTransparencyToolbar';
import { DEFAULT_PLAN_OPACITY, PROJECT_PLAN_FEATURE_ID } from './index';
import MapBoxMapDrawLayer from './MapBoxMapDrawLayer';
import { PROJECT_PLAN_DRAW_LAYER_STYLE } from './styles/ProjectPlanDrawLayerStyles';
import { PROJECT_PLAN_LAYER_STYLE } from './styles/ProjectPlanLayerStyles';
import { SCALE_PLAN_DRAW_LAYER_STYLE } from './styles/ScalePlanDrawLayerStyles';
import { layerIds, sourceIds } from '../../../mapBoxOptions';

const resizeFormField: FormField = {
  name: 'length',
  type: 'number',
  label: i18n.t('common.form.length'),
  valueUnit: 'm',
  validations: ['number', 'required'],
  params: {
    required: i18n.t('errors.formRequired', { property: i18n.t('common.form.length') }),
  },
  input: { textAlign: 'right' },
};

export const ProjectPlanLayer = () => {
  const { mapBox } = useMapBox();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { accountKey } = useSelector((state: RootState) => state.account.user);

  const uploadProjectPlanDialogOpen = useSelector((state: RootState) => state.map.uploadProjectPlanDialogOpen);
  const projectPlanEditable = useSelector((state: RootState) => state.map.projectPlanEditable);
  const scaleModeProjectPlan = useSelector((state: RootState) => state.map.scaleModeProjectPlan);

  const projectId = useProjectIdFromUrl();
  const { data: planImageFeature } = useGetImageFeatureQuery(projectId);
  const [updateImageFeature] = useUpdateImageFeatureMutation();
  const [deleteImageFeature] = useDeleteImageFeatureMutation();

  const planImageUrl = useMemo<string | undefined>(() => {
    if (planImageFeature) {
      return `${dBelApiConfig.endpointBaseUrl}/model/v1/account/${accountKey}/project/${projectId}/media/${planImageFeature.properties.fileName}`;
    }
    return undefined;
  }, [accountKey, planImageFeature, projectId]);

  const [planOpacityDuringSliderDragNDrop, setPlanOpacityDuringSliderDragNDrop] =
    useState<number>(DEFAULT_PLAN_OPACITY);
  const [planCoordsDuringDragNDrop, setPlanCoordsDuringDragNDrop] = useState<number[][] | undefined>();
  const [scaleLineFeature, setScaleLineFeature] = useState<Feature | undefined | null>();
  const [displayScaleDialogOpen, setDisplayScaleDialogOpen] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);

  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    if (!planImageFeature) return;

    setPlanOpacityDuringSliderDragNDrop(planImageFeature.properties.opacity);
    setPlanCoordsDuringDragNDrop(planImageFeature.geometry.coordinates[0]);
  }, [planImageFeature]);

  useEffect(() => {
    if (isMouseDown) return; // no updating when mouse is down

    if (projectPlanEditable && planImageFeature) {
      dispatch(
        switchToMapDrawMode({
          mode: 'tx_poly',
          selectedFeatureId: PROJECT_PLAN_FEATURE_ID,
          options: {
            canTrash: false,
            canScale: true,
            canRotate: true,
            singleRotationPoint: true,
            canSelectFeatures: false,
          },
        })
      );
    } else if (scaleModeProjectPlan) {
      dispatch(switchToMapDrawMode({ mode: 'draw_line_string' }));
    } else if (!planImageFeature) {
      dispatch(resetMapDrawMode());
    }
  }, [dispatch, isMouseDown, planImageFeature, projectPlanEditable, scaleModeProjectPlan]);

  const handleOnUpdateFeature = useCallback((feature: Feature) => {
    if (feature.geometry.type !== 'Polygon') return;
    setPlanCoordsDuringDragNDrop(feature.geometry.coordinates[0]);
  }, []);

  const handleOnUpdateFeatureDebounced = useCallback(
    (feature: Feature) => {
      if (!isImageFeature(feature)) return;
      updateImageFeature({ projectId, imageFeature: feature });
    },
    [projectId, updateImageFeature]
  );

  useEffect(() => {
    const onMouseDown = () => {
      setIsMouseDown(true);
    };

    const onMouseUp = () => {
      setIsMouseDown(false);
    };

    if (projectPlanEditable) {
      mapBox.on('mousedown', onMouseDown);
      mapBox.on('mouseup', onMouseUp);
    }

    return () => {
      mapBox.off('mousedown', onMouseDown);
      mapBox.off('mouseup', onMouseUp);
    };
  }, [mapBox, projectPlanEditable]);

  useEffect(() => {
    mapBox.setPaintProperty(layerIds.ProjectPlan, 'raster-opacity', planOpacityDuringSliderDragNDrop);
  }, [mapBox, planOpacityDuringSliderDragNDrop]);

  const handleChangingPlanOpacity = useCallback((newOpacity: number) => {
    setPlanOpacityDuringSliderDragNDrop(newOpacity);
  }, []);

  const handlePlanOpacityChanged = useCallback(
    (newOpacity: number) => {
      const updatedPlanImageFeature = cloneDeep(planImageFeature);
      updatedPlanImageFeature.properties.opacity = newOpacity;
      updateImageFeature({ projectId, imageFeature: updatedPlanImageFeature });
    },
    [planImageFeature, projectId, updateImageFeature]
  );

  const handleDeleteFeature = useCallback(async () => {
    await deleteImageFeature({ projectId, imageFeatureId: PROJECT_PLAN_FEATURE_ID });

    dispatch(setProjectPlanEditable(false));
  }, [deleteImageFeature, dispatch, projectId]);

  useEffect(() => {
    if (projectPlanEditable && planImageFeature && !planImageFeature?.properties.visible) {
      const updatedPlanImageFeature = cloneDeep(planImageFeature);
      updatedPlanImageFeature.properties.visible = true;
      updateImageFeature({ projectId, imageFeature: updatedPlanImageFeature });
    }
  }, [planImageFeature, projectId, projectPlanEditable, updateImageFeature]);

  useEffect(() => {
    mapBox.setLayoutProperty(
      layerIds.ProjectPlan,
      'visibility',
      planImageFeature?.properties.visible ? 'visible' : 'none'
    );
  }, [mapBox, planImageFeature?.properties.visible]);

  // sort layers incluis DrawLayer otherwise the drawlayer disappears under the image
  useEffect(() => {
    if (!mapBox.getLayer(layerIds.ProjectPlan) || !mapBox.getLayer(layerIds.Buildings)) return;

    const mapBoxLayers = mapBox.getStyle().layers;
    const projectPlanIndex = mapBoxLayers.findIndex((search: AnyLayer) => search.id === layerIds.ProjectPlan);
    const buildingsIndex = mapBoxLayers.findIndex((search: AnyLayer) => search.id === layerIds.Buildings);
    if (buildingsIndex <= projectPlanIndex) {
      mapBox.moveLayer(layerIds.ProjectPlan, layerIds.Buildings);
    }
  }, [mapBox]);

  const handleUploadPlanDialogClose = useCallback(() => {
    dispatch(setUploadProjectPlanDialogOpen(false));
  }, [dispatch]);

  const handleOnCreateScaleLineFeature = useCallback((feature: Feature) => {
    if ((feature.geometry as LineString).coordinates.length !== 2) {
      (feature.geometry as LineString).coordinates = (feature.geometry as LineString).coordinates.slice(0, 2);
    }
    setScaleLineFeature(feature);
    setDisplayScaleDialogOpen(true);
  }, []);

  const handleOnDeleteScaleLineFeature = useCallback(() => {
    setScaleLineFeature(undefined);
  }, []);

  const handleOnUpdateScaleLineFeature = useCallback((feature: Feature) => {
    if ((feature.geometry as LineString).coordinates.length !== 2) {
      (feature.geometry as LineString).coordinates = (feature.geometry as LineString).coordinates.slice(0, 2);
    }
    setScaleLineFeature(feature);
  }, []);

  const handleOnClickCalculate = useCallback(() => {
    setDisplayScaleDialogOpen(true);
  }, []);

  const closeScaleDialog = useCallback(() => {
    setDisplayScaleDialogOpen(false);
  }, []);

  const handleOnClickChangeModes = useCallback(() => {
    dispatch(setProjectPlanEditable(false));
    dispatch(resetMapDrawMode());
    dispatch(setScaleModeProjectPlan(true));
  }, [dispatch]);

  const calculateScale = useCallback(
    (values: FormFieldValues) => {
      setDisplayScaleDialogOpen(false);
      const lineLength = length(scaleLineFeature) * 1000;
      const inputLength = values[resizeFormField.name] as number;
      const scaleFactor = inputLength / lineLength;
      const feature = cloneDeep(planImageFeature);
      feature.geometry = transformScale(planImageFeature.geometry, scaleFactor);
      updateImageFeature({ projectId, imageFeature: feature });
      setScaleLineFeature(undefined);
      dispatch(setProjectPlanEditable(true));
      dispatch(setScaleModeProjectPlan(false));
      setSnackBarOpen(true);
    },
    [scaleLineFeature, planImageFeature, updateImageFeature, projectId, dispatch]
  );

  const handleSnackBarClose = useCallback(() => {
    setSnackBarOpen(false);
  }, [setSnackBarOpen]);

  const scaleButtonDisabled = scaleLineFeature && (scaleLineFeature.geometry as LineString).coordinates.length === 2;

  return (
    <>
      <MapBoxImageSource id={sourceIds.ProjectPlan} imageUrl={planImageUrl} coords={planCoordsDuringDragNDrop} />
      <MapBoxMapLayer
        id={layerIds.ProjectPlan}
        sourceId={sourceIds.ProjectPlan}
        layerStyle={PROJECT_PLAN_LAYER_STYLE}
      />
      {projectPlanEditable && planImageFeature && (
        <MapBoxMapDrawLayer
          useSelectOnlyMode
          data={planImageFeature}
          layerStyle={PROJECT_PLAN_DRAW_LAYER_STYLE}
          onDeleteFeature={handleDeleteFeature}
          onUpdateFeature={handleOnUpdateFeature}
          onUpdateFeatureDebounced={handleOnUpdateFeatureDebounced}
        >
          <Stack
            direction="row"
            sx={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <ProjectPlanTransparencyToolbar
              planOpacity={planOpacityDuringSliderDragNDrop}
              onChangingOpacity={handleChangingPlanOpacity}
              onChangedOpacity={handlePlanOpacityChanged}
            />
            <MapBoxOverlayContainer sx={{ ml: 2 }}>
              <Button
                sx={{ alignItems: 'center', mt: '5px', py: 1, pl: 2, pr: 2 }}
                variant="text"
                onClick={handleOnClickChangeModes}
                startIcon={<ScalePlanIcon />}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 600,
                  }}
                >
                  {t('pages.architect.project.projectPlan.switchToScalingButtonLabel')}
                </Typography>
              </Button>
            </MapBoxOverlayContainer>
          </Stack>
        </MapBoxMapDrawLayer>
      )}

      {scaleModeProjectPlan && !projectPlanEditable && (
        <MapBoxMapDrawLayer
          data={scaleLineFeature}
          layerStyle={SCALE_PLAN_DRAW_LAYER_STYLE}
          onCreateFeature={handleOnCreateScaleLineFeature}
          onDeleteFeature={handleOnDeleteScaleLineFeature}
          onUpdateFeature={handleOnUpdateScaleLineFeature}
          openProjectPropertiesOnClick={false}
        >
          <MapBoxAlertMessage
            severity="info"
            sx={{ position: 'absolute', top: 100, left: '50%', transform: 'translateX(-50%)' }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>
              {t('pages.architect.project.projectPlan.scalePlanInfoHeaderText')}
            </Typography>
            {t('pages.architect.project.projectPlan.scalePlanInfoBodyText')}
          </MapBoxAlertMessage>
          <MapBoxOverlayContainer
            sx={{
              position: 'absolute',
              bottom: 50,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <Button
              sx={{ alignItems: 'center', py: 1, px: 2 }}
              variant="text"
              onClick={handleOnClickCalculate}
              startIcon={<ScalePlanIcon />}
              disabled={!scaleButtonDisabled}
            >
              {t('pages.architect.project.projectPlan.scalePlanButtonLabel')}
            </Button>
          </MapBoxOverlayContainer>
        </MapBoxMapDrawLayer>
      )}
      {uploadProjectPlanDialogOpen && (
        <UploadPlanDialog open={uploadProjectPlanDialogOpen} onClose={handleUploadPlanDialogClose} />
      )}
      {displayScaleDialogOpen && (
        <SimpleDialogWithInput
          open={displayScaleDialogOpen}
          title={t('pages.architect.project.projectPlan.scalePlanTitleText')}
          body={t('pages.architect.project.projectPlan.scalePlanBodyText')}
          onClose={closeScaleDialog}
          onCallback={calculateScale}
          formField={resizeFormField}
        />
      )}
      <Snackbar
        open={snackBarOpen}
        onClose={handleSnackBarClose}
        autoHideDuration={6000}
        sx={{ bottom: { xs: 120 } }}
        message={t('pages.architect.project.projectPlan.planScaledSuccesMessage')}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </>
  );
};
