import { InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomInputLabel = styled(InputLabel)(({ theme }) => theme.unstable_sx({ mb: '-5px' }));

export interface PropertyLabelProps {
  label: string;
}

export const PropertyLabel = ({ label }: PropertyLabelProps) => (
  <>
    <CustomInputLabel shrink>{label}</CustomInputLabel>
  </>
);
