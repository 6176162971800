import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, ButtonBase, Divider, Popover, Stack, Theme, Typography } from '@mui/material';
import { useResetUserPasswordMutation } from '@dbel/react-commons/api';
import { PropsWithSx } from '@dbel/react-commons/types';
import { useSnackbar } from 'notistack';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import { auth0Config } from '../../config';

const AccountPopover = ({ sx }: PropsWithSx<{}>) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const [resetPassword, { error }] = useResetUserPasswordMutation();
  const [isHandlingResetPW, setIsHandlingResetPW] = useState<boolean>(false);
  const [showPwResetSuccesMessage, setShowPwResetSuccesMessage] = useState<boolean>(false);

  const handleOpen = (): void => {
    setShowPwResetSuccesMessage(false);
    setOpen(true);
  };

  const isDbConnectionUser = user.id.includes('auth0');

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleResetPW = useCallback(() => {
    setIsHandlingResetPW(true);

    resetPassword({ userId: user.id, userEmail: user.email })
      .unwrap()
      .then(() => {
        setShowPwResetSuccesMessage(true);
        setIsHandlingResetPW(false);
      })
      .catch(() => {
        setIsHandlingResetPW(false);
      });
  }, [resetPassword, user]);

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      const returnToURL = `${window.location.origin}`;
      logout({
        logoutParams: { returnTo: returnToURL },
        clientId: auth0Config.clientId,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ...sx,
        }}
      >
        <Avatar
          src={user.avatar}
          sx={{
            height: 37,
            width: 37,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240, mt: 1 },
        }}
        sx={{ zIndex: (theme: Theme) => theme.zIndex.modal + 1 }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user.name}
          </Typography>
        </Box>
        <Divider />
        {/*<Box sx={{ mt: 2 }}>*/}
        {/*  <MenuItem component={RouterLink} to="/architect/social/profile">*/}
        {/*    <ListItemIcon>*/}
        {/*      <UserIcon fontSize="small" />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText*/}
        {/*      primary={*/}
        {/*        <Typography color="textPrimary" variant="subtitle2">*/}
        {/*          Profile*/}
        {/*        </Typography>*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </MenuItem>*/}
        {/*  <MenuItem component={RouterLink} to="/architect/account">*/}
        {/*    <ListItemIcon>*/}
        {/*      <CogIcon fontSize="small" />*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText*/}
        {/*      primary={*/}
        {/*        <Typography color="textPrimary" variant="subtitle2">*/}
        {/*          Settings*/}
        {/*        </Typography>*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </MenuItem>*/}
        {/*</Box>*/}
        <Stack sx={{ p: 2 }} direction="column" spacing={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            {t('pages.menus.accountPopover.logoutButton')}
          </Button>
          <LoadingButton
            color="primary"
            fullWidth
            onClick={handleResetPW}
            loading={isHandlingResetPW}
            disabled={!isDbConnectionUser}
          >
            {t('pages.menus.accountPopover.passwordResetButton')}
          </LoadingButton>
          {showPwResetSuccesMessage && (
            <Typography color="textPrimary" variant="subtitle2">
              {t('pages.menus.accountPopover.passwordResetSuccessMessage')}
            </Typography>
          )}
          {error !== undefined && (
            <Typography color="error.main" variant="subtitle2">
              {t('pages.menus.accountPopover.passwordResetFailedMessage')}
            </Typography>
          )}
          {!isDbConnectionUser && (
            <Typography color="textPrimary" variant="subtitle2">
              {t('pages.menus.accountPopover.nonAuth0DBConnectionUserMessage')}
            </Typography>
          )}
        </Stack>
      </Popover>
    </>
  );
};

export default AccountPopover;
