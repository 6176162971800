import { InputAdornment, Stack, TextField, TextFieldProps } from '@mui/material';
import i18n from 'i18next';
import { useUpdateBuildingMutation, useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { cloneDeep } from 'lodash';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Building, BuildingHeight, ProjectItemHeightSource, ProjectItemSource } from '@dbel/shared/types';
import * as Yup from 'yup';
import { PropertyField } from '@dbel/react-commons/components';
import { useProjectIdFromUrl } from '../../../../../hooks/useProjectIdFromUrl';
import { openProjectItemPropertiesPanel } from '../../../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../../../store/store';

const MAX_HEIGHT = 1000;

interface FormFields {
  height: number | null;
  description: string;
}

const textFieldProps: TextFieldProps = { sx: { pb: 1 } };

const formValidationSchema = Yup.object().shape({
  height: Yup.number()
    .label(i18n.t('common.labels.height'))
    .transform((value) => (Number.isNaN(value) ? null : value))
    .moreThan(0, i18n.t('errors.formMoreThanValue', { property: i18n.t('common.labels.height'), value: 0 }))
    .max(MAX_HEIGHT, i18n.t('errors.formMaxValue', { property: i18n.t('common.labels.height'), value: MAX_HEIGHT }))
    .nullable(),
  description: Yup.string()
    .label(i18n.t('common.labels.description'))
    .transform((value) => value.trim()),
});

function getFormDefaultValues(building: Building, defaultStoryLevelHeight: number): FormFields {
  let height = building.properties.height?.aboveGroundInMeters;

  const storyLevels = building.properties.height?.storyLevels;

  if (building.properties.height?.source === 'ORIGIN_LEVELS' && storyLevels) {
    height = storyLevels * defaultStoryLevelHeight;
  }

  return {
    height: height ?? null,
    description: building.properties.description ?? '',
  };
}

function translateHeightSourceValue(heightSource: ProjectItemHeightSource): string {
  if (!heightSource) return i18n.t('common.notAvailable');

  return i18n.t(`pages.architect.project.drawer.heightSource.${heightSource}`);
}

function translateOriginSourceValue(source: ProjectItemSource): string {
  if (source === 'OSM') {
    return i18n.t('pages.architect.project.drawer.buildingSourceOriginal');
  }

  return i18n.t('pages.architect.project.drawer.buildingSourceUser');
}

function getLevelsValue(buildingHeight: BuildingHeight): string {
  return String(buildingHeight?.storyLevels ?? i18n.t('common.notAvailable'));
}

const inputLabelProps = {
  shrink: true,
};

export interface BuildingHeightFormProps {
  building: Building;
}

export const BuildingPropertiesForm = ({ building }: BuildingHeightFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateBuilding] = useUpdateBuildingMutation();

  const projectId = useProjectIdFromUrl();
  const { data: project } = useGetProjectByIdQuery(projectId);

  const mapMode = useSelector((state: RootState) => state.map.mapMode);

  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);

  const heightFieldRef = useRef<HTMLInputElement>();

  const projectSettings = project?.settings;
  const { defaultStoryLevelHeight, defaultBuildingHeight } = projectSettings.model.buildings;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    defaultValues: getFormDefaultValues(building, defaultStoryLevelHeight),
  });

  const mapIsInResultsMode = mapMode === 'RESULTS';

  const onFormSubmit = useCallback<SubmitHandler<FormFields>>(
    ({ height, description }) => {
      if (!isDirty) return;

      const buildingForUpdate = cloneDeep(building);

      // TODO: is this the right place for business logic as per RTK query best practices?
      if (!buildingForUpdate.properties.height) {
        buildingForUpdate.properties.height = {
          source: 'USER_DEFINED',
        };
      } else {
        buildingForUpdate.properties.height.source = 'USER_DEFINED';
      }

      if (height === null) {
        delete buildingForUpdate.properties.height?.aboveGroundInMeters;
      } else {
        buildingForUpdate.properties.height.aboveGroundInMeters = height;
      }

      if (description === '') {
        delete buildingForUpdate.properties.description;
      } else {
        buildingForUpdate.properties.description = description;
      }

      updateBuilding({ projectId, building: buildingForUpdate });

      // TODO: needed?
      dispatch(openProjectItemPropertiesPanel(buildingForUpdate));
    },
    [building, dispatch, isDirty, projectId, updateBuilding]
  );

  const onKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        handleSubmit(onFormSubmit)();
        event.preventDefault();
      }
    },
    [handleSubmit, onFormSubmit]
  );

  const handleOnBlurTextField = useCallback(() => {
    handleSubmit(onFormSubmit)();
  }, [handleSubmit, onFormSubmit]);

  useEffect(() => {
    reset(getFormDefaultValues(building, defaultStoryLevelHeight));
  }, [building, defaultStoryLevelHeight, reset]);

  useEffect(() => {
    if (!projectItemForPropertiesPanel?.id) return;

    // TODO this needs to be improved
    if (heightFieldRef.current) {
      heightFieldRef.current.focus();
    }
  }, [projectItemForPropertiesPanel.id]);

  return (
    <>
      <Stack direction="column" spacing={1}>
        <TextField
          {...register('height', {
            valueAsNumber: true,
          })}
          inputRef={heightFieldRef}
          type="number"
          variant="standard"
          label={t('pages.architect.project.drawer.heightLabel')}
          fullWidth
          placeholder={t('pages.architect.project.drawer.heightPlaceholder', { val: defaultBuildingHeight })}
          onBlur={handleOnBlurTextField}
          error={errors.height !== undefined}
          helperText={errors.height ? errors.height?.message : ' '}
          InputLabelProps={inputLabelProps}
          onKeyUp={onKeyPress}
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('common.units.meter')}</InputAdornment>,
            readOnly: mapIsInResultsMode,
            disableUnderline: mapIsInResultsMode,
          }}
          inputProps={{ min: 0, max: MAX_HEIGHT }}
        />
        <PropertyField
          label={t('pages.architect.project.drawer.heightSourceLabel')}
          value={translateHeightSourceValue(building.properties?.height?.source)}
          textFieldProps={textFieldProps}
        />
        <PropertyField
          label={t('pages.architect.project.drawer.buildingSourceLabel')}
          value={translateOriginSourceValue(building.properties.source)}
          textFieldProps={textFieldProps}
        />
        <PropertyField
          label={t('pages.architect.project.drawer.levelsLabel')}
          value={getLevelsValue(building.properties.height)}
          textFieldProps={textFieldProps}
        />
        <PropertyField
          label={t('pages.architect.project.drawer.standartLevelHeightLabel')}
          value={defaultStoryLevelHeight}
          valueUnit="m"
          textFieldProps={textFieldProps}
        />
        <TextField
          {...register('description')}
          variant="standard"
          multiline
          label={t('pages.architect.project.drawer.descriptionLabel')}
          fullWidth
          placeholder={t('pages.architect.project.drawer.noInformation')}
          onBlur={handleOnBlurTextField}
          error={errors.description !== undefined}
          helperText={errors.description ? errors.description.message : ' '}
          InputLabelProps={inputLabelProps}
        />
      </Stack>
    </>
  );
};
