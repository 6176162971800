import { Accordion, Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Receiver } from '@dbel/shared/types';
import { AccordionHeader, AccordionContent, formatPowerLevel } from '@dbel/react-commons/components';
import { Criticality } from '@dbel/react-commons/types';
import { CriticalityPeriodIcon } from '../../../../criticality/CriticalityPeriodIcon';
import { CriticalityPeriodText } from '../../../../criticality/CriticalityPeriodText';
import { CriticalityRatingDot } from '../../../../criticality/CriticalityRatingDot';
import { CriticalityRatingProbabilityMessage } from '../../../../criticality/CriticalityRatingProbabilityMessage';
import { PropertyLabel } from '../../common/PropertyLabel';
import { ReceiverInfluencersList } from './ReceiverInfluencersList';

export interface ReceiverCriticalityAccordionItemProps {
  receiver: Receiver;
  criticality: Criticality;
}

export const ReceiverCriticalityAccordionItem = ({ criticality, receiver }: ReceiverCriticalityAccordionItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      {criticality && (
        <Accordion>
          <AccordionHeader variant="nested">
            <CriticalityPeriodIcon type={criticality.period} sx={{ mr: 0.5 }} />
            <CriticalityPeriodText type={criticality.period} />

            <Typography sx={{ ml: 'auto' }}>{formatPowerLevel(criticality.evaluatedDbLevel, true)}</Typography>
            <CriticalityRatingDot criticality={criticality} sx={{ ml: 0.7 }} />
          </AccordionHeader>
          <AccordionContent variant="nested">
            <Stack direction="column" spacing={2}>
              <Box>
                <PropertyLabel label={t('pages.architect.project.drawer.ratingLabel')} />
                <CriticalityRatingProbabilityMessage criticality={criticality} />
              </Box>
              <Box>
                <PropertyLabel label={t('pages.architect.project.drawer.influencerLabel')} />
                <ReceiverInfluencersList receiver={receiver} period={criticality.period} />
              </Box>
            </Stack>
          </AccordionContent>
        </Accordion>
      )}
    </>
  );
};
