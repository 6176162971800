import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import i18n from 'i18next';
import { SnackbarProvider } from 'notistack';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { DebugProvider } from '@dbel/react-commons/components';

import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import './i18n';
import routes from './routes';
import lightTheme from './theme/lightTheme';

const App = () => {
  const auth = useAuth();
  const [queryParams] = useSearchParams();
  const debugEnabled = queryParams.get('debug') !== null;
  useScrollReset();

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: i18n.language, dir: i18n.dir() }}
        defaultTitle="dBEL Architect"
        titleTemplate="dBEL Architect - %s"
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DebugProvider debugEnabled={debugEnabled}>
          <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <SnackbarProvider dense maxSnack={3}>
              {auth.isInitialized ? routes : <SplashScreen />}
            </SnackbarProvider>
          </ThemeProvider>
        </DebugProvider>
      </LocalizationProvider>
    </>
  );
};

export default App;
