import { useUpdateWallMutation, useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { InputAdornment, Stack, TextField } from '@mui/material';
import i18n from 'i18next';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Wall } from '@dbel/shared/types';
import * as Yup from 'yup';
import { useProjectIdFromUrl } from '../../../../../hooks/useProjectIdFromUrl';
import { RootState, useSelector } from '../../../../../store/store';

const MAX_HEIGHT = 1000;

interface FormFields {
  height: number | null;
  description: string;
}

const formValidationSchema = Yup.object().shape({
  height: Yup.number()
    .label(i18n.t('common.labels.height'))
    .transform((value) => (Number.isNaN(value) ? null : value))
    .moreThan(0, i18n.t('errors.formMoreThanValue', { property: i18n.t('common.labels.height'), value: 0 }))
    .max(MAX_HEIGHT, i18n.t('errors.formMaxValue', { property: i18n.t('common.labels.height'), value: MAX_HEIGHT }))
    .nullable(),
  description: Yup.string()
    .label(i18n.t('common.labels.description'))
    .transform((value) => value.trim()),
});

const inputLabelProps = {
  shrink: true,
};

function getFormDefaultValues(wall: Wall): FormFields {
  return {
    height: wall.properties.height ?? null,
    description: wall.properties.description ?? '',
  };
}

export interface WallPropertiesFormProps {
  wall: Wall;
}

export const WallPropertiesForm = ({ wall }: WallPropertiesFormProps) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  const projectId = useProjectIdFromUrl();
  const [updateWall] = useUpdateWallMutation();

  const { data: project } = useGetProjectByIdQuery(projectId);

  const mapMode = useSelector((state: RootState) => state.map.mapMode);

  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);

  const heightFieldRef = useRef<HTMLInputElement>();

  const projectSettings = project?.settings;
  const { defaultBuildingHeight } = projectSettings.model.buildings;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    defaultValues: getFormDefaultValues(wall),
  });

  const mapIsInResultsMode = mapMode === 'RESULTS';

  const onFormSubmit = useCallback<SubmitHandler<FormFields>>(
    ({ height, description }) => {
      if (!isDirty) return;

      const wallForUpdate = cloneDeep(wall);

      if (height === null) {
        delete wallForUpdate.properties.height;
      } else {
        wallForUpdate.properties.height = height;
      }

      if (description === '') {
        delete wallForUpdate.properties.description;
      } else {
        wallForUpdate.properties.description = description;
      }
      updateWall({ projectId, wall: wallForUpdate });

      // TODO: needed?
      //dispatch(openProjectItemPropertiesPanel(buildingForUpdate));
    },
    [isDirty, projectId, updateWall, wall]
  );

  const handleOnBlurTextField = useCallback(() => {
    handleSubmit(onFormSubmit)();
  }, [handleSubmit, onFormSubmit]);

  useEffect(() => {
    reset(getFormDefaultValues(wall));
  }, [reset, wall]);

  useEffect(() => {
    if (!projectItemForPropertiesPanel?.id) return;

    // TODO this needs to be improved
    if (heightFieldRef.current) {
      heightFieldRef.current.focus();
    }
  }, [projectItemForPropertiesPanel.id]);

  return (
    <>
      <Stack direction="column" spacing={1.5} sx={{ alignItems: 'flex-start' }}>
        <TextField
          {...register('height', {
            valueAsNumber: true,
          })}
          inputRef={heightFieldRef}
          type="number"
          variant="standard"
          label={t('pages.architect.project.drawer.heightLabel')}
          fullWidth
          placeholder={t('pages.architect.project.drawer.heightPlaceholder', { val: defaultBuildingHeight })}
          onBlur={handleOnBlurTextField}
          error={errors.height !== undefined}
          helperText={errors.height ? errors.height?.message : ' '}
          InputLabelProps={inputLabelProps}
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('common.units.meter')}</InputAdornment>,
            readOnly: mapIsInResultsMode,
            disableUnderline: mapIsInResultsMode,
          }}
          inputProps={{ min: 0, max: MAX_HEIGHT }}
        />
        <TextField
          {...register('description')}
          variant="standard"
          multiline
          label={t('pages.architect.project.drawer.descriptionLabel')}
          fullWidth
          placeholder={t('pages.architect.project.drawer.noInformation')}
          onBlur={handleOnBlurTextField}
          error={errors.description !== undefined}
          helperText={errors.description ? errors.description.message : ' '}
          InputLabelProps={inputLabelProps}
        />
      </Stack>
    </>
  );
};
