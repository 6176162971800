import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PropsWithSx } from '@dbel/react-commons/types';
import { MapMode, switchToMapMode } from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import { ModeSwitcher } from './ModeSwitcher';
import { ModeSwitcherItem } from './ModeSwitcherItem';

export const MapModeSwitcher = ({ sx }: PropsWithSx<{}>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mapMode = useSelector((state: RootState) => state.map.mapMode);

  const handleOnChangeMapMode = useCallback(
    (newMode: MapMode) => {
      if (newMode) {
        dispatch(switchToMapMode(newMode));
      }
    },
    [dispatch]
  );

  return (
    <>
      <ModeSwitcher<MapMode>
        value={mapMode}
        onChange={handleOnChangeMapMode}
        itemDivider
        sx={{ display: 'flex', ...sx }}
      >
        <ModeSwitcherItem<MapMode> value="MAP_SURROUNDING" text={t('pages.architect.project.mainMenu.map')} />
        <ModeSwitcherItem<MapMode> value="PROJECT_BUILDINGS" text={t('pages.architect.project.mainMenu.buildings')} />
        <ModeSwitcherItem<MapMode> value="SOUND_SOURCES" text={t('pages.architect.project.mainMenu.sources')} />
        <ModeSwitcherItem<MapMode> value="RESULTS" text={t('pages.architect.project.mainMenu.results')} />
      </ModeSwitcher>
    </>
  );
};
