import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

const Main = styled('main')(({ theme }) =>
  theme.unstable_sx({
    height: '100vh',
  })
);

export const AllErrorPagesLayout = () => (
  <>
    <Main>
      <Outlet />
    </Main>
  </>
);
