import { Box } from '@mui/material';
import { LngLat } from 'mapbox-gl';
import { useCallback, useEffect, useMemo } from 'react';
import { Building, Receiver } from '@dbel/shared/types';
import { useGetProjectByIdQuery, useGetNoiseProtectionAreaReceiversQuery } from '@dbel/react-commons/api';
import { useMapBox } from '@dbel/react-commons/components';
import { taLaermCalculatorService } from '@dbel/react-commons/hooks';
import { useProjectIdFromUrl } from '../../../../../hooks/useProjectIdFromUrl';
import { openReceiverDetailsInPropertiesPanel } from '../../../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../../../store/store';
import { ReceiverWithEvaluationResult } from '../../index';
import { ReceiverAccordionItem } from './ReceiverAccordionItem';
import { TaLaermPresets } from '../../../../../NoiseProtectionEvaluationPresets';

export interface DBResultsPanelProps {
  building: Building;
  disabled: boolean;
}

export const ReceiverAccordion = ({ building, disabled }: DBResultsPanelProps) => {
  const dispatch = useDispatch();
  const { mapBox } = useMapBox();

  const noiseEmissionsCriticalityTimeSpan = useSelector(
    (state: RootState) => state.map.noiseEmissionsCriticalityTimeSpan
  );

  const focusedReceiverIdInPropertiesPanel = useSelector(
    (state: RootState) => state.map.focusedReceiverIdInPropertiesPanel
  );

  const projectId = useProjectIdFromUrl();
  const { data: project } = useGetProjectByIdQuery(projectId);
  const { data: receivers } = useGetNoiseProtectionAreaReceiversQuery(projectId);

  const taLaermAreaType = useMemo(
    () => building.properties.taLaerm?.areaType ?? project.settings.taLaerm?.defaultAreaType,
    [building.properties.taLaerm?.areaType, project.settings.taLaerm?.defaultAreaType]
  );

  const receiversForBuilding: ReceiverWithEvaluationResult[] = useMemo(
    () =>
      receivers
        .filter((receiver: Receiver) => receiver.properties.relations.buildingKey === building.id)
        .map((receiver: Receiver) => ({
          receiver,
          evaluationResult: taLaermCalculatorService.evaluateReceiver(
            TaLaermPresets[taLaermAreaType].fassade,
            [receiver],
            noiseEmissionsCriticalityTimeSpan
          ),
        }))
        // sort by criticality descending
        .sort(
          (
            receiverAWithEvalResult: ReceiverWithEvaluationResult,
            receiverBWithEvalResult: ReceiverWithEvaluationResult
          ) => {
            const receiverAEvalResultDifference =
              receiverAWithEvalResult.evaluationResult?.highestCriticality.differenceToLowCriticality ?? -Infinity;

            const receiverBEvalResultDifference =
              receiverBWithEvalResult.evaluationResult?.highestCriticality.differenceToLowCriticality ?? -Infinity;

            return receiverBEvalResultDifference - receiverAEvalResultDifference;
          }
        ),
    [building.id, noiseEmissionsCriticalityTimeSpan, receivers, taLaermAreaType]
  );

  const handleExpandedStateChange = useCallback(
    (expanded: boolean, receiver: Receiver) => {
      if (expanded) {
        dispatch(openReceiverDetailsInPropertiesPanel(String(receiver.id)));

        const [lng, lat] = receiver.geometry.coordinates;

        mapBox.flyTo({
          center: new LngLat(lng, lat),
        });
      }
    },
    [dispatch, mapBox]
  );

  useEffect(() => {
    if (receiversForBuilding.length === 0) return;

    if (
      !focusedReceiverIdInPropertiesPanel ||
      receiversForBuilding.findIndex((r) => r.receiver.id === focusedReceiverIdInPropertiesPanel) === -1
    ) {
      dispatch(openReceiverDetailsInPropertiesPanel(String(receiversForBuilding[0].receiver.id)));
    }
  }, [dispatch, focusedReceiverIdInPropertiesPanel, receiversForBuilding]);

  return (
    <Box>
      {receiversForBuilding.map((receiverWithEvalResult) => (
        <ReceiverAccordionItem
          key={receiverWithEvalResult.receiver.id}
          expanded={focusedReceiverIdInPropertiesPanel === receiverWithEvalResult.receiver.id}
          disabled={disabled}
          receiverWithEvalResult={receiverWithEvalResult}
          onExpandedStateChange={handleExpandedStateChange}
        />
      ))}
    </Box>
  );
};
