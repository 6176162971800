import { LayerStyle } from '@dbel/react-commons/types';
import { BUILDING_BACKGROUND_COLOR } from './colors/colors';
import {
  BUILDING_FILL_COLOR,
  BUILDING_FILL_OPACITY,
  BUILDING_OUTLINE_COLOR,
  BUILDING_OUTLINE_OPACITY,
} from './expressions/buildingExpressions';
import { HEIGHT_FEATURE_STATE_VALUE } from './expressions/featureStateExpressions';

export const BUILDINGS_3D_LAYER_STYLE: LayerStyle = {
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-color': BUILDING_FILL_COLOR,
    // 'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, HEIGHT_FEATURE_STATE_VALUE],
    'fill-extrusion-height': HEIGHT_FEATURE_STATE_VALUE,
    'fill-extrusion-opacity': 0.8,
    'fill-extrusion-vertical-gradient': true,
  },
};

export const BUILDINGS_LAYER_STYLE_BACKGROUND: LayerStyle = {
  type: 'fill',
  filter: ['==', '$type', 'Polygon'],
  paint: {
    // TODO: this needs to use ['get'...] somewhere so that this layer is on top of the heatmap layer and prevents the heatmap transparency. I don't know why.
    // Using only 'fill-color': BUILDING_BACKGROUND_COLOR here is not enough.
    'fill-color': ['coalesce', ['get', 'dummy'], BUILDING_BACKGROUND_COLOR],
  },
};

export const BUILDINGS_LAYER_STYLE: LayerStyle = {
  type: 'fill',
  filter: ['==', '$type', 'Polygon'],
  paint: {
    'fill-color': BUILDING_FILL_COLOR,
    'fill-opacity': BUILDING_FILL_OPACITY,
  },
};

export const BUILDINGS_LAYER_STYLE_OUTLINE: LayerStyle = {
  type: 'line',
  filter: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],

  paint: {
    'line-color': BUILDING_OUTLINE_COLOR,
    'line-width': 2,
    'line-opacity': BUILDING_OUTLINE_OPACITY,
  },
};
