import { useMemo } from 'react';
import { TaLaermPeriod } from '@dbel/shared/types';
import { PropsWithSx } from '@dbel/react-commons/types';
import { ReactComponent as TimeSpanDayIcon } from '../../icons/TimeSpanDay.svg';
import { ReactComponent as TimeSpanNightIcon } from '../../icons/TimeSpanNight.svg';

export interface CriticalityPeriodIconProps {
  type: TaLaermPeriod;
}

export const CriticalityPeriodIcon = ({ sx, type }: PropsWithSx<CriticalityPeriodIconProps>) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'day':
        return <TimeSpanDayIcon sx={sx} />;
      case 'night':
        return <TimeSpanNightIcon sx={sx} />;
      default:
        return undefined;
    }
  }, [sx, type]);

  return <>{icon}</>;
};
