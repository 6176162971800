import { CustomDialogTitle } from '@dbel/react-commons/components';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SimpleDialogProps {
  title: string;
  open: boolean;
  onClose?: () => void;
  onCallback?: () => void;
  okButton?: ReactNode;
}
export const SimpleDialog = ({
  title,
  open,
  onClose,
  onCallback,
  children,
  okButton,
}: PropsWithChildren<SimpleDialogProps>) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(open);
  const { t } = useTranslation();
  const handleClickCloseSettings = useCallback(() => {
    setDialogOpen(false);
    onClose();
  }, [onClose]);

  const handleOkClicked = useCallback(() => {
    onCallback();
    handleClickCloseSettings();
  }, [onCallback, handleClickCloseSettings]);

  return (
    <>
      <Dialog onClose={handleClickCloseSettings} open={dialogOpen} fullWidth maxWidth="sm">
        <CustomDialogTitle onClickClose={onClose}>{title}</CustomDialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClickCloseSettings}>
            {t('common.buttonLabels.cancel')}
          </Button>
          {okButton ?? (
            <Button color="primary" onClick={handleOkClicked} sx={{ mr: 2 }}>
              {t('common.buttonLabels.save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
