import { LoadingButton } from '@mui/lab';
import { ColoredIcon, useMapBox, CustomDialogTitle } from '@dbel/react-commons/components';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormHelperText,
  Stack,
} from '@mui/material';
import { DBELFeatureType, ImageFeature, ImageFeatureProperties } from '@dbel/shared/types';
import { styled } from '@mui/material/styles';
import {
  useGetImageFeatureQuery,
  useUpdateImageFeatureMutation,
  useUploadMediaMutation,
} from '@dbel/react-commons/api';
import { centerOfMass, polygon } from '@turf/turf';
import { Position } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectIdFromUrl } from '../../hooks/useProjectIdFromUrl';
import { ReactComponent as UploadFileIcon } from '../../icons/UploadFile.svg';
import { setProjectPlanEditable } from '../../store/slices/map';
import { useDispatch } from '../../store/store';
import { DEFAULT_PLAN_OPACITY, PROJECT_PLAN_FEATURE_ID } from '../mapbox/layers';

const MAX_IMAGE_SIZE_IN_BYTES = 10485760; // 10 * 1024 * 2014;

const HiddenInput = styled('input')(({ theme }) =>
  theme.unstable_sx({
    display: 'none',
  })
);

function computePolyCoordinates(
  mapBox: mapboxgl.Map,
  imageWidth: number,
  imageHeight: number,
  previousFeature?: ImageFeature
): Position[] {
  let centerX: number;
  let centerY: number;

  let imW = imageWidth;
  let imH = imageHeight;

  if (previousFeature) {
    const center = centerOfMass(previousFeature);
    const { x, y } = mapBox.project([center.geometry.coordinates[0], center.geometry.coordinates[1]]);

    centerX = x;
    centerY = y;
  } else {
    const { width: canvasWidth, height: canvasHeight } = mapBox.getCanvas().getBoundingClientRect();

    while (imW >= 0.8 * canvasWidth || imH >= 0.8 * canvasHeight) {
      imW = Math.round(0.8 * imW);
      imH = Math.round(0.8 * imH);
    }

    centerX = canvasWidth / 2;
    centerY = canvasHeight / 2;
  }

  const upperLeft = mapBox.unproject([centerX - imW / 2, centerY - imH / 2]).toArray();
  const upperRight = mapBox.unproject([centerX + imW / 2, centerY - imH / 2]).toArray();
  const lowerRight = mapBox.unproject([centerX + imW / 2, centerY + imH / 2]).toArray();
  const lowerLeft = mapBox.unproject([centerX - imW / 2, centerY + imH / 2]).toArray();

  return [upperLeft, upperRight, lowerRight, lowerLeft, upperLeft];
}

interface UploadPlanDialogProps {
  open: boolean;
  onClose: (canceled?: boolean) => void;
}

const UploadPlanDialog = ({ open, onClose }: UploadPlanDialogProps) => {
  const { t } = useTranslation();
  const { mapBox } = useMapBox();
  const dispatch = useDispatch();

  const projectId = useProjectIdFromUrl();
  const { data: planImageFeature } = useGetImageFeatureQuery(projectId);

  const uploadMediaRequest = useRef<{ abort: () => void }>();
  const [updateImageFeature] = useUpdateImageFeatureMutation();
  const [uploadMedia, { isLoading: fileIsUploading }] = useUploadMediaMutation();

  const [error, setError] = useState('');

  const handleClickCancel = useCallback(() => {
    if (uploadMediaRequest.current) {
      uploadMediaRequest.current.abort();

      uploadMediaRequest.current = undefined;
    }

    onClose(true);
  }, [onClose]);

  const handleOnClose = useCallback(() => {
    // prevents closing while uploading
    if (fileIsUploading) return;

    onClose();
  }, [fileIsUploading, onClose]);

  const handleOnSelectFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!(event.target?.files?.length > 0)) return;

      const selectedFile = event.target.files.item(0);

      if (selectedFile.size > MAX_IMAGE_SIZE_IN_BYTES) {
        setError('errors.fileUploadMaxSize');
        return;
      }

      const src = URL.createObjectURL(selectedFile);
      const img = new Image();

      img.onload = async () => {
        const { width: imageWidth, height: imageHeight } = img;
        URL.revokeObjectURL(src);

        const uploadMediaResult = uploadMedia({ projectId, file: selectedFile });
        uploadMediaRequest.current = uploadMediaResult;

        const fileName = await uploadMediaResult.unwrap();

        const coords = computePolyCoordinates(mapBox, imageWidth, imageHeight, planImageFeature);

        const newImageFeature = polygon<ImageFeatureProperties>(
          [coords],
          {
            type: DBELFeatureType.ImageFeature,
            key: PROJECT_PLAN_FEATURE_ID,
            fileName,
            opacity: DEFAULT_PLAN_OPACITY,
            visible: true,
          },
          { id: PROJECT_PLAN_FEATURE_ID }
        );

        await updateImageFeature({ projectId, imageFeature: newImageFeature });

        dispatch(setProjectPlanEditable(true));

        onClose();
      };

      img.onerror = () => {
        URL.revokeObjectURL(src);
        setError('errors.fileUploadError');
      };

      img.src = src;
    },
    [dispatch, mapBox, onClose, planImageFeature, projectId, updateImageFeature, uploadMedia]
  );

  return (
    <>
      <Dialog onClose={handleOnClose} open={open}>
        <Stack direction="row">
          <Box sx={{ mr: 'auto', pt: 2, pl: 3 }}>
            <ColoredIcon color="#EBF4FD" icon={<UploadFileIcon color="primary" />} />
          </Box>
          <Box>
            <CustomDialogTitle onClickClose={handleOnClose}>
              {t('pages.architect.project.map.uploadImageDialogTitle')}
            </CustomDialogTitle>
            <DialogContent>
              <Stack spacing={2} direction="column">
                <DialogContentText sx={{ mr: 1 }}>
                  {t('pages.architect.project.map.uploadImageDialogBody')}
                </DialogContentText>
                {error !== '' && <FormHelperText error>{t(error)}</FormHelperText>}
              </Stack>
            </DialogContent>
          </Box>
        </Stack>
        <DialogActions>
          <Button color="secondary" onClick={handleClickCancel} sx={{ mx: 1 }}>
            {t('common.buttonLabels.cancel')}
          </Button>
          <form noValidate autoComplete="off">
            {/* https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/label-has-associated-control.md */}
            {/* eslint jsx-a11y/label-has-associated-control: ["error", { assert: "either" } ]*/}
            <label htmlFor="contained-button-file">
              <HiddenInput
                accept="image/jpeg, image/png"
                id="contained-button-file"
                type="file"
                onChange={handleOnSelectFile}
              />
              <LoadingButton loading={fileIsUploading} color="primary" component="span" sx={{ mr: 1 }}>
                {t('pages.architect.project.map.chooseFileButtonText')}
              </LoadingButton>
            </label>
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadPlanDialog;
