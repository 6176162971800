import i18n from 'i18next';
import { merge } from 'lodash';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translationEN, translationDE } from '@dbel/react-commons/components';
import localeDe from './locales/de/translation.json';
import localeEn from './locales/en/translation.json';

const resources = {
  en: {
    translation: merge(translationEN, localeEn),
  },
  de: {
    translation: merge(translationDE, localeDe),
  },
};

const detectionOptions = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.DEV,
    resources,
    detection: detectionOptions,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });
