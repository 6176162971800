import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '@dbel/shared/types';
import { User } from '../../types/user';

const SLICE_PREFIX = 'account';

interface AccountState {
  user?: User;
  account?: Account;
}

const initialState: AccountState = {};

const slice = createSlice({
  name: `${SLICE_PREFIX}`,
  initialState,
  reducers: {
    resetState: () => initialState,
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setAccount: (state, action: PayloadAction<Account>) => {
      state.account = action.payload;
    },
  },
});

export const { resetState: resetAccountState, setUser, setAccount } = slice.actions;

export default slice.reducer;
