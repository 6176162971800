import { MapBoxOverlayContainer } from '@dbel/react-commons/components';
import { PropsWithSx } from '@dbel/react-commons/types';
import { Slider, Stack, Typography } from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface ProjectPlanTransparencyProps {
  planOpacity: number;
  onChangingOpacity: (newValue: number) => void;
  onChangedOpacity: (newValue: number) => void;
}

export const ProjectPlanTransparencyToolbar = ({
  sx,
  planOpacity,
  onChangingOpacity,
  onChangedOpacity,
}: PropsWithSx<ProjectPlanTransparencyProps>) => {
  const { t } = useTranslation();

  const handleChangePlanOpacity = useCallback(
    (_: Event, newValue: number | number[]) => {
      if (!Array.isArray(newValue)) {
        onChangingOpacity(newValue);
      }
    },
    [onChangingOpacity]
  );

  const handleChangeCommittedPlanOpacity = useCallback(
    (_: SyntheticEvent | Event, newValue: number | number[]) => {
      if (!Array.isArray(newValue)) {
        onChangedOpacity(newValue);
      }
    },
    [onChangedOpacity]
  );

  return (
    <>
      <MapBoxOverlayContainer sx={sx}>
        <Stack spacing={4} direction="row" sx={{ alignItems: 'center', py: 1, pl: 2, pr: 4 }}>
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              fontWeight: 600,
            }}
          >
            {t('common.labels.opacity')}
          </Typography>

          <Slider
            sx={{ width: 150 }}
            value={planOpacity}
            min={0.1}
            max={1}
            step={0.1}
            onChange={handleChangePlanOpacity}
            onChangeCommitted={handleChangeCommittedPlanOpacity}
          />
        </Stack>
      </MapBoxOverlayContainer>
    </>
  );
};
