import { DrawLayerStyle } from '../MapBoxMapDrawLayer';
import { SCALE_PLAN_COLOR } from './colors/colors';
import { COMMON_FILL_OPACITY } from './expressions/commonExpressions';

export const SCALE_PLAN_DRAW_LAYER_STYLE: DrawLayerStyle = [
  {
    id: 'gl-draw-polygon-line',
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': SCALE_PLAN_COLOR,
      'line-width': 4,
      'line-opacity': COMMON_FILL_OPACITY,
    },
  },

  {
    id: 'gl-draw-point-active',
    type: 'symbol',
    filter: ['==', '$type', 'Point'],
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 8,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 5,
      'circle-color': SCALE_PLAN_COLOR,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': SCALE_PLAN_COLOR,
    },
  },
  {
    id: 'gl-draw-point-selected-stroke-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'feature']],
    paint: {
      'circle-radius': 12,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-selected-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'feature']],
    paint: {
      'circle-radius': 8,
      'circle-color': SCALE_PLAN_COLOR,
    },
  },
];
