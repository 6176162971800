import { Typography } from '@mui/material';
import { PropsWithSx } from '@dbel/react-commons/types';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TaLaermPeriod } from '@dbel/shared/types';

export interface CriticalityPeriodIconProps {
  type: TaLaermPeriod;
}

export const CriticalityPeriodText = ({ sx, type }: PropsWithSx<CriticalityPeriodIconProps>) => {
  const { t } = useTranslation();

  const text = useMemo(() => {
    switch (type) {
      case 'day':
      case 'night':
        return t('pages.architect.project.drawer.continuousLevelTitle');
      default:
        return undefined;
    }
  }, [type, t]);

  return (
    <>
      <Typography noWrap sx={sx}>
        {text}
      </Typography>
    </>
  );
};
