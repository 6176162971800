import { Absorption } from './Absorptions';

export const DEFAULT_ABSORPTION_PRESET: AbsorptionPreset = {
  name: 'Default aborber preset',
  absorptionValues: [0.001, 0.001, 0.001, 0.001, 0.001, 0.001],
};

export type AbsorptionPreset = {
  name: string;
  absorptionValues: Absorption;
  id?: string;
};

export type AbsorptionPresets = AbsorptionPreset[];
