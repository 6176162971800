import MapboxDraw from '@mapbox/mapbox-gl-draw';

export type MapBoxDrawMode = {
  mode: Exclude<MapboxDraw.DrawMode, 'static'> | 'tx_poly';
};

export type MapBoxDrawSimpleSelectMode = MapBoxDrawMode & {
  mode: 'simple_select';
  selectedFeatureIds?: string[];
};

export type MapBoxDrawDirectSelectMode = MapBoxDrawMode & {
  mode: 'direct_select';
  selectedFeatureId: string;
};

export type MapBoxDrawDrawPointMode = MapBoxDrawMode & {
  mode: 'draw_point';
};

export type MapBoxDrawDrawLineStringMode = MapBoxDrawMode & {
  mode: 'draw_line_string';
};

export type MapBoxDrawDrawPolygonMode = MapBoxDrawMode & {
  mode: 'draw_polygon';
};

export enum MapBoxDrawScaleRotateModeReferencePoint {
  Center = 0,
  Opposite = 1,
}

export type MapBoxDrawScaleRotateModeOptions = {
  canScale?: boolean;
  canRotate?: boolean;
  canTrash?: boolean;
  rotatePivot?: MapBoxDrawScaleRotateModeReferencePoint;
  scaleCenter?: MapBoxDrawScaleRotateModeReferencePoint;
  singleRotationPoint?: boolean;
  rotationPointRadius?: number;
  canSelectFeatures?: boolean;
};

export type MapBoxDrawScaleRotateMode = MapBoxDrawMode & {
  mode: 'tx_poly';
  selectedFeatureId: string;
  options?: MapBoxDrawScaleRotateModeOptions;
};

export function isMapBoxDrawSimpleSelectMode(mode: MapBoxDrawMode): mode is MapBoxDrawSimpleSelectMode {
  return mode.mode === 'simple_select';
}

export function isMapBoxDrawDirectSelectMode(mode: MapBoxDrawMode): mode is MapBoxDrawDirectSelectMode {
  return mode.mode === 'direct_select';
}

export function isMapBoxDrawScaleRotateMode(mode: MapBoxDrawMode): mode is MapBoxDrawScaleRotateMode {
  return mode.mode === 'tx_poly';
}

export function isMapBoxDrawDrawPointMode(mode: MapBoxDrawMode): mode is MapBoxDrawDrawPointMode {
  return mode.mode === 'draw_point';
}

export function isMapBoxDrawDrawLineStringMode(mode: MapBoxDrawMode): mode is MapBoxDrawDrawLineStringMode {
  return mode.mode === 'draw_line_string';
}

export function isMapBoxDrawDrawPolygonMode(mode: MapBoxDrawMode): mode is MapBoxDrawDrawPolygonMode {
  return mode.mode === 'draw_polygon';
}

export type MapBoxDrawModes =
  | MapBoxDrawSimpleSelectMode
  | MapBoxDrawDirectSelectMode
  | MapBoxDrawDrawPointMode
  | MapBoxDrawDrawLineStringMode
  | MapBoxDrawDrawPolygonMode
  | MapBoxDrawScaleRotateMode;

export type MapBoxDrawModeIds = MapBoxDrawModes['mode'];
