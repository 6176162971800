import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Building, isBuilding, isSoundSource, isWall, SoundSource, Wall } from '@dbel/shared/types';
import { closeProjectItemPropertiesPanel, resetMapDrawMode } from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import { BuildingPropertiesPanel } from './building/BuildingPropertiesPanel';
import { SoundSourcePropertiesPanel } from './sound-source/SoundSourcePropertiesPanel';
import { WallsPropertiesPanel } from './wall/WallsPropertiesPanel';

export const PROJECT_ITEM_PROPERTIES_PANEL_WIDTH = 300;

export interface ProjectItemPropertiesRightPanelProps {
  width: number;
}

export const ProjectItemPropertiesRightPanel = ({ width = 200 }: ProjectItemPropertiesRightPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // TODO: Refactor to handle all kinds of propterties
  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);
  const projectItemIsBuilding = projectItemForPropertiesPanel && isBuilding(projectItemForPropertiesPanel);
  const projectItemIsSoundSource = projectItemForPropertiesPanel && isSoundSource(projectItemForPropertiesPanel);
  const projectItemIsWall = projectItemForPropertiesPanel && isWall(projectItemForPropertiesPanel);

  const handleClickCloseButton = useCallback(() => {
    dispatch(resetMapDrawMode());
    dispatch(closeProjectItemPropertiesPanel());
  }, [dispatch]);

  return (
    <>
      {projectItemForPropertiesPanel && (projectItemIsBuilding || projectItemIsSoundSource || projectItemIsWall) && (
        <Drawer
          sx={{
            width,
            '& .MuiDrawer-paper': {
              position: 'relative',
            },
            height: '100%',
          }}
          variant="persistent"
          anchor="right"
          open
          hideBackdrop
        >
          <Box
            sx={{
              height: '100%',
              pt: 6,
              pb: 3,
              px: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                position: 'absolute',
                left: 8,
                top: 10,
                fontWeight: 400,
                fontSize: 16,
              }}
            >
              {t('pages.architect.project.drawer.propertiesTitle')}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClickCloseButton}
              size="small"
              sx={{
                position: 'absolute',
                right: 3,
                top: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
            {/* TODO: [DISCUSS] This panel shouldn't know about either building or sound source panels. You could use a
            generic slot for this panels content. */}
            <Box sx={{ height: '100%', overflow: 'auto' }}>
              {projectItemIsBuilding && (
                <BuildingPropertiesPanel building={projectItemForPropertiesPanel as Building} />
              )}

              {projectItemIsSoundSource && (
                <SoundSourcePropertiesPanel soundSource={projectItemForPropertiesPanel as SoundSource} />
              )}

              {projectItemIsWall && <WallsPropertiesPanel wall={projectItemForPropertiesPanel as Wall} />}
            </Box>
          </Box>
        </Drawer>
      )}
    </>
  );
};
