import { Feature, Geometry, LineString, Point } from 'geojson';
import i18n from 'i18next';
import { geometry, feature, centerOfMass, length, along } from '@turf/turf';
import { cloneDeep } from 'lodash';
import { MapMouseEvent } from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetProjectByIdQuery,
  useGetSoundSourcesQuery,
  useCreateSoundSourceMutation,
  useUpdateSoundSourceMutation,
  useDeleteSoundSourceMutation,
} from '@dbel/react-commons/api';
import {
  useMapBox,
  useMapBoxDrawByRef,
  MapBoxMapSource,
  MapBoxMapLayer,
  MapBoxAlertMessage,
} from '@dbel/react-commons/components';
import { FormField, FormFieldValues } from '@dbel/react-commons/types';
import { EmissionModelFacet } from '@dbel/shared/emission-models';
import {
  SoundSource,
  SoundSourceProperties,
  isSoundSource,
  isPointSoundSource,
  Receiver,
  PointSoundSource,
} from '@dbel/shared/types';
import { availableEmissionModels } from '@dbel/shared/util';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { useReceiverTools } from '../../../hooks/useReceiverTools';
import { openProjectItemPropertiesPanel } from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import { CustomSoundSourcePropertiesDialog } from '../../dialogs/CustomSoundSourcePropertiesDialog/CustomSoundSourcePropertiesDialog';
import { SimpleDialogWithInput } from '../../dialogs/SimpleDialogWithInput';
import { SoundSourcesToolbar } from '../toolbars/SoundSourcesToolbar';
import MapBoxMapDrawLayer from './MapBoxMapDrawLayer';
import { SOUND_SOURCES_DRAW_LAYER_STYLE } from './styles/SoundSourcesDrawLayerStyles';
import {
  SOUND_SOURCE_LAYER_STYLE_TYPE_LINE,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POINT,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_HIGHLIGHTED,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_SELECTED,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON,
  SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON_OUTLINE,
} from './styles/SoundSourcesLayerStyles';
import { layerIds, sourceIds } from '../../../mapBoxOptions';

type CenterOfMassPoint = Feature<Point, CenterOfMassPointProperties>;
type CenterOfMassPointProperties = { key: string; soundSource: SoundSource };

const heightFormField: FormField = {
  name: 'height',
  label: i18n.t('common.form.height'),
  type: 'number',
  valueUnit: 'm',
  validations: ['number', 'required'],
  params: {
    required: i18n.t('errors.formRequired', { property: i18n.t('common.form.length') }),
  },
  input: { textAlign: 'right' },
  autofocus: true,
};

export interface SoundSourcesLayerProps {
  isEditable?: boolean;
  receivers?: Receiver[];
  projectSoundSources?: boolean;
  isSelectable?: boolean;
}

const SoundSourcesLayer = ({
  isEditable,
  receivers = [],
  projectSoundSources = true,
  isSelectable = true,
}: SoundSourcesLayerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mapBox } = useMapBox();
  const mapBoxDraw = useMapBoxDrawByRef();
  const receiverTools = useReceiverTools();

  const appMode = useSelector((state: RootState) => state.map.appMode);

  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);
  const activeToolbarTool = useSelector((state: RootState) => state.map.activeSoundSourceDrawTool);
  const viewMode = useSelector((state: RootState) => state.map.viewMode);

  const focusedReceiverIdInPropertiesPanel = useSelector(
    (state: RootState) => state.map.focusedReceiverIdInPropertiesPanel
  );

  const [soundSourceForDialog, setSoundSourceForDialog] = useState<SoundSource | undefined>();
  const [heightSoundSourceDialogPropsOpen, setHeightSoundSourceDialogPropsOpen] = useState<boolean>(false);
  const [genericPointSoundSourceDialogPropsOpen, setGenericPointSoundSourceDialogPropsOpen] = useState<boolean>(false);

  const projectId = useProjectIdFromUrl();

  const { data: project } = useGetProjectByIdQuery(projectId);
  const { data: soundSourcesData } = useGetSoundSourcesQuery({ projectId });

  const [createSoundSource] = useCreateSoundSourceMutation();
  const [updateSoundSource] = useUpdateSoundSourceMutation();
  const [deleteSoundSource] = useDeleteSoundSourceMutation();

  const [parkingSpaceInfoBoxShown, setParkingSpaceInfoBoxShown] = useState<boolean>(false);
  const [serviceRoadInfoBoxShown, setServiceRoadInfoBoxShown] = useState<boolean>(false);

  const soundSources = useMemo(() => {
    if (!soundSourcesData) return [];
    return soundSourcesData.filter(
      (soundSource) => soundSource.properties.isProjectSoundSource === projectSoundSources
    );
  }, [projectSoundSources, soundSourcesData]);

  const handleOnCancelDialogs = useCallback(() => {
    setGenericPointSoundSourceDialogPropsOpen(false);
    setHeightSoundSourceDialogPropsOpen(false);

    mapBoxDraw.current.delete(String(soundSourceForDialog.id));
  }, [mapBoxDraw, soundSourceForDialog]);

  const handeOnSaveSoundSourceInDialog = useCallback(
    (newSoundSource: SoundSource) => {
      createSoundSource({
        projectId,
        soundSource: newSoundSource,
      })
        .unwrap()
        .then((createdSoundSource: SoundSource) => {
          setHeightSoundSourceDialogPropsOpen(false);
          setGenericPointSoundSourceDialogPropsOpen(false);
          dispatch(openProjectItemPropertiesPanel(createdSoundSource));
        });
    },
    [createSoundSource, dispatch, projectId]
  );

  const handleOnSaveHeightDialog = useCallback(
    (values: FormFieldValues) => {
      const inputHeight = values[heightFormField.name] as number;

      if (soundSourceForDialog) {
        const updatedSoundSource = cloneDeep(soundSourceForDialog);

        // set sound source height according to user input as z dimension
        updatedSoundSource.geometry.coordinates[2] = inputHeight;

        handeOnSaveSoundSourceInDialog(updatedSoundSource);
      }
      setHeightSoundSourceDialogPropsOpen(false);
    },
    [handeOnSaveSoundSourceInDialog, soundSourceForDialog]
  );

  const handleOnCreateFeature = useCallback(
    (newFeature: Feature) => {
      // const availableSoundSourcesMap = createAvailableSoundSourcesToPropertiesMap(project.availableSoundSources);

      const featureProps = availableEmissionModels(project).find(
        (soundSourceProps) => soundSourceProps.emissionModel.facet === activeToolbarTool
      );

      if (!featureProps || newFeature.geometry.type === 'GeometryCollection') return;

      const newGeometry = geometry(featureProps.geometryType, newFeature.geometry.coordinates);

      const newSoundSource = feature<Geometry, Omit<SoundSourceProperties, 'key'>>(
        newGeometry,
        {
          ...featureProps,
        },
        {
          // this is just used to delete the feature again after the user pressed cancel
          // this id will be removed before calling the create API endpoint anyway
          id: newFeature.id,
        }
      ) as SoundSource;

      if (
        (['VENTILATION', 'AIR_CONDITION', 'HEAT_PUMP', 'HOUSE_TECHNIC', 'SONICATION'] as EmissionModelFacet[]).includes(
          newSoundSource.properties.emissionModel.facet
        )
      ) {
        setSoundSourceForDialog(newSoundSource);
        setHeightSoundSourceDialogPropsOpen(true);
      } else if (newSoundSource.properties.emissionModel.facet === 'CUSTOM_POINT_SOUND_SOURCE') {
        setSoundSourceForDialog(newSoundSource);
        setGenericPointSoundSourceDialogPropsOpen(true);
      } else {
        if (
          (['PARKING_ENTRANCE'] as EmissionModelFacet[]).includes(newSoundSource.properties.emissionModel.facet) &&
          appMode === 'OUTSIDE_NOISE'
        ) {
          newSoundSource.geometry.coordinates[2] = 1.5;
        }

        createSoundSource({
          projectId,
          soundSource: newSoundSource,
        })
          .unwrap()
          .then((createdSoundSource: SoundSource) => {
            dispatch(openProjectItemPropertiesPanel(createdSoundSource));
          });
      }
    },
    [activeToolbarTool, appMode, createSoundSource, dispatch, project, projectId]
  );

  const handleOnClickFeatures = useCallback(
    (_: MapMouseEvent, __: string, featureIds: string[]) => {
      const [clickedFeatureId] = featureIds;

      const clickedSoundSource = soundSources.find((soundSource: SoundSource) => soundSource.id === clickedFeatureId);

      if (clickedSoundSource) {
        dispatch(openProjectItemPropertiesPanel(clickedSoundSource));
      }
    },
    [dispatch, soundSources]
  );

  useEffect(() => {
    let lastSelectedSoundSourceId: string | number;

    if (projectItemForPropertiesPanel !== undefined) {
      lastSelectedSoundSourceId = projectItemForPropertiesPanel.id;

      if (!isEditable) {
        mapBox.setFeatureState(
          { id: lastSelectedSoundSourceId, source: sourceIds['SoundSources'] },
          { selected: true }
        );
        mapBox.setFeatureState(
          { id: lastSelectedSoundSourceId, source: sourceIds['SoundSourcesCenterOfMassPoints'] },
          { selected: true }
        );
      }
    }

    return () => {
      if (lastSelectedSoundSourceId && !isEditable) {
        mapBox.setFeatureState(
          { id: lastSelectedSoundSourceId, source: sourceIds['SoundSources'] },
          { selected: false, highlighted: false }
        );
        mapBox.setFeatureState(
          { id: lastSelectedSoundSourceId, source: sourceIds['SoundSourcesCenterOfMassPoints'] },
          { selected: false, highlighted: false }
        );
      }
    };
  }, [isEditable, mapBox, projectItemForPropertiesPanel, soundSources]);

  const handleOnUpdateFeature = useCallback(
    (updateFeature: Feature) => {
      if (!isSoundSource(updateFeature)) return;

      if (isPointSoundSource(updateFeature)) {
        const originalSoundSource: SoundSource = soundSources.find(
          (soundSource: SoundSource) => soundSource.id === updateFeature.id
        );
        const [, , height] = originalSoundSource.geometry.coordinates;
        if (height !== undefined) {
          const updatedSoundSource = cloneDeep(updateFeature) as SoundSource;

          // set sound source height according to user input as z dimension
          updatedSoundSource.geometry.coordinates[2] = height;

          updateSoundSource({ projectId, soundSource: updatedSoundSource });
          return;
        }
      }

      updateSoundSource({ projectId, soundSource: updateFeature });
    },
    [projectId, soundSources, updateSoundSource]
  );

  const handleOnDeleteFeature = useCallback(
    (deleteFeature: Feature) => {
      deleteSoundSource({ projectId, soundSourceId: String(deleteFeature.id) });
    },
    [projectId, deleteSoundSource]
  );

  useEffect(() => {
    setParkingSpaceInfoBoxShown(activeToolbarTool === 'PARKING_AREA');
  }, [activeToolbarTool]);

  useEffect(() => {
    setServiceRoadInfoBoxShown(activeToolbarTool === 'SERVICE_ROAD');
  }, [activeToolbarTool]);

  const soundSourcesCenterOfMassPoints: CenterOfMassPoint[] = useMemo(() => {
    const points: CenterOfMassPoint[] = [];

    if (!soundSources) return points;

    soundSources
      .filter((soundSource: SoundSource) => soundSource.geometry.type === 'Polygon')
      .forEach((soundSource: SoundSource) => {
        const centerOfMassPoint = feature(
          centerOfMass(soundSource).geometry,
          {
            key: String(soundSource.id),
            soundSource,
          },
          { id: soundSource.id }
        );
        return points.push(centerOfMassPoint);
      });

    soundSources
      .filter((soundSource: SoundSource) => soundSource.geometry.type === 'LineString')
      .forEach((soundSource: SoundSource) => {
        const lineLength = length(soundSource);
        const fiftyPercentLineLengthPoint = feature(
          along(soundSource as Feature<LineString>, lineLength / 2).geometry,
          { key: String(soundSource.id), soundSource },
          { id: soundSource.id }
        );
        points.push(fiftyPercentLineLengthPoint);
      });

    return points;
  }, [soundSources]);

  const handleClickCenterOfMassPoints = useCallback(
    (_: MapMouseEvent, __: string, featureIds: string[]) => {
      const [clickedCenterOfMassPointId] = featureIds;

      const clickedCenterOfMassPoint = soundSourcesCenterOfMassPoints.find((p) => p.id === clickedCenterOfMassPointId);

      if (clickedCenterOfMassPoint) {
        dispatch(openProjectItemPropertiesPanel(clickedCenterOfMassPoint.properties.soundSource));
      }
    },
    [dispatch, soundSourcesCenterOfMassPoints]
  );

  useEffect(() => {
    if (!focusedReceiverIdInPropertiesPanel) return () => {};
    if (!receivers || receivers.length === 0) return () => {};

    const foundReceiver = receivers.find((receiver: Receiver) => receiver.id === focusedReceiverIdInPropertiesPanel);

    const highlightSoundSourcesForReceiver = (highlight: boolean) => {
      if (foundReceiver.properties.ignoreInEvaluation) {
        highlight = false;
      }

      const influencers = receiverTools.getAllInfluencersForReceiver(foundReceiver);

      influencers.forEach((influencer) => {
        mapBox.setFeatureState(
          { id: influencer.soundSourceKey, source: sourceIds['SoundSources'] },
          { highlighted: highlight }
        );

        mapBox.setFeatureState(
          { id: influencer.soundSourceKey, source: sourceIds['SoundSourcesCenterOfMassPoints'] },
          { highlighted: highlight }
        );
      });
    };

    if (foundReceiver) {
      highlightSoundSourcesForReceiver(true);
    }

    return () => {
      if (foundReceiver) {
        highlightSoundSourcesForReceiver(false);
      }
    };
  }, [focusedReceiverIdInPropertiesPanel, mapBox, receiverTools, receivers]);

  return (
    <>
      {(!isEditable || viewMode !== '2D') && (
        <>
          <MapBoxMapSource id={sourceIds['SoundSources']} data={soundSources} />
          <MapBoxMapSource id={sourceIds['SoundSourcesCenterOfMassPoints']} data={soundSourcesCenterOfMassPoints} />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesTypePolygon']}
            sourceId={sourceIds['SoundSources']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON}
            cursorOnHover={isSelectable ? 'pointer' : ''}
            onClickFeatures={isSelectable ? handleOnClickFeatures : undefined}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesTypePolygonOutline']}
            sourceId={sourceIds['SoundSources']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POLYGON_OUTLINE}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesTypeLine']}
            sourceId={sourceIds['SoundSources']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_LINE}
            cursorOnHover={isSelectable ? 'pointer' : ''}
            onClickFeatures={isSelectable ? handleOnClickFeatures : undefined}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesCenterOfMassPoints']}
            sourceId={sourceIds['SoundSourcesCenterOfMassPoints']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POINT}
            onClickFeatures={isSelectable ? handleClickCenterOfMassPoints : undefined}
            cursorOnHover={isSelectable ? 'pointer' : ''}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesCenterOfMassPointsSelected']}
            sourceId={sourceIds['SoundSourcesCenterOfMassPoints']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_SELECTED}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesCenterOfMassPointsHighlighted']}
            sourceId={sourceIds['SoundSourcesCenterOfMassPoints']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_HIGHLIGHTED}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesTypePoint']}
            sourceId={sourceIds['SoundSources']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POINT}
            cursorOnHover={isSelectable ? 'pointer' : ''}
            onClickFeatures={isSelectable ? handleOnClickFeatures : undefined}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesTypePointSelected']}
            sourceId={sourceIds['SoundSources']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_SELECTED}
          />
          <MapBoxMapLayer
            id={layerIds['SoundSourcesTypePointHighlighted']}
            sourceId={sourceIds['SoundSources']}
            layerStyle={SOUND_SOURCE_LAYER_STYLE_TYPE_POINT_HIGHLIGHTED}
          />
        </>
      )}
      {isEditable && viewMode === '2D' && (
        <>
          <MapBoxMapDrawLayer
            ref={mapBoxDraw}
            data={soundSources}
            layerStyle={SOUND_SOURCES_DRAW_LAYER_STYLE}
            selectedFeature={projectItemForPropertiesPanel}
            onCreateFeature={handleOnCreateFeature}
            onUpdateFeature={handleOnUpdateFeature}
            onDeleteFeature={handleOnDeleteFeature}
          >
            <SoundSourcesToolbar />
          </MapBoxMapDrawLayer>
          {(parkingSpaceInfoBoxShown || serviceRoadInfoBoxShown) && (
            <MapBoxAlertMessage
              severity="info"
              sx={{ position: 'absolute', top: 60, left: '50%', transform: 'translateX(-50%)' }}
            >
              {parkingSpaceInfoBoxShown && t('pages.architect.project.map.drawAdviceParkingSpace')}
              {serviceRoadInfoBoxShown && t('pages.architect.project.map.drawAdviceServiceRoad')}
            </MapBoxAlertMessage>
          )}
          {genericPointSoundSourceDialogPropsOpen && (
            <CustomSoundSourcePropertiesDialog
              open={genericPointSoundSourceDialogPropsOpen}
              onSave={handeOnSaveSoundSourceInDialog}
              onCancel={handleOnCancelDialogs}
              soundSource={soundSourceForDialog as PointSoundSource}
            />
          )}
          {heightSoundSourceDialogPropsOpen && (
            <SimpleDialogWithInput
              open={heightSoundSourceDialogPropsOpen}
              title={t('pages.architect.project.panels.propertiesDialogTitle', {
                soundSourceLabel: t(`common.soundSourceLabels.${soundSourceForDialog.properties.emissionModel.facet}`),
              })}
              body={t('pages.architect.project.panels.propertiesDialogBody', {
                soundSourceLabel: t(`common.soundSourceLabels.${soundSourceForDialog.properties.emissionModel.facet}`),
              })}
              onClose={handleOnCancelDialogs}
              onCallback={handleOnSaveHeightDialog}
              formField={heightFormField}
            />
          )}
        </>
      )}
    </>
  );
};

export default SoundSourcesLayer;
