import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLazyGetProductSubscriptionQuery } from '@dbel/react-commons/api';
import { Banner } from '@dbel/react-commons/components';
import { PERMISSIONS_WRITE_ACCOUNT } from '../components/AuthGuard';
import useAuth from '../hooks/useAuth';

export const BANNER_HEIGHT = 48;

export const ProductSubscriptionBanner = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [getProductSubscription, { data: productSubscriptions }] = useLazyGetProductSubscriptionQuery();

  useEffect(() => {
    if (user.accountKey) {
      getProductSubscription();
    }
  }, [getProductSubscription, user.accountKey]);

  const handleActionButton = useCallback(() => {
    navigate(`/account/billing`);
  }, [navigate]);

  useEffect(() => {
    if (window.location.pathname.includes('/account/billing/')) return;
    if (!productSubscriptions) return;
    if (productSubscriptions[0]?.status === 'canceled') {
      navigate(`/account/billing`);
    }
  }, [navigate, productSubscriptions]);

  const canWriteAccount = useMemo(() => {
    if (user.permissions === undefined) return false;
    return user.permissions.includes(PERMISSIONS_WRITE_ACCOUNT);
  }, [user]);
  
  useEffect(() => { 
    if (window.location.pathname.includes('/account/billing/')) return;
    if (!productSubscriptions) return;
    if (productSubscriptions[0]?.status === 'canceled') {
      navigate(`/account/billing`);
    }
  }, [navigate, productSubscriptions]);  

  return (
    <>
      {productSubscriptions && productSubscriptions[0]?.status === 'trialing' && (
        <Box sx={{ height: `${BANNER_HEIGHT}px` }}>
          <Banner
            text={t('pages.subscriptionBanner.trialing.bannerText')}
            color="#ff9800"
            button={
              canWriteAccount ? (
                <Button color="secondary" variant="outlined" onClick={handleActionButton}>
                  {t('pages.subscriptionBanner.trialing.buttonText')}
                </Button>
              ) : (
                <></>
              )
            }
          />
        </Box>
      )}
    </>
  );
};
