import { cloneDeep, find, merge } from 'lodash';
import { Building } from '@dbel/shared/types';
import { DrawLayerStyle, MergableDrawLayerStyle } from '../MapBoxMapDrawLayer';

export const mergeDrawLayerStyles = (
  sourceStyle: DrawLayerStyle,
  styleToMerge: MergableDrawLayerStyle
): DrawLayerStyle => {
  const clonedSourceStyle = cloneDeep(sourceStyle);

  styleToMerge.forEach((layerStyle) => {
    const srcLayerStyle = find(clonedSourceStyle, (s) => s.id === layerStyle.id);

    if (srcLayerStyle) {
      merge(srcLayerStyle, layerStyle);
    }
  });

  return clonedSourceStyle;
};

// TODO: this is super ugly but needed but get styles property working.
// Mapbox still cannot handle objects on feature properties.
// Using them does result in weired behaviour of styling expressions
// this can be removed if we remove the logic from the expressions and move it to react

export const flattenBuildingHeightSourceProperty = (building: Building): Building => {
  building = cloneDeep(building);

  (building.properties as any).heightSource = building.properties.height?.source;
  (building.properties as any).heightAboveGroundInMeters = building.properties.height?.aboveGroundInMeters;
  (building.properties as any).heightStoryLevels = building.properties.height?.storyLevels;
  return building;
};

export const flattenBuildings = (buildings: Building[]): Building[] =>
  buildings.map((building) => flattenBuildingHeightSourceProperty(building));
