import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'nprogress/nprogress.css';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { sentryConfig } from './config';
import { AuthProvider } from './contexts/Auth0Context';
import store from './store/store';
import { BITBUCKET_COMMIT } from './version';

Sentry.init({
  autoSessionTracking: true,
  dsn: sentryConfig.baseUrl,
  integrations: [new BrowserTracing()],
  environment: sentryConfig.env,
  release: 'dbel-platform-frontend@0.0.1',
  enabled: import.meta.env.PROD,
  dist: BITBUCKET_COMMIT,
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: sentryConfig.sampleRate,
  // debug: !import.meta.env.PROD,
});

const root = createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ReduxProvider>
  </HelmetProvider>
);
