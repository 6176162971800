import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Drawer, IconButton, Stack, Theme, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useMatch } from 'react-router-dom';
import { useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { MapBoxOverlayContainer } from '@dbel/react-commons/components';
import { ProjectType } from '@dbel/shared/types';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { ReactComponent as DBelLogoIcon } from '../../../icons/dBelLogo.svg';
import useAuth from '../../../hooks/useAuth';
import { AppMenu } from '../app-menu/AppMenu';
import { AppModeSwitcher } from './AppModeSwitcher';
import { MapModeSwitcher } from './MapModeSwitcher';
import AccountPopover from '../../dashboard/AccountPopover';

export const MainMenu = () => {
  // TODO: that not good but currently react router has no better path matching
  const isSettingsRoute = useMatch({ path: '/architect/project/:projectId/settings' }) !== null;
  const auth = useAuth();

  const projectId = useProjectIdFromUrl();
  const { data: project, isSuccess } = useGetProjectByIdQuery(projectId ?? skipToken);
  const [appMenuOpen, setAppMenuOpen] = useState(false);

  const handleAppMenuToggle = () => {
    setAppMenuOpen(!appMenuOpen);
  };

  const location = useLocation();

  const hasProjectData = isSuccess && project.type !== ProjectType.ROOM_ACOUSTICS;

  useEffect(() => {
    setAppMenuOpen(false);
  }, [location]);

  return (
    <>
      <Stack direction="row">
        {auth.accountStatus === 'ACTIVE' && (
          <MapBoxOverlayContainer
            sx={{
              display: 'flex',
              maxWidth: '450px',
              minWidth: 0,
              alignItems: 'center',
            }}
          >
            <DBelLogoIcon sx={{ mx: 1 }} />

            <IconButton onClick={handleAppMenuToggle}>
              <MenuIcon />
            </IconButton>

            {hasProjectData && (
              <>
                <Typography variant="body2" noWrap sx={{ mx: 1, fontWeight: 'bold' }} title={project?.name}>
                  {project?.name}
                </Typography>
                <IconButton component={RouterLink} to={`/architect/project/${projectId}/settings`}>
                  <SettingsOutlinedIcon />
                </IconButton>
              </>
            )}
          </MapBoxOverlayContainer>
        )}
        {hasProjectData && !isSettingsRoute && auth.accountStatus === 'ACTIVE' && (
          <>
            <AppModeSwitcher
              sx={{
                ml: 1,
              }}
            />
            <MapModeSwitcher
              sx={{
                mx: 1,
              }}
            />
          </>
        )}
        {auth.accountStatus === 'ACTIVE' && (
          <Drawer
            variant="temporary"
            open={appMenuOpen}
            onClose={handleAppMenuToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              '& .MuiDrawer-paper': { borderRadius: 0, width: 300 },
              zIndex: (theme: Theme) => theme.zIndex.modal + 1,
            }}
          >
            <IconButton sx={{ position: 'absolute', right: 5, top: 12 }} onClick={handleAppMenuToggle}>
              <CloseIcon />
            </IconButton>
            <AppMenu />
          </Drawer>
        )}
        {auth.accountStatus === 'ACTIVE' && <AccountPopover sx={{ ml: 'auto', pointerEvents: 'auto' }} />}
      </Stack>
    </>
  );
};
