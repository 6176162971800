import { styled } from '@mui/material/styles';
import { Outlet } from 'react-router-dom';

const Content = styled('div')(({ theme }) =>
  theme.unstable_sx({
    padding: '20px',
    flexGrow: 1,
    overflow: 'auto',
  })
);

export const AllAccountPagesLayout = () => (
  <>
    <Content>
      <Outlet />
    </Content>
  </>
);
