import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

export interface ExpertSettingsButtonProps {
  onClick: () => void;
}

export const ExpertSettingsButton = ({ onClick }: ExpertSettingsButtonProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Button variant="text" color="secondary" startIcon={<EditIcon />} onClick={onClick}>
        {t('pages.architect.project.drawer.editOctaveBandButton')}
      </Button>
    </>
  );
};
