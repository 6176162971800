export const SELECTED_BORDER_COLOR = '#FFFFFF';

export const BUILDING_BACKGROUND_COLOR = '#DCD9D6';
export const BUILDING_SURROUNDING_COLOR = '#5664D2';
export const BUILDING_SURROUNDING_3D_COLOR = '#858dc8';
export const BUILDING_SURROUNDING_3D_SELECTED_COLOR = '#4f56a5';

export const BUILDING_PROJECT_COLOR = '#09A7B1';
export const BUILDING_PROJECT_3D_COLOR = '#50bfc7';
export const BUILDING_PROJECT_3D_SELECTED_COLOR = '#25abb8';

export const BUILDING_IGNORED_COLOR = '#2C2E42';

export const HIGHLIGHTED_WALL_COLOR = '#4f56a5';

export const SOUND_SOURCE_COLOR = '#E91E63';
export const SOUND_SOURCE_SELECTED_COLOR = '#E91E63';
export const SOUND_SOURCE_HIGHLIGHTED_COLOR = '#2196F3';

export const SCALE_PLAN_COLOR = '#FF9800';

export const RECEIVER_BORDER_COLOR = '#FFF';
export const RECEIVER_IGNORED_COLOR = '#2C2E42';

export const PROJECT_AREA_BORDER_COLOR = '#4030FF';

export const PROJECT_PLAN_BORDER_COLOR = '#2196F3';

export const TA_LAERM_COLOR_CRITICALITY_UNKNOWN = '#2C2E42';
export const TA_LAERM_COLOR_CRITICALITY_LOW = '#36DB46';
export const TA_LAERM_COLOR_CRITICALITY_MEDIUM = '#F2BD33';
export const TA_LAERM_COLOR_CRITICALITY_HIGH = '#E44628';
