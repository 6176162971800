import {
  useCreateCalculationMutation,
  useLazyGetCalculationResultByIdQuery,
  useLazyGetMessagesQuery,
} from '@dbel/react-commons/api';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { LoadingButton } from '@mui/lab';
import { useCallback, useEffect, useState } from 'react';
import { createCalculationRequest } from '@dbel/react-commons/hooks';
import { useProjectIdFromUrl } from '../../../../../hooks/useProjectIdFromUrl';

const DEBUG_VISUALIZER_BASE_URL = '/debug-viewer/index.html?projectUrl=';

export interface DebugCalculationDataButtonProps {
  soundSourceId: string;
  receiverId: string;
}

export const DebugSoundSourceForReceiverButton = ({ soundSourceId, receiverId }: DebugCalculationDataButtonProps) => {
  const projectId = useProjectIdFromUrl();

  // TODO: Move the main body of logic to useCreateCalculationHooks
  // const { isLoading } = useCreateCalculationHooks(projectId);

  const [getCalculationResult] = useLazyGetCalculationResultByIdQuery();
  const [loading, setLoading] = useState<boolean>(false);

  const [calculationKey, setCalculationKey] = useState<string>();

  const [getMessages, { data: messages }] = useLazyGetMessagesQuery({
    selectFromResult: (result) => ({
      ...result,
      data: result.data || [],
    }),
  });

  const [createCalculation] = useCreateCalculationMutation();

  const handleOnStartCalculation = useCallback(() => {
    setLoading(true);
    const calculationRequest = createCalculationRequest(projectId, [receiverId], [soundSourceId], false, true);
    getMessages(projectId);

    createCalculation(calculationRequest)
      .unwrap()
      .then((createCalculationResult) => {
        setCalculationKey(createCalculationResult.calculationKey);
      })
      .catch((error) => console.error('TBI error creating calculation', error));
  }, [createCalculation, getMessages, projectId, receiverId, soundSourceId]);

  useEffect(() => {
    if (!messages || messages.length === 0) return;
    if (!calculationKey) return;

    messages.forEach((message) => {
      if (message.taskReference === calculationKey) {
        switch (message.taskStatus) {
          case 'succeeded':
            getCalculationResult({ calculationKey })
              .unwrap()
              .then((results) => {
                setLoading(false);
                if (!results.debugPaths) return;
                // const newWindowUrl = DEBUG_VISUALIZER_BASE_URL + encodeURIComponent(debugUrl);
                const newWindowUrl = DEBUG_VISUALIZER_BASE_URL + encodeURIComponent(results.debugPaths[0]);
                window.open(newWindowUrl, '_blank');
              });
            break;
          case 'failed':
            console.error('calculation failed');
            break;
          default:
            break;
        }
      }
    });
  }, [calculationKey, getCalculationResult, messages]);

  return (
    <>
      <LoadingButton onClick={handleOnStartCalculation} loading={loading} size="small" variant="text">
        <ViewInArIcon />
      </LoadingButton>
    </>
  );
};
