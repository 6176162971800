import { TabContext } from '@mui/lab';
import { Divider, Stack } from '@mui/material';
import { cloneDeep } from 'lodash';
import { useUpdateWallMutation, useDeleteWallMutation, useGetWallsQuery } from '@dbel/react-commons/api';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wall } from '@dbel/shared/types';
import { DeleteButton } from '@dbel/react-commons/components';
import { useProjectIdFromUrl } from '../../../../hooks/useProjectIdFromUrl';
import { closeProjectItemPropertiesPanel } from '../../../../store/slices/map';
import { useDispatch } from '../../../../store/store';
import { CustomTab, CustomTabs, DrawerPropertiesTabsType } from '../building';
import { TitleFormField } from '../building/properties-tab/TitleFormField';
import { PropertiesTab } from '../building/PropertiesTab';

export interface WallsPropertiesPanelProps {
  wall: Wall;
}

function getWallPlaceholder(wall: Wall) {
  return String(wall.id).slice(0, 10);
}

export const WallsPropertiesPanel = ({ wall }: WallsPropertiesPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectId = useProjectIdFromUrl();
  const [updateWall] = useUpdateWallMutation();
  const [deleteWall] = useDeleteWallMutation();
  const { data: walls } = useGetWallsQuery(projectId);

  const [activeTab, setActiveTab] = useState<DrawerPropertiesTabsType>('PROPERTIES');

  // if the projectItemForPropertiesPanel is not in the walls list anymore, close panel
  useEffect(() => {
    if (!walls) return;
    const foundBuilding = walls.find((item: Wall) => item.id === wall.id);
    if (!foundBuilding) {
      dispatch(closeProjectItemPropertiesPanel());
    }
  }, [walls, dispatch, wall.id]);

  const onTitleFormFieldChanged = useCallback(
    (title: string) => {
      const wallForUpdate = cloneDeep(wall);

      if (title.trim() === '') {
        delete wallForUpdate.properties.name;
      } else {
        wallForUpdate.properties.name = title;
      }

      updateWall({ projectId, wall: wallForUpdate });

      // TODO: needed?
      //  dispatch(openProjectItemPropertiesPanel(buildingForUpdate));
    },
    [projectId, updateWall, wall]
  );
  //(event: React.SyntheticEvent, value: any)
  const handleOnChangeTab = useCallback((_: SyntheticEvent, newActiveTabIndex: DrawerPropertiesTabsType) => {
    setActiveTab(newActiveTabIndex);
  }, []);

  const handleOnClickDeleteButton = useCallback(() => {
    // TODO: introduce some kind of usePanel Hook for this
    dispatch(closeProjectItemPropertiesPanel());
    deleteWall({ projectId, wallId: wall.id });
  }, [dispatch, deleteWall, projectId, wall.id]);

  return (
    <>
      <Stack direction="column" spacing={1.5} sx={{ alignItems: 'flex-start' }}>
        <TitleFormField feature={wall} placeHolder={getWallPlaceholder(wall)} onSubmit={onTitleFormFieldChanged} />
        <Divider />
        <TabContext value={activeTab}>
          <CustomTabs value={activeTab} onChange={handleOnChangeTab}>
            <CustomTab label={t('pages.architect.project.drawer.buildingTab.propertiesTabTitle')} value="PROPERTIES" />
          </CustomTabs>

          <PropertiesTab feature={wall} forTab="PROPERTIES" />
        </TabContext>
        <Divider />

        <DeleteButton
          label={t('pages.architect.project.drawer.removeWallButton')}
          onClick={handleOnClickDeleteButton}
        />
      </Stack>
    </>
  );
};
