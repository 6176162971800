import { FormControl, FormHelperText, Input } from '@mui/material';
import { Building, Wall } from '@dbel/shared/types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import i18n from 'i18next';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { PropertyLabel } from '../../common/PropertyLabel';

const MAX_LENGTH = 100;

interface FormFields {
  title: string | null;
}

const formValidationSchema = Yup.object().shape({
  title: Yup.string()
    .label(i18n.t('common.labels.title'))
    .nullable()
    .max(MAX_LENGTH, i18n.t('errors.formMaxLength', { property: i18n.t('common.labels.title'), value: MAX_LENGTH })),
});

function getFormDefaultValues(feature: Building | Wall): FormFields {
  return {
    title: feature.properties.name ?? '',
  };
}

export interface TitleFormProps {
  feature: Building | Wall;
  placeHolder: string;
  onSubmit: (title: string) => void;
}

export const TitleFormField = ({ feature, placeHolder, onSubmit }: TitleFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    defaultValues: getFormDefaultValues(feature),
  });

  const onFormSubmit = useCallback<SubmitHandler<FormFields>>(
    ({ title }) => {
      if (!isDirty) return;

      onSubmit(title);
    },
    [isDirty, onSubmit]
  );

  const handleOnBlurTextField = useCallback(() => {
    handleSubmit(onFormSubmit)();
  }, [handleSubmit, onFormSubmit]);

  useEffect(() => {
    reset(getFormDefaultValues(feature));
  }, [feature, reset]);

  return (
    <>
      <FormControl variant="standard" error={errors.title !== undefined} fullWidth>
        <PropertyLabel label={t('pages.architect.project.drawer.titleLabel')} />
        <Input
          {...register('title')}
          onBlur={handleOnBlurTextField}
          placeholder={placeHolder}
          inputProps={{ style: { fontSize: 16, fontWeight: '700' } }}
        />

        <FormHelperText>{errors.title ? errors.title?.message : ' '}</FormHelperText>
      </FormControl>
    </>
  );
};
