import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CustomDialogTitle, formatPowerLevel } from '@dbel/react-commons/components';
import i18n from 'i18next';
import { clone, cloneDeep } from 'lodash';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isCustomPointEmissionModel } from '@dbel/shared/emission-models';
import { OctaveBand, PointSoundSource, OCTAVE_BAND_FREQUENCIES, SoundPowerLevel } from '@dbel/shared/types';
import { aWeight, calcSumLevel, calcSumLevelInDbA } from '@dbel/shared/util';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { formatFrequency } from '../../../utils/formatters';
import { CustomSoundSourcePropertiesDialogProps, MAX_DB, MAX_HEIGHT, MIN_DB, MIN_HEIGHT } from './types';

const DB_VALUE_FOR_NOT_SPECIFIED_FREQUENCY: number = -99;

type OctaveBandOptionalDbValue = number | null;
type OctaveBandWithNotSpecifiedDbValues = [
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue,
  OctaveBandOptionalDbValue
];

type FormFields = {
  height: number;
  octaveBand?: OctaveBandWithNotSpecifiedDbValues;
};

const formValidationSchema = Yup.object().shape({
  height: Yup.number()
    .label(i18n.t('common.labels.height'))
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(i18n.t('errors.formRequired', { property: i18n.t('common.labels.height') }))
    .min(MIN_HEIGHT, i18n.t('errors.formMinValue', { property: i18n.t('common.labels.height'), value: MIN_HEIGHT }))
    .max(MAX_HEIGHT, i18n.t('errors.formMaxValue', { property: i18n.t('common.labels.height'), value: MAX_HEIGHT }))
    .nullable(),
  octaveBand: Yup.array()
    .of(
      Yup.number()
        .label(i18n.t('common.labels.frequency'))
        .min(MIN_DB, i18n.t('errors.formMinValue', { property: i18n.t('common.labels.frequency'), value: MIN_DB }))
        .max(MAX_DB, i18n.t('errors.formMaxValue', { property: i18n.t('common.labels.frequency'), value: MAX_DB }))
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
    )
    .test({
      name: 'testOneDbValueSpecified',
      message: 'errors.formOneFrequency',
      test: (dbValues) => dbValues.some((dbValue) => dbValue !== null),
      exclusive: true,
    }),
});

function getOctaveBandFromFormValue(
  octaveBandWithNotSpecifiedDbValues: OctaveBandWithNotSpecifiedDbValues
): OctaveBand {
  const octaveBand: OctaveBand = clone(octaveBandWithNotSpecifiedDbValues);

  for (let i = 0; i < octaveBand.length; i += 1) {
    octaveBand[i] =
      octaveBand[i] === null || Number.isNaN(octaveBand[i]) ? DB_VALUE_FOR_NOT_SPECIFIED_FREQUENCY : octaveBand[i];
  }

  return octaveBand;
}

function getFormValueFromOctaveBand(octaveBand: OctaveBand): OctaveBandWithNotSpecifiedDbValues {
  const octaveBandFormValue: OctaveBand = clone(octaveBand);

  for (let i = 0; i < octaveBandFormValue.length; i += 1) {
    octaveBandFormValue[i] =
      octaveBandFormValue[i] === DB_VALUE_FOR_NOT_SPECIFIED_FREQUENCY ? null : octaveBandFormValue[i];
  }
  return octaveBandFormValue;
}

function calcAWeight(frequency: number, dbValue: number | null): string {
  return !dbValue || Number.isNaN(dbValue) ? '' : String(aWeight(frequency, dbValue));
}

function calcTotalDb(octaveBandWithNotSpecifiedDbValues: OctaveBandWithNotSpecifiedDbValues): number {
  return calcSumLevel(getOctaveBandFromFormValue(octaveBandWithNotSpecifiedDbValues));
}

function calcTotalDbA(octaveBandWithNotSpecifiedDbValues: OctaveBandWithNotSpecifiedDbValues): number {
  return calcSumLevelInDbA(getOctaveBandFromFormValue(octaveBandWithNotSpecifiedDbValues));
}

function getFormDefaultValues(soundSource: PointSoundSource): FormFields {
  let octaveBand: OctaveBandWithNotSpecifiedDbValues = [null, null, null, null, null, null, null, null, null, null];

  const emissionModel = soundSource?.properties.emissionModel;

  if (isCustomPointEmissionModel(emissionModel)) {
    // WOEDBL-1729: if fixedSoundPowerLevelLin is not set, then we assume that the sound source was created without this bugfix
    // Since the entire custom sound source will be redesigned we don't do a database migration an rather handle this here
    const soundPowerLevel: typeof emissionModel.fixedSoundPowerLevelLin =
      emissionModel.fixedSoundPowerLevelLin || emissionModel.fixedSoundPowerLevel;

    if (soundPowerLevel && 'day' in soundPowerLevel) {
      if (Array.isArray(soundPowerLevel.day)) {
        octaveBand = getFormValueFromOctaveBand(soundPowerLevel.day);
      } else {
        octaveBand = [null, null, null, null, soundPowerLevel.day, null, null, null, null, null];
      }
    }
  }

  return {
    height: soundSource?.geometry.coordinates[2] ?? 1,
    octaveBand,
  };
}

export const ComplexSoundSourcePropertiesDialog = ({
  open,
  onSave,
  onCancel,
  soundSource,
}: CustomSoundSourcePropertiesDialogProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields, isValid: isFormValid, isDirty: isFormDirty },
  } = useForm<FormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    defaultValues: getFormDefaultValues(soundSource),
  });

  const octaveBandFormValue = useWatch({ name: 'octaveBand', control });

  const handleOnCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onFormSubmit = useCallback<SubmitHandler<FormFields>>(
    ({ height, octaveBand: octaveBandWithNotSpecifiedDbValues }) => {
      const updatedSoundSource = cloneDeep(soundSource);

      // set sound source height according to user input as z dimension
      updatedSoundSource.geometry.coordinates[2] = height;

      const { emissionModel } = updatedSoundSource.properties;

      if (isCustomPointEmissionModel(emissionModel)) {
        const octaveBand = getOctaveBandFromFormValue(octaveBandWithNotSpecifiedDbValues);
        // TKR: This is a temporary hack. This needs to be redesigned completly
        const aWeightedValues = OCTAVE_BAND_FREQUENCIES.map((frequency, index) =>
          octaveBand[index] !== -99 ? Number(calcAWeight(frequency, octaveBand[index])) : -99
        );

        emissionModel.fixedSoundPowerLevelLin = {
          day: octaveBand,
          night: octaveBand,
        };

        // TKR: We need to cast to SoundPowerLevel here b/c of the above hack
        emissionModel.fixedSoundPowerLevel = {
          day: aWeightedValues as SoundPowerLevel,
          night: aWeightedValues as SoundPowerLevel,
        };
      }

      onSave(updatedSoundSource);
    },
    [onSave, soundSource]
  );

  useEffect(() => {
    reset(getFormDefaultValues(soundSource));
  }, [soundSource, reset]);

  return (
    <>
      <Dialog open={open} onClose={handleOnCancel} fullWidth maxWidth="md" aria-labelledby="form-dialog-title">
        <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
          <CustomDialogTitle onClickClose={handleOnCancel}>
            {t('pages.architect.project.panels.soundSourcePropertiesTitle')}
          </CustomDialogTitle>
          <DialogContent dividers>
            <Stack direction="column" spacing={2}>
              <TextField
                {...register('height', {
                  valueAsNumber: true,
                })}
                id="height"
                label={t('pages.architect.project.panels.heightAboveGroundLabel')}
                autoFocus
                required
                type="number"
                size="small"
                error={errors.height !== undefined}
                helperText={errors.height?.message}
                inputProps={{
                  min: MIN_HEIGHT,
                  max: MAX_HEIGHT,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('common.units.meter')}</InputAdornment>,
                }}
              />
              <Typography variant="h6">{t('pages.architect.project.panels.soundPowerLevelsTitle')}</Typography>

              <Typography>{t('pages.architect.project.panels.complexSoundPowerLevelsBody')}</Typography>
              <Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  justifyItems="center"
                  alignItems="center"
                  gap={0.5}
                >
                  {/* Frequency line */}

                  <Typography gridColumn="1 / span 1" gridRow={1} justifySelf="end">
                    {t('pages.architect.project.panels.frequencyTitle')}
                  </Typography>

                  {OCTAVE_BAND_FREQUENCIES.map((frequency, index) => (
                    <InputLabel
                      key={index}
                      htmlFor={`frequencyInput_${index}`}
                      sx={{
                        gridColumn: `${index + 2} / span 1`,
                        gridRow: 1,
                      }}
                    >
                      {formatFrequency(frequency)}
                    </InputLabel>
                  ))}

                  <Typography gridColumn="12 / span 1" gridRow={1} justifySelf="start">
                    {t('common.units.hertz')}
                  </Typography>

                  {/* Level per frequency line in db */}

                  <Typography gridColumn="1 / span 1" gridRow={2} justifySelf="end">
                    {t('pages.architect.project.panels.levelsTitle')}
                  </Typography>

                  {OCTAVE_BAND_FREQUENCIES.map((_, index) => (
                    // TODO: how to avoid this as any here?
                    <OutlinedInput
                      {...register(`octaveBand.${index}` as any, {
                        valueAsNumber: true,
                      })}
                      key={`frequency_${index}`}
                      id={`frequencyInput_${index}`}
                      type="number"
                      error={
                        dirtyFields.octaveBand &&
                        (errors.octaveBand?.[index] !== undefined || errors.octaveBand?.message !== undefined)
                      }
                      size="small"
                      inputProps={{
                        min: MIN_DB,
                        max: MAX_DB,
                      }}
                      fullWidth
                      sx={{
                        gridColumn: `${index} + 2`,
                        gridRow: 2,
                        '& input': { p: 0.5, textAlign: 'center' },
                      }}
                    />
                  ))}

                  <Typography gridColumn="12 / span 1" gridRow={2} justifySelf="start">
                    {t('common.units.decibel')}
                  </Typography>

                  {/* Total level line in db */}

                  <Typography gridColumn="1 / span 1" gridRow={3} justifySelf="end">
                    {t('pages.architect.project.panels.bandsTotalTitle')}
                  </Typography>
                  <Typography gridColumn="2 / span 10" gridRow={3}>
                    {isFormValid && formatPowerLevel(calcTotalDb(octaveBandFormValue))}
                  </Typography>
                  <Typography gridColumn="12 / span 1" gridRow={3} justifySelf="start">
                    {t('common.units.decibel')}
                  </Typography>

                  {/* A-weighted level per frequency line in dB(A) */}

                  <Typography
                    display="inline"
                    gridColumn="1 / span 1"
                    gridRow={4}
                    justifySelf="end"
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {t('pages.architect.project.panels.aWeightedTitle')}
                  </Typography>

                  {OCTAVE_BAND_FREQUENCIES.map((frequency, index) => (
                    <OutlinedInput
                      value={
                        errors.octaveBand?.[index]
                          ? ''
                          : formatPowerLevel(calcAWeight(frequency, octaveBandFormValue[index]))
                      }
                      key={`frequency_${index + 1}_calculated-dba`}
                      disabled
                      fullWidth
                      sx={{
                        gridColumn: `${index + 2}/ span 1`,
                        gridRow: 4,
                        '& input': { p: 0.5, textAlign: 'center' },
                      }}
                    />
                  ))}
                  <Typography gridColumn="12 / span 1" gridRow={4} justifySelf="start">
                    {t('common.units.dBA')}
                  </Typography>

                  {/* Total A-weighted level line in db(A) */}

                  <Typography gridColumn="1 / span 1" gridRow={5} justifySelf="end">
                    {t('pages.architect.project.panels.totalTitle')}
                  </Typography>
                  <Typography
                    gridColumn="2 / span 10"
                    gridRow={5}
                    sx={{ justifySelf: 'stretch', textAlign: 'center', backgroundColor: '#f1f1f1' }}
                  >
                    {isFormValid && formatPowerLevel(calcTotalDbA(octaveBandFormValue))}
                  </Typography>
                  <Typography gridColumn="12 / span 1" gridRow={5} justifySelf="start">
                    {t('common.units.dBA')}
                  </Typography>
                </Box>
                {Array.isArray(errors.octaveBand) &&
                  errors.octaveBand.map((error, index) => (
                    <FormHelperText key={`frequency_error_${index + 1}`} error>
                      {`${OCTAVE_BAND_FREQUENCIES[index]} Hz ${error.message}`}
                    </FormHelperText>
                  ))}

                {!Array.isArray(errors.octaveBand) && errors.octaveBand && dirtyFields.octaveBand && (
                  <FormHelperText error>{t((errors.octaveBand as any)?.message)}</FormHelperText>
                )}
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleOnCancel}>
              {t('common.buttonLabels.cancel')}
            </Button>
            <Button color="primary" type="submit" disabled={!isFormValid || !isFormDirty}>
              {t('common.buttonLabels.save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
