import { Divider, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUpdateSoundSourceMutation,
  useDeleteSoundSourceMutation,
  useGetSoundSourcesQuery,
} from '@dbel/react-commons/api';
import { DeleteButton, PropertyField, formatPowerLevel } from '@dbel/react-commons/components';
import { EmissionModelFacet, emissionModelCalculatorService } from '@dbel/shared/emission-models';
import { SoundSource, isPointSoundSource } from '@dbel/shared/types';
import { calcSumLevel } from '@dbel/shared/util';
import { useProjectIdFromUrl } from '../../../../hooks/useProjectIdFromUrl';
import { closeProjectItemPropertiesPanel, openProjectItemPropertiesPanel } from '../../../../store/slices/map';
import { useDispatch } from '../../../../store/store';
import { CustomSoundSourcePropertiesDialog } from '../../../dialogs/CustomSoundSourcePropertiesDialog/CustomSoundSourcePropertiesDialog';
import { ExpertSettingsButton } from '../common/ExpertSettingsButton';
import { PropertyDropDown } from '../common/PropertyDropdown';
import { TitlePropertyField } from '../common/TitlePropertyField';

export interface SoundSourcePropertiesPanelProps {
  soundSource: SoundSource;
}

export const SoundSourcePropertiesPanel = ({ soundSource }: SoundSourcePropertiesPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectId = useProjectIdFromUrl();
  const [updateSoundSource] = useUpdateSoundSourceMutation();
  const [deleteSoundSource] = useDeleteSoundSourceMutation();

  const projectSoundSources = soundSource.properties.isProjectSoundSource;
  const { data: soundSourcesData } = useGetSoundSourcesQuery({ projectId });

  const soundSources = useMemo(() => {
    if (!soundSourcesData) return [];
    return soundSourcesData.filter(
      (soundSourceItem) => soundSourceItem.properties.isProjectSoundSource === projectSoundSources
    );
  }, [projectSoundSources, soundSourcesData]);

  const [customSoundSourcePropertiesDialogOpen, setCustomSoundSourcePropertiesDialogOpen] = useState<boolean>(false);

  // if the projectItemForPropertiesPanel is not in the buildings list anymore, close panel
  useEffect(() => {
    if (!soundSources) return;
    const foundSoundSource = soundSources.find((item: SoundSource) => item.id === soundSource.id);
    if (!foundSoundSource) {
      dispatch(closeProjectItemPropertiesPanel());
    }
  }, [soundSources, dispatch, soundSource.id]);

  const isCustomSoundSource = (
    ['CUSTOM_AREA_SOUND_SOURCE', 'CUSTOM_LINE_SOUND_SOURCE', 'CUSTOM_POINT_SOUND_SOURCE'] as EmissionModelFacet[]
  ).includes(soundSource.properties.emissionModel.facet);

  const handleOnClickDeleteButton = useCallback(() => {
    // TODO: remove String() here
    dispatch(closeProjectItemPropertiesPanel());
    deleteSoundSource({ projectId, soundSourceId: String(soundSource.id) });
  }, [soundSource, deleteSoundSource, dispatch, projectId]);

  const handleOnClickExpertSettingsButton = useCallback(() => {
    setCustomSoundSourcePropertiesDialogOpen(true);
  }, []);

  const handleOnSaveSoundSourceDialog = useCallback(
    async (updatedSoundSource: SoundSource) => {
      await updateSoundSource({ projectId, soundSource: updatedSoundSource });

      setCustomSoundSourcePropertiesDialogOpen(false);

      // TODO: why is this really needed because I thought das the props panel will automatically be updated upon data changes in the client cache?
      dispatch(openProjectItemPropertiesPanel(updatedSoundSource));
    },
    [dispatch, projectId, updateSoundSource]
  );

  const handleOnCancelSoundSourceDialog = useCallback(() => {
    setCustomSoundSourcePropertiesDialogOpen(false);
  }, []);

  let height: number | undefined;

  const { emissionModel } = soundSource.properties;
  // TODO: DISCUSS: Warum sollte emissionModel hier undefined sein? Per Model darf das nicht passieren
  const { day, night } = emissionModel
    ? emissionModelCalculatorService.calculate(emissionModel)
    : { day: undefined, night: undefined };

  const noiseLevelDay = day ? formatPowerLevel(calcSumLevel(day)) : formatPowerLevel(undefined);
  const noiseLevelNight = night ? formatPowerLevel(calcSumLevel(night)) : formatPowerLevel(undefined);

  // TODO: DISCUSS: Why is height saved as z coordinate and not as a parameter?
  if (isPointSoundSource(soundSource)) {
    [, , height] = soundSource.geometry.coordinates;
  }

  return (
    <>
      <Stack direction="column" spacing={1.5} sx={{ alignItems: 'flex-start' }}>
        <TitlePropertyField feature={soundSource} />

        <Divider />
        {(soundSource.properties.emissionModel.type === 'SIZED_POINT_EMISSION_MODEL' ||
          soundSource.properties.emissionModel.type === 'SIZED_AREA_EMISSION_MODEL') && (
          <PropertyDropDown soundSource={soundSource} />
        )}

        {height !== undefined && (
          <PropertyField label={t('pages.architect.project.drawer.soundSourceHeight')} value={height} valueUnit="m" />
        )}

        <PropertyField
          label={t('pages.architect.project.drawer.noiseLevelLabelDay')}
          value={noiseLevelDay}
          valueUnit="dba"
        />

        <PropertyField
          label={t('pages.architect.project.drawer.noiseLevelLabelNight')}
          value={noiseLevelNight}
          valueUnit="dba"
        />

        {isCustomSoundSource && (
          <>
            <Divider />
            <ExpertSettingsButton onClick={handleOnClickExpertSettingsButton} />
          </>
        )}

        <Divider />

        <DeleteButton
          label={t('pages.architect.project.drawer.deleteSoundSourceButton')}
          onClick={handleOnClickDeleteButton}
        />
      </Stack>

      {/* TODO: Only Point sound sources allowed as of now */}
      {isPointSoundSource(soundSource) && customSoundSourcePropertiesDialogOpen && (
        <CustomSoundSourcePropertiesDialog
          open={customSoundSourcePropertiesDialogOpen}
          onCancel={handleOnCancelSoundSourceDialog}
          onSave={handleOnSaveSoundSourceDialog}
          soundSource={soundSource}
        />
      )}
    </>
  );
};
