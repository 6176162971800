import { Criticality } from '@dbel/react-commons/types';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export interface CriticalityRatingMessageProps {
  criticality: Criticality;
}

export const CriticalityRatingProbabilityMessage = ({ criticality }: CriticalityRatingMessageProps) => {
  const { t } = useTranslation();

  const probabilityMessage = useMemo(() => {
    const { differenceToLowCriticality } = criticality;

    if (Number.isNaN(differenceToLowCriticality)) return t('evaluationMessages.rating.unknown');

    switch (true) {
      case differenceToLowCriticality <= 0:
        return t('evaluationMessages.rating.low');

      case differenceToLowCriticality > 0 && differenceToLowCriticality <= 4:
        return t('evaluationMessages.rating.mediumLow');

      case differenceToLowCriticality > 4 && differenceToLowCriticality <= 8:
        return t('evaluationMessages.rating.medium');

      case differenceToLowCriticality > 8 && differenceToLowCriticality <= 12:
        return t('evaluationMessages.rating.mediumHigh');

      default:
        return t('evaluationMessages.rating.hight');
    }
  }, [criticality, t]);

  return (
    <>
      <Typography fontWeight="bold" component="span">
        {probabilityMessage}
      </Typography>
    </>
  );
};
