import { LoadingButton } from '@mui/lab';
import { Stack, Theme } from '@mui/material';
import { bbox, feature } from '@turf/turf';
import { useGetProductSubscriptionQuery, useCreateProjectMutation } from '@dbel/react-commons/api';
import {
  useMapBox,
  calculateProjectArea,
  MapBoxOverlayContainer,
  MapBoxMapPlaceInput,
  MapBoxMapMarker,
  formatLanguage,
} from '@dbel/react-commons/components';
import { useProjectAreaValidator } from '@dbel/react-commons/hooks';
import { MapBoxGeocodingFeature } from '@dbel/react-commons/types';
import { Polygon } from 'geojson';
import { LngLat, LngLatLike } from 'mapbox-gl';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CreateProjectInput, ProjectAreaGeometry, ProjectSettings, ProjectType } from '@dbel/shared/types';
import i18next from 'i18next';
import BuildingsAndWallsLayer from '../layers/BuildingsAndWallsLayer';
import { ProjectAreaLayer } from '../layers/ProjectAreaLayer';
import { CreateProjectToolbar } from '../toolbars/CreateProjectToolbar';
import { ProjectAreaStatusMessage } from './create-project/ProjectAreaStatusMessage';
import { BANNER_HEIGHT } from '../../../pages/ProductSubscriptionBanner';

const DEFAULT_PROJECT_AREA_SIDE_LENGTH_IN_METERS = 350;

const CreateProjectMapMode = () => {
  const { t } = useTranslation();
  const { language } = i18next;
  const navigate = useNavigate();
  const { mapBox } = useMapBox();

  const { data: productSubscriptions } = useGetProductSubscriptionQuery();

  const bannerHeight = useMemo(() => {
    if (productSubscriptions === undefined) return 0;

    const productSubscription = productSubscriptions[0];
    if (productSubscription?.status === 'trialing') return BANNER_HEIGHT;
    return 0;
  }, [productSubscriptions]);

  //const { state } = useLocation(); if you goto this page with an project name -> state.projectName = project name

  const [placeName, setPlaceName] = useState<string | undefined>();
  const [placeCenter, setPlaceCenter] = useState<[number, number] | undefined>();

  const projectPlaceSelected = placeName !== undefined && placeCenter !== undefined;
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);

  const [isMapAnimating, setIsMapAnimating] = useState<boolean>(false);

  const [placeMarkerPosition, setPlaceMarkerPosition] = useState<LngLatLike | undefined>();

  const [projectArea, setProjectArea] = useState<ProjectAreaGeometry | undefined>();
  const { validationResult, isValidating: projectAreaIsValidating } = useProjectAreaValidator(projectArea);

  const [createProject, { isLoading: isCreatingProject }] = useCreateProjectMutation();

  const handleOnSelectPlace = useCallback(
    (selectedPlace: MapBoxGeocodingFeature) => {
      setPlaceName(selectedPlace.place_name);
      setPlaceCenter(selectedPlace.center);
      setSearchInProgress(false);

      setPlaceMarkerPosition(LngLat.convert(selectedPlace.center));

      const calculatedProjectArea = calculateProjectArea(
        selectedPlace.center,
        DEFAULT_PROJECT_AREA_SIDE_LENGTH_IN_METERS
      );

      setProjectArea(calculatedProjectArea);

      const projectAreaBoundingBox = bbox(calculatedProjectArea);

      // zoom to the project area after selecting a place from the auto complete search
      if (projectAreaBoundingBox.length === 4) {
        // idle event is the best we have to determine if the animation by fitBounds() has finished
        mapBox.once('idle', () => {
          setIsMapAnimating(false);
        });

        setIsMapAnimating(true);

        mapBox.fitBounds(projectAreaBoundingBox, {
          padding: { top: 80, left: 0, right: 0, bottom: 180 + bannerHeight },
        });
      }
    },
    [bannerHeight, mapBox]
  );

  const handleOnStartSearching = useCallback(() => {
    setSearchInProgress(true);
  }, []);

  const handleOnCancelSearching = useCallback(() => {
    setSearchInProgress(false);
  }, []);

  const handleProjectAreaMove = useCallback((newProjectArea: ProjectAreaGeometry) => {
    setProjectArea(newProjectArea);
  }, []);

  const handleSave = useCallback(async () => {
    const initialUIState = {
      uiState: { lastAppMode: 'OUTSIDE_NOISE' },
    } as Pick<ProjectSettings, 'uiState'>;

    const projectInput: CreateProjectInput = {
      name: placeName,
      type: ProjectType.ARCHITECT,
      boundingBox: feature<Polygon>(projectArea),
      settings: initialUIState,
    };

    const createdProject = await createProject({
      projectInput,
    }).unwrap();
    navigate(`/architect/project/${createdProject.key}`);
  }, [createProject, navigate, placeName, projectArea]);

  return (
    <>
      <MapBoxOverlayContainer sx={{ position: 'absolute', top: 11, left: 100, width: '60%' }}>
        <MapBoxMapPlaceInput
          disabled={isCreatingProject}
          onSelectPlace={handleOnSelectPlace}
          onStartSearching={handleOnStartSearching}
          onCancelSearching={handleOnCancelSearching}
          config={{
            noOptionsText: t('pages.architect.project.map.nothingFound'),
            searchLanguage: formatLanguage(language),
            sx: { borderRadius: 5 },
          }}
        />
      </MapBoxOverlayContainer>

      {!searchInProgress && projectPlaceSelected && (
        <>
          {/* layers */}

          <ProjectAreaLayer area={projectArea} onProjectAreaMove={handleProjectAreaMove} />
          <BuildingsAndWallsLayer buildings={validationResult?.meta?.buildings} isEditable={false} />

          {/* controls */}

          <MapBoxMapMarker position={placeMarkerPosition} />

          {!isMapAnimating && (
            <>
              <Stack
                spacing={2}
                sx={{
                  position: 'absolute',
                  bottom: (theme: Theme) => theme.spacing(2),
                  left: 100,
                  right: 100,
                  alignItems: 'center',
                }}
              >
                <>
                  <ProjectAreaStatusMessage projectArea={projectArea} />
                  <LoadingButton
                    loading={isCreatingProject || projectAreaIsValidating}
                    onClick={handleSave}
                    disabled={!validationResult?.areaIsValid || projectAreaIsValidating}
                  >
                    {t('pages.architect.project.create.createProjectButton')}
                  </LoadingButton>
                </>
              </Stack>
              <CreateProjectToolbar />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CreateProjectMapMode;
