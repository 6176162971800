import { feature } from '@turf/turf';
import { Feature } from 'geojson';
import { useCallback, useEffect, useMemo } from 'react';
import { ProjectAreaGeometry } from '@dbel/shared/types';
import { switchToMapDrawMode } from '../../../store/slices/map';
import { useDispatch } from '../../../store/store';
import MapBoxMapDrawLayer from './MapBoxMapDrawLayer';
import { PROJECT_AREA_DRAW_LAYER_STYLE } from './styles/ProjectAreaDrawLayerStyles';

export const PROJECT_AREA_FEATURE_ID = 'project-area';

export interface ProjectAreaLayerProps {
  area: ProjectAreaGeometry;
  onProjectAreaMove: (newArea: ProjectAreaGeometry) => void;
}

export const ProjectAreaLayer = ({ area, onProjectAreaMove }: ProjectAreaLayerProps) => {
  const dispatch = useDispatch();

  // the id is important here because if the polygon always has the same id,
  // the draw plugin only adds it once and then always updates it
  // see docs https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md
  const projectAreaFeature = useMemo(() => feature(area, {}, { id: PROJECT_AREA_FEATURE_ID }), [area]);

  useEffect(() => {
    dispatch(
      switchToMapDrawMode({
        mode: 'tx_poly',
        selectedFeatureId: PROJECT_AREA_FEATURE_ID,
        options: {
          canTrash: false,
          canScale: true,
          canRotate: true,
          singleRotationPoint: true,
          canSelectFeatures: true,
        },
      })
    );
  }, [dispatch]);

  const handleProjectAreaMove = useCallback(
    (modifiedProjectArea: Feature) => {
      onProjectAreaMove(modifiedProjectArea.geometry as ProjectAreaGeometry);
    },
    [onProjectAreaMove]
  );

  return (
    <>
      <MapBoxMapDrawLayer
        layerStyle={PROJECT_AREA_DRAW_LAYER_STYLE}
        data={projectAreaFeature}
        onUpdateFeatureDebounced={handleProjectAreaMove}
        useSelectOnlyMode
      />
    </>
  );
};
