import { ReceiverWithEvaluationResult } from '../../index';
import { ReceiverCriticalityAccordionItem } from './ReceiverCriticalityAccordionItem';

export interface ReceiverCriticalityListProps {
  receiverWithEvalResult: ReceiverWithEvaluationResult;
}

export const ReceiverCriticalityAccordion = ({ receiverWithEvalResult }: ReceiverCriticalityListProps) => (
  <>
    {receiverWithEvalResult.evaluationResult && (
      <>
        <ReceiverCriticalityAccordionItem
          receiver={receiverWithEvalResult.receiver}
          criticality={receiverWithEvalResult.evaluationResult.criticalities.day}
        />
        <ReceiverCriticalityAccordionItem
          receiver={receiverWithEvalResult.receiver}
          criticality={receiverWithEvalResult.evaluationResult.criticalities.night}
        />
      </>
    )}
  </>
);
