import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

export interface MainMenuEntryProps {
  title: string;
  expanded: boolean;
  icon?: ReactNode;
  navTo?: string;
}

export const MainMenuEntry = ({ expanded, title, navTo, icon, children }: PropsWithChildren<MainMenuEntryProps>) => {
  const [internalExpanded, setInternalExpanded] = useState<boolean>(false);

  const handleOnChange = useCallback(() => {
    setInternalExpanded(!internalExpanded);
  }, [internalExpanded]);

  useEffect(() => {
    setInternalExpanded(expanded);
  }, [expanded]);

  return (
    <>
      <ListItemButton
        onClick={!navTo ? handleOnChange : undefined}
        component={navTo ? RouterLink : ListItemButton}
        to={navTo}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}

        <ListItemText>
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
        </ListItemText>

        {internalExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      {children && (
        <Collapse in={internalExpanded} timeout="auto">
          <List component="div" disablePadding sx={{ ml: 2 }}>
            {children}
          </List>
        </Collapse>
      )}
    </>
  );
};
