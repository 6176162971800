import { LoadingButton } from '@mui/lab';
import { Accordion, FormControlLabel, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import i18n from 'i18next';
import { AccordionHeader, AccordionContent, formatPowerLevel } from '@dbel/react-commons/components';
import { cloneDeep } from 'lodash';
import { SyntheticEvent, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Receiver } from '@dbel/shared/types';
import * as Yup from 'yup';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { useReceiverHooks } from '@dbel/react-commons/hooks';
import { useProjectIdFromUrl } from '../../../../../hooks/useProjectIdFromUrl';
import { ReceiverWithEvaluationResult } from '../../index';
import { ReceiverCriticalityAccordion } from './ReceiverCriticalityAccordion';
import { CriticalityRatingDot } from '../../../../criticality/CriticalityRatingDot';

const formatReceiverAccordionItemHeaderText = (receiverWithEvalResult: ReceiverWithEvaluationResult) => {
  if (receiverWithEvalResult.receiver.properties.ignoreInEvaluation) {
    return i18n.t('pages.architect.project.drawer.deactivatedReceiverTitle');
  }

  return formatPowerLevel(receiverWithEvalResult.evaluationResult?.highestCriticality.evaluatedDbLevel, true);
};

export interface ReceiverAccordionItemProps {
  receiverWithEvalResult: ReceiverWithEvaluationResult;
  expanded: boolean;
  disabled: boolean;
  onExpandedStateChange: (state: boolean, receiver: Receiver) => void;
}

const MAX_HEIGHT = 1000;

interface FormFields {
  height: number | null;
}

function getFormDefaultValues(receiverWithEvalResult: ReceiverWithEvaluationResult): FormFields {
  return {
    height: receiverWithEvalResult.receiver.geometry.coordinates[2] ?? null,
  };
}

const formValidationSchema = Yup.object().shape({
  height: Yup.number()
    .label(i18n.t('common.labels.height'))
    .transform((value) => (Number.isNaN(value) ? null : value))
    .moreThan(0, i18n.t('errors.formMoreThanValue', { property: i18n.t('common.labels.height'), value: 0 }))
    .max(MAX_HEIGHT, i18n.t('errors.formMaxValue', { property: i18n.t('common.labels.height'), value: MAX_HEIGHT }))
    .nullable(),
});

export const ReceiverAccordionItem = ({
  expanded,
  disabled,
  receiverWithEvalResult,
  onExpandedStateChange,
}: ReceiverAccordionItemProps) => {
  const { t } = useTranslation();

  const projectId = useProjectIdFromUrl();
  const { handleOnUpdateReceiverAndRecalculate, recevierIsLoading, handleOnUpdateReceiver } =
    useReceiverHooks(projectId);

  const heightFieldRef = useRef<HTMLInputElement>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    defaultValues: getFormDefaultValues(receiverWithEvalResult),
  });

  useEffect(() => {
    reset(getFormDefaultValues(receiverWithEvalResult));
  }, [reset, receiverWithEvalResult]);

  const onFormSubmit = useCallback<SubmitHandler<FormFields>>(
    ({ height }) => {
      if (!isDirty) return;
      const newItem = structuredClone(receiverWithEvalResult.receiver);
      newItem.geometry.coordinates[2] = height;
      handleOnUpdateReceiverAndRecalculate(newItem);
    },
    [handleOnUpdateReceiverAndRecalculate, isDirty, receiverWithEvalResult.receiver]
  );

  const handleOnBlurTextField = useCallback(() => {
    handleSubmit(onFormSubmit)();
  }, [handleSubmit, onFormSubmit]);

  const handleOnReceiverVisibleChange = useCallback(
    (receiver: Receiver) => () => {
      const updatedReceiver = cloneDeep(receiver);
      updatedReceiver.properties.ignoreInEvaluation = !receiver.properties.ignoreInEvaluation;
      handleOnUpdateReceiver(updatedReceiver);
    },
    [handleOnUpdateReceiver]
  );

  const handleAccordionChange = useCallback(
    (_: SyntheticEvent, expandedState: boolean) => {
      onExpandedStateChange(expandedState, receiverWithEvalResult.receiver);
    },
    [onExpandedStateChange, receiverWithEvalResult.receiver]
  );

  return (
    <>
      <Accordion expanded={expanded} disabled={disabled} onChange={handleAccordionChange}>
        <AccordionHeader>
          <Typography>{`${formatReceiverAccordionItemHeaderText(receiverWithEvalResult)}`}</Typography>

          {receiverWithEvalResult.evaluationResult && (
            <CriticalityRatingDot
              criticality={receiverWithEvalResult.evaluationResult.highestCriticality}
              sx={{ ml: 'auto' }}
            />
          )}
        </AccordionHeader>
        <AccordionContent>
          <TextField
            {...register('height', {
              valueAsNumber: true,
            })}
            inputRef={heightFieldRef}
            type="number"
            variant="standard"
            label={t('pages.architect.project.drawer.heightLabel')}
            fullWidth
            placeholder={t('pages.architect.project.drawer.heightPlaceholder', { val: 2 })}
            onBlur={handleOnBlurTextField}
            error={errors.height !== undefined}
            helperText={errors.height ? errors.height?.message : ' '}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={recevierIsLoading}
            InputProps={{
              endAdornment: <InputAdornment position="end">{t('common.units.meter')}</InputAdornment>,
            }}
            inputProps={{ min: 0, max: MAX_HEIGHT }}
          />
          <FormControlLabel
            sx={{ ml: 0, display: 'flex', fontSize: 14, pointerEvents: 'none', width: '100%' }}
            control={
              <>
                <Switch
                  onChange={handleOnReceiverVisibleChange(receiverWithEvalResult.receiver)}
                  checked={receiverWithEvalResult.receiver.properties.ignoreInEvaluation ?? false}
                  disabled={recevierIsLoading}
                  sx={{ pointerEvents: 'auto' }}
                />
                <LoadingButton loading={recevierIsLoading} variant="text" sx={{ ml: 'auto' }} />
              </>
            }
            label={`${t('pages.architect.project.drawer.deactivateReceiver')}`}
            labelPlacement="start"
            disableTypography
          />
          {receiverWithEvalResult.receiver.properties.ignoreInEvaluation !== true && (
            <ReceiverCriticalityAccordion receiverWithEvalResult={receiverWithEvalResult} />
          )}
        </AccordionContent>
      </Accordion>
    </>
  );
};
