import { Expression } from 'mapbox-gl';
import {
  BUILDING_IGNORED_COLOR,
  BUILDING_PROJECT_COLOR,
  BUILDING_SURROUNDING_COLOR,
  SELECTED_BORDER_COLOR,
} from '../colors/colors';
import { COMMON_FILL_OPACITY, IS_SELECTED } from './commonExpressions';
import {
  ALL_RECEIVERS_ARE_IGNORED_FEATURE_STATE_VALUE,
  FILL_COLOR_FEATURE_STATE_VALUE,
  HAS_FILL_COLOR_FEATURE_STATE,
} from './featureStateExpressions';
import { BUILDING_HAS_HEIGHT, IS_IGNORED_IN_EVALUATION, IS_PROJECT_BUILDING } from './propertyExpressions';

export const BUILDING_BASE_COLOR: Expression = [
  'case',
  IS_PROJECT_BUILDING,
  BUILDING_PROJECT_COLOR,
  BUILDING_SURROUNDING_COLOR,
];

export const BUILDING_IS_IGNORED: Expression = [
  'any',
  IS_IGNORED_IN_EVALUATION,
  ALL_RECEIVERS_ARE_IGNORED_FEATURE_STATE_VALUE,
];

export const BUILDING_FILL_COLOR: Expression = [
  'case',
  BUILDING_IS_IGNORED,
  BUILDING_IGNORED_COLOR,
  HAS_FILL_COLOR_FEATURE_STATE,
  FILL_COLOR_FEATURE_STATE_VALUE,
  BUILDING_BASE_COLOR,
];

export const BUILDING_FILL_OPACITY: Expression = [
  'case',
  ['all', ['!', HAS_FILL_COLOR_FEATURE_STATE], ['!', BUILDING_HAS_HEIGHT], IS_SELECTED],
  0.2,
  ['all', ['!', HAS_FILL_COLOR_FEATURE_STATE], ['!', BUILDING_HAS_HEIGHT]],
  0,
  COMMON_FILL_OPACITY,
];

export const BUILDING_OUTLINE_COLOR: Expression = [
  'case',
  ['all', IS_SELECTED, ['any', IS_IGNORED_IN_EVALUATION, HAS_FILL_COLOR_FEATURE_STATE]],
  SELECTED_BORDER_COLOR,
  BUILDING_FILL_COLOR,
];

export const BUILDING_OUTLINE_OPACITY: Expression = [
  'case',
  ['all', IS_SELECTED, ['any', IS_IGNORED_IN_EVALUATION, HAS_FILL_COLOR_FEATURE_STATE]],
  1,
  0.5,
];
