import { Dialog, DialogContent, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommerceCharacteristics,
  OpeningHours,
  ProjectCharacteristic,
  ProjectCharacteristicTypes,
  typeOfCharacteristics,
} from '@dbel/shared/types';
import { CustomDialogTitle } from '@dbel/react-commons/components';
import {
  closingStep,
  defaultCommerceProperties,
  getDefaultPropertiesForItem,
  introStep,
  ParameterWizardStep,
  projectCharacteristicTypesValues,
  projectTypeStep,
} from './slides';
import BooleanSlide from './slides/BooleanSlide';
import CheckBoxSlide from './slides/CheckBoxSlide';
import DialogActionsPanel from './slides/DialogActionsPanel';
import HoursSlide from './slides/HoursSlide';
import NumberSlide from './slides/NumberSlide';
import RadioBoxSlide from './slides/RadioBoxSlide';

interface ParameterWizardDialogProps {
  open: boolean;
  onCancel: () => void;
  handleSave: (properties: ProjectCharacteristic) => void;
}

const slideTranslationsPrefix = 'pages.architect.project.create.parameterSlides';

const ParameterWizardDialog = ({ open, onCancel, handleSave }: ParameterWizardDialogProps) => {
  const { t } = useTranslation();
  const [store, setStore] = useState<ProjectCharacteristic>(defaultCommerceProperties);

  const [activeStep, setActiveStep] = useState(0);
  const [selectedProjectCharacteristic, setSelectedProjectCharacteristic] =
    useState<ProjectCharacteristicTypes>('COMMERCE');

  // TODO this should be handled differently because it not generic for now
  const [sundayChecked, setSundayChecked] = useState(false);

  const handleOnCancel = useCallback(
    (_: Object, reason?: string) => {
      if (reason && reason === 'backdropClick') return;
      onCancel();
    },
    [onCancel]
  );

  const steps = useMemo(
    () => [introStep, projectTypeStep, ...projectCharacteristicTypesValues[selectedProjectCharacteristic], closingStep],
    [selectedProjectCharacteristic]
  );

  const isLastStep = activeStep === steps.length - 1;

  const gotoNextStep = useCallback(() => {
    if (isLastStep) {
      if (!sundayChecked) {
        if (store.type === 'COMMERCE') {
          delete (store as CommerceCharacteristics).openingHours.sundays;
        }
      }
      handleSave(store);
      return;
    }
    setActiveStep(activeStep + 1);
  }, [activeStep, handleSave, isLastStep, store, sundayChecked]);

  const gotoPrevStep = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  const onValueChange = useCallback(
    (
      item: ProjectCharacteristicTypes | number | string | string[] | OpeningHours | boolean,
      step: ParameterWizardStep
    ) => {
      if (step.id === 'projectCharacteristicType') {
        setSelectedProjectCharacteristic(item as ProjectCharacteristicTypes);
        setStore(getDefaultPropertiesForItem(item as ProjectCharacteristicTypes));
      } else {
        setStore({ ...store, ...{ [step.id]: item } } as ProjectCharacteristic);
      }
    },
    [store]
  );

  const mappedStore = useMemo(() => typeOfCharacteristics(store), [store]);

  const getStepContent = useCallback(
    (stepIndex: number) => {
      const currentStep = steps[stepIndex];
      switch (currentStep.type) {
        case 'TEXT':
          return (
            <DialogActionsPanel
              stepBackwards={gotoPrevStep}
              stepForwards={gotoNextStep}
              isFirstStep={activeStep === 0}
              isLastStep={isLastStep}
            />
          );
        case 'PROJECTSWITCH':
          return (
            <RadioBoxSlide
              step={currentStep}
              checkedItem={selectedProjectCharacteristic}
              onValueChange={onValueChange}
              stepBackwards={gotoPrevStep}
              stepForwards={gotoNextStep}
            />
          );
        case 'NUMBER':
          return (
            <NumberSlide
              step={currentStep}
              store={mappedStore}
              onValueChange={onValueChange}
              stepBackwards={gotoPrevStep}
              stepForwards={gotoNextStep}
            />
          );
        case 'RADIOBOX':
          return (
            <RadioBoxSlide
              step={currentStep}
              store={mappedStore}
              onValueChange={onValueChange}
              stepBackwards={gotoPrevStep}
              stepForwards={gotoNextStep}
            />
          );
        case 'CHECKBOX':
          return (
            <CheckBoxSlide
              step={currentStep}
              store={mappedStore}
              onValueChange={onValueChange}
              stepBackwards={gotoPrevStep}
              stepForwards={gotoNextStep}
            />
          );
        case 'BOOLEAN':
          return (
            <BooleanSlide
              step={currentStep}
              store={mappedStore}
              onValueChange={onValueChange}
              stepBackwards={gotoPrevStep}
              stepForwards={gotoNextStep}
            />
          );
        case 'HOURS':
          return (
            <HoursSlide
              step={currentStep}
              store={mappedStore}
              onValueChange={onValueChange}
              stepBackwards={gotoPrevStep}
              stepForwards={gotoNextStep}
              sundayChecked={sundayChecked}
              switchSunday={setSundayChecked}
            />
          );
        default:
          return <div>Not implemented yet</div>;
      }
    },
    [
      steps,
      gotoPrevStep,
      gotoNextStep,
      activeStep,
      isLastStep,
      selectedProjectCharacteristic,
      onValueChange,
      mappedStore,
      sundayChecked,
    ]
  );

  return (
    <>
      <Dialog open={open} disableEscapeKeyDown>
        <CustomDialogTitle onClickClose={handleOnCancel}>
          {t('pages.architect.project.create.parameterWizardTitle')}
        </CustomDialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          <Typography variant="h6">{t(`${slideTranslationsPrefix}.${steps[activeStep].id}.title`)}</Typography>
          <Typography sx={{ whiteSpace: 'pre-line', my: 1 }}>
            {t(`${slideTranslationsPrefix}.${steps[activeStep].id}.body`)}
          </Typography>

          {getStepContent(activeStep)}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ParameterWizardDialog;
