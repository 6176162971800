import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Add, EditRoad } from '@mui/icons-material';
import { GeoJsonGeometryTypes } from 'geojson';
import i18n from 'i18next';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { MapBoxMapToolbarItem, MapBoxMapToolbarGroup } from '@dbel/react-commons/components';
import { SoundSourceToolId } from '@dbel/react-commons/types';
import { availableEmissionModels } from '@dbel/shared/util';
import { useToolbars } from '../../../contexts/ToolbarContext';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { ReactComponent as AirConditionIcon } from '../../../icons/sound-sources/AirCondition.svg';
import { ReactComponent as AirInletIcon } from '../../../icons/sound-sources/AirInlet.svg';
import { ReactComponent as AirOutletIcon } from '../../../icons/sound-sources/AirOutlet.svg';
import { ReactComponent as ChimneyIcon } from '../../../icons/sound-sources/Chimney.svg';
import { ReactComponent as HeatPumpIcon } from '../../../icons/sound-sources/HeatPump.svg';
import { ReactComponent as HouseTechnicIcon } from '../../../icons/sound-sources/HouseTechnic.svg';
import { ReactComponent as LoadingDockIcon } from '../../../icons/sound-sources/LoadingDock.svg';
import { ReactComponent as OutsidePersonsIcon } from '../../../icons/sound-sources/OutsidePersons.svg';
import { ReactComponent as ParkingEntranceIcon } from '../../../icons/sound-sources/ParkingEntrance.svg';
import { ReactComponent as ParkingSpaceIcon } from '../../../icons/sound-sources/ParkingSpace.svg';
import { ReactComponent as ServiceRoadIcon } from '../../../icons/sound-sources/ServiceRoad.svg';
import { ReactComponent as ShoppingTrolliesIcon } from '../../../icons/sound-sources/ShoppingTrollies.svg';
import { ReactComponent as SonicationIcon } from '../../../icons/sound-sources/Sonication.svg';
import { ReactComponent as VentilationIcon } from '../../../icons/sound-sources/Ventilation.svg';
import { switchToMapDrawMode, switchToToolbarTool } from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import { MapBoxDrawModeIds } from '../types';

const GEOMETRY_TO_DRAW_MODE_MAP: Record<
  GeoJsonGeometryTypes,
  Extract<MapboxDraw.DrawMode, 'draw_point' | 'draw_line_string' | 'draw_polygon'>
> = {
  Point: 'draw_point',
  MultiPoint: 'draw_point',
  LineString: 'draw_line_string',
  MultiLineString: 'draw_line_string',
  Polygon: 'draw_polygon',
  MultiPolygon: 'draw_polygon',
  GeometryCollection: 'draw_point',
};

const FALLBACK_TOOLBAR_ITEM_ICON = <Add />;
const FALLBACK_TOOLBAR_ITEM_TEXT = 'pages.architect.project.toolbar.generic';
const SOUND_SOURCES_TRANSLATE_KEY_PREFIX = 'common.soundSourceLabels';

const TOOLBAR_ITEM_PROPS: Partial<Record<SoundSourceToolId, ReactNode>> = {
  AIR_CONDITION: <AirConditionIcon />,
  HEAT_PUMP: <HeatPumpIcon />,
  VENTILATION: <VentilationIcon />,
  AIR_INLET: <AirInletIcon />,
  PARKING_AREA: <ParkingSpaceIcon />,
  PARKING_ROAD_ABOVEGROUND: <EditRoad />,
  PARKING_ROAD_UNDERGROUND: <EditRoad />,
  PARKING_AREA_TRUCKS: <ParkingSpaceIcon />,
  SHOPPING_CART_COLLECTOR: <ShoppingTrolliesIcon />,
  SERVICE_ROAD: <ServiceRoadIcon />,
  LOADING_AREA: <LoadingDockIcon />,
  CUSTOM_POINT_SOUND_SOURCE: <Add />,
  EXHAUST_VENT: <AirOutletIcon />,
  CHIMNEY_OUTLET: <ChimneyIcon />,
  PARKING_ENTRANCE: <ParkingEntranceIcon />,
  OUTDOOR_PERSONS: <OutsidePersonsIcon />,
  SMOKING_AREA: <OutsidePersonsIcon />,
  HOUSE_TECHNIC: <HouseTechnicIcon />,
  SONICATION: <SonicationIcon />,
};

export const SoundSourcesToolbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projectToolbarRef } = useToolbars();

  const projectId = useProjectIdFromUrl();
  const { data: project } = useGetProjectByIdQuery(projectId);

  const activeToolbarTool = useSelector((state: RootState) => state.map.activeSoundSourceDrawTool);

  const handleOnToolChange = useCallback(
    (newToolValue: SoundSourceToolId) => {
      dispatch(switchToToolbarTool(newToolValue));
    },
    [dispatch]
  );

  const toolbarItemsForAvailableSoundSources = useMemo<ReactNode[]>(() => {
    if (!project) return [];

    return availableEmissionModels(project).map((soundSource, index) => {
      const { emissionModel } = soundSource;
      const { facet } = emissionModel;

      const icon: ReactNode = TOOLBAR_ITEM_PROPS[facet] ?? FALLBACK_TOOLBAR_ITEM_ICON;

      const displayName = i18n.exists(`${SOUND_SOURCES_TRANSLATE_KEY_PREFIX}.${facet}`)
        ? `${SOUND_SOURCES_TRANSLATE_KEY_PREFIX}.${facet}`
        : soundSource.name ?? FALLBACK_TOOLBAR_ITEM_TEXT;

      return <MapBoxMapToolbarItem<SoundSourceToolId> key={index} value={facet} icon={icon} text={t(displayName)} />;
    });
  }, [project, t]);

  useEffect(() => {
    // const availableSoundSourcesMap = createAvailableSoundSourcesToPropertiesMap(project.availableSoundSources);

    let newDrawMode: MapBoxDrawModeIds = 'simple_select';
    // if (activeToolbarTool in availableSoundSourcesMap) {
    // TODO: important: facet check isn't enough here, since they can repeatedly be added to the project
    if (activeToolbarTool) {
      const activeSoundSource = availableEmissionModels(project).find(
        (soundSourceProps) => soundSourceProps.emissionModel.facet === activeToolbarTool
      );
      newDrawMode = GEOMETRY_TO_DRAW_MODE_MAP[activeSoundSource.geometryType];
    }
    // }

    dispatch(
      switchToMapDrawMode({
        mode: newDrawMode,
      })
    );
  }, [activeToolbarTool, dispatch, project]);

  if (toolbarItemsForAvailableSoundSources.length > 0) {
    return createPortal(
      <>
        <MapBoxMapToolbarGroup<SoundSourceToolId> activeTool={activeToolbarTool} onChangeTool={handleOnToolChange}>
          {toolbarItemsForAvailableSoundSources.map((node) => node)}
        </MapBoxMapToolbarGroup>
      </>,
      projectToolbarRef.current
    );
  }

  return <></>;
};
