import { Expression } from 'mapbox-gl';
import {
  SELECTED_BORDER_COLOR,
  SOUND_SOURCE_COLOR,
  SOUND_SOURCE_HIGHLIGHTED_COLOR,
  SOUND_SOURCE_SELECTED_COLOR,
} from '../colors/colors';
import { IS_SELECTED } from './commonExpressions';
import { HIGHLIGHTED_FEATURE_STATE_ACTIVE } from './featureStateExpressions';

export const SOUND_SOURCE_FILL_COLOR: Expression = [
  'case',
  HIGHLIGHTED_FEATURE_STATE_ACTIVE,
  SOUND_SOURCE_HIGHLIGHTED_COLOR,
  IS_SELECTED,
  SOUND_SOURCE_SELECTED_COLOR,
  SOUND_SOURCE_COLOR,
];

export const SOUND_SOURCE_FILL_BORDER_COLOR: Expression = [
  'case',
  HIGHLIGHTED_FEATURE_STATE_ACTIVE,
  SOUND_SOURCE_HIGHLIGHTED_COLOR,
  IS_SELECTED,
  SELECTED_BORDER_COLOR,
  SOUND_SOURCE_COLOR,
];

export const SOUND_SOURCE_SYMBOL_OPACITY: Expression = ['case', IS_SELECTED, 0, 1];
export const SOUND_SOURCE_SELECTED_SYMBOL_OPACITY: Expression = ['case', IS_SELECTED, 1, 0];
export const SOUND_SOURCE_HIGHLIGHTED_SYMBOL_OPACITY: Expression = ['case', HIGHLIGHTED_FEATURE_STATE_ACTIVE, 1, 0];
