import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { omit } from 'lodash';

export const disableShiftClickInMapBoxDrawSimpleSelectMode = () => {
  // Patches the MaxBoxDraw simple_select mode onClick event handler to
  // disable selecting multiple elements via shift+click. Unfortunately, there's
  // no setting in MapBoxDraw to do this.
  // The patch just removes the shiftKey property from the MouseEvent so that
  // it seems the user has never pressed the shift key.
  const mapBoxDrawSimpleSelectMode = (MapboxDraw.modes as any).simple_select;
  const originalSimpleSelectOnClickHandler = mapBoxDrawSimpleSelectMode.onClick;

  function patchedMapBoxDrawOnClickHandler(state: any, evt: any) {
    evt.originalEvent = omit(evt.originalEvent, 'shiftKey');
    // @ts-ignore
    originalSimpleSelectOnClickHandler.apply(this, [state, evt]);
  }

  mapBoxDrawSimpleSelectMode.onClick = patchedMapBoxDrawOnClickHandler;
};
