export enum UsageScenarioType {
  MUSIC_ACOUSTIC = 'MUSIC_ACOUSTIC',
  MUSIC_AMPLIFIED = 'MUSIC_AMPLIFIED',
  MUSIC_CLASS = 'MUSIC_CLASS',
  SPEECH = 'SPEECH',
  CLASS = 'CLASS',
  CLASS_INCLUSIVE = 'CLASS_INCLUSIVE',
  SPORT_AND_SWIMMING = 'SPORT_AND_SWIMMING',
  TRANSIT = 'TRANSIT',
  AREA_SHORT_TERM = 'AREA_SHORT_TERM',
  AREA_LONG_TERM = 'AREA_LONG_TERM',
  AREA_QUIET = 'AREA_QUIET',
  AREA_LOUD = 'AREA_LOUD',
}

export enum UsageScenarioUseCase {
  COMMUNICATION = 'COMMUNICATION',
  NOISE_REDUCTION = 'NOISE_REDUCTION',
}

export enum UsageScenarioWeight {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3',
  B4 = 'B4',
  B5 = 'B5',
}

export type UsageScenarioUseCases = UsageScenarioUseCase.COMMUNICATION | UsageScenarioUseCase.NOISE_REDUCTION;

export const CommunicationUsageScenarios: UsageScenarioType[] = [
  UsageScenarioType.MUSIC_ACOUSTIC,
  UsageScenarioType.MUSIC_AMPLIFIED,
  UsageScenarioType.MUSIC_CLASS,
  UsageScenarioType.SPEECH,
  UsageScenarioType.CLASS,
  UsageScenarioType.CLASS_INCLUSIVE,
  UsageScenarioType.SPORT_AND_SWIMMING,
];

export const NoiseReductionUsageScenarios: UsageScenarioType[] = [
  UsageScenarioType.TRANSIT,
  UsageScenarioType.AREA_SHORT_TERM,
  UsageScenarioType.AREA_LONG_TERM,
  UsageScenarioType.AREA_QUIET,
  UsageScenarioType.AREA_LOUD,
];

export const usageScenarioWeights: Record<UsageScenarioType, UsageScenarioWeight> = {
  MUSIC_ACOUSTIC: UsageScenarioWeight.A1,
  MUSIC_AMPLIFIED: UsageScenarioWeight.A3,
  MUSIC_CLASS: UsageScenarioWeight.A2,
  SPEECH: UsageScenarioWeight.A2,
  CLASS: UsageScenarioWeight.A3,
  CLASS_INCLUSIVE: UsageScenarioWeight.A4,
  SPORT_AND_SWIMMING: UsageScenarioWeight.A5,
  TRANSIT: UsageScenarioWeight.B1,
  AREA_SHORT_TERM: UsageScenarioWeight.B2,
  AREA_LONG_TERM: UsageScenarioWeight.B3,
  AREA_QUIET: UsageScenarioWeight.B4,
  AREA_LOUD: UsageScenarioWeight.B5,
};
