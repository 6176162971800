import { useCallback, useEffect, useRef, useState } from 'react';
import mqtt, { IClientOptions, MqttClient } from 'mqtt';

const mqttOptions: IClientOptions = {
  keepalive: 60,
  reschedulePings: true,
  protocol: 'wss',
  protocolVersion: 3,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
  clean: true,
  port: 443,
  password: '',
};

export type MqttConnectionData = { endPointUrl: string };

export type MqttConnectionStatus = 'connecting' | 'connected' | 'offline' | 'reconnecting' | 'error';

export const useMqttHook = ({ endPointUrl }: MqttConnectionData) => {
  const [message, setMessage] = useState<string>('');

  const [status, setStatus] = useState<MqttConnectionStatus>('offline');

  const clientValid = useRef(false);

  const [client, setClient] = useState<MqttClient | null>(null);

  const subscribeToTopic = useCallback(
    (topic: string) => {
      if (!client) return;
      client.subscribe(topic);
    },
    [client]
  );

  const unsubscribeFromTopic = useCallback(
    (topic: string) => {
      if (!client) return;
      client.unsubscribe(topic);
    },
    [client]
  );

  const connect = useCallback(
    (username: string, clientId: string) => {
      if (!username) return;
      if (!client && !clientValid.current) {
        clientValid.current = true;
        setStatus('connecting');

        const mqttClient = mqtt.connect(endPointUrl, { ...mqttOptions, clientId, username });

        mqttClient.on('connect', () => {
          setClient(mqttClient);
          setStatus('connected');
        });

        mqttClient.on('message', (_: string, bufferedMessage: Buffer) => {
          setMessage(bufferedMessage.toString());
        });

        mqttClient.on('reconnect', () => {
          console.debug('on reconnect');
          setStatus('reconnecting');
        });
        mqttClient.on('error', () => {
          setStatus('error');
        });
        mqttClient.on('offline', () => {
          console.debug('on offline');
          setStatus('offline');
        });
        mqttClient.on('end', () => {
          console.debug('on end');
          setStatus('offline');
        });
      }
    },
    [client, endPointUrl]
  );

  useEffect(
    () => () => {
      if (client) {
        client.end(true);
        setClient(null);
        clientValid.current = false;
      }
    },
    [client, clientValid]
  );

  return { status, message, connect, subscribeToTopic, unsubscribeFromTopic };
};
