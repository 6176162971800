import AddLocationIcon from '@mui/icons-material/AddLocation';
import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { centerOfMass } from '@turf/turf';
import { useGetSoundSourcesQuery } from '@dbel/react-commons/api';
import { CustomListItemButton, formatPowerLevel, useDebug, useMapBox } from '@dbel/react-commons/components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Receiver, SoundSource, TaLaermPeriod } from '@dbel/shared/types';
import { useProjectIdFromUrl } from '../../../../../hooks/useProjectIdFromUrl';
import { useReceiverTools } from '../../../../../hooks/useReceiverTools';
import { DebugSoundSourceForReceiverButton } from './DebugSoundSourceForReceiverButton';
import { SOUND_SOURCE_HIGHLIGHTED_COLOR } from '../../../layers/styles/colors/colors';

interface InfluencerUiInfo {
  soundSourceKey: string;
  soundSourceTitle: string;
  rating: number;
}

export interface ReceiverInfluencerListProps {
  period: TaLaermPeriod;
  receiver: Receiver;
}

export const ReceiverInfluencersList = ({ receiver, period }: ReceiverInfluencerListProps) => {
  const { t } = useTranslation();
  const debug = useDebug();
  const { mapBox } = useMapBox();

  const receiverTools = useReceiverTools();

  const influencers = useMemo(
    () => receiverTools.getInfluencersForReceiver(receiver, period),
    [period, receiver, receiverTools]
  );

  const projectId = useProjectIdFromUrl();
  const { data: soundSourcesData } = useGetSoundSourcesQuery({ projectId });

  const soundSources = useMemo(() => {
    if (!soundSourcesData) return [];
    return soundSourcesData.filter((soundSource) => soundSource.properties.isProjectSoundSource === true);
  }, [soundSourcesData]);

  const soundSourceIdIndexAccess = useMemo<Record<string, SoundSource>>(() => {
    const sources: SoundSource[] = soundSources ?? [];

    return sources.reduce<Record<string, SoundSource>>((obj, item) => {
      obj[String(item.id)] = item;
      return obj;
    }, {});
  }, [soundSources]);

  const sortedInfluencers = useMemo<InfluencerUiInfo[]>(() => {
    if (!influencers) return [];

    return influencers
      .flatMap<InfluencerUiInfo>((influencer) => {
        const soundSourceForInfluencer = soundSourceIdIndexAccess[influencer.soundSourceKey];

        // TODO: This should not happen. There are no SoundSources without a facet
        // when the bug is fixed we can remove the next line
        if (!soundSourceForInfluencer || !soundSourceForInfluencer.properties?.emissionModel?.facet) {
          return [];
        }

        return [
          {
            soundSourceKey: influencer.soundSourceKey,
            soundSourceTitle: t(`common.soundSourceLabels.${soundSourceForInfluencer.properties.emissionModel.facet}`),
            rating: influencer.ratingLevel,
          },
        ];
      })
      .sort((a, b) => b.rating - a.rating);
  }, [influencers, soundSourceIdIndexAccess, t]);

  const handleClickInfluencer = useCallback(
    (soundSourceKey: string) => () => {
      const clickedSoundSource = soundSourceIdIndexAccess[soundSourceKey];

      if (clickedSoundSource) {
        const [lng, lat] = centerOfMass(clickedSoundSource).geometry.coordinates;

        mapBox.flyTo({
          center: [lng, lat],
        });
      }
    },
    [soundSourceIdIndexAccess, mapBox]
  );

  return (
    <>
      <List dense>
        {sortedInfluencers.map((influencer, index) => (
          <ListItem
            key={index}
            alignItems="flex-start"
            secondaryAction={
              debug.enabled ? (
                <DebugSoundSourceForReceiverButton
                  receiverId={String(receiver.id)}
                  soundSourceId={influencer.soundSourceKey}
                />
              ) : undefined
            }
          >
            <CustomListItemButton onClick={handleClickInfluencer(influencer.soundSourceKey)} sx={{ width: '100%' }}>
              <ListItemIcon>
                <AddLocationIcon htmlColor={SOUND_SOURCE_HIGHLIGHTED_COLOR} />
              </ListItemIcon>
              <ListItemText disableTypography sx={{ display: 'flex' }}>
                <Typography variant="body2" noWrap>
                  {influencer.soundSourceTitle}
                </Typography>
                <Typography variant="body2" sx={{ ml: 'auto', whiteSpace: 'nowrap' }}>
                  {formatPowerLevel(influencer.rating, true)}
                </Typography>
              </ListItemText>
            </CustomListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};
