import { DrawLayerStyle, MergableDrawLayerStyle } from '../MapBoxMapDrawLayer';
import { BUILDINGS_LAYER_STYLE, BUILDINGS_LAYER_STYLE_OUTLINE } from './BuildingsLayerStyles';
import { BUILDING_PROJECT_COLOR, BUILDING_SURROUNDING_COLOR } from './colors/colors';
import { BUILDING_BASE_COLOR } from './expressions/buildingExpressions';
import { mergeDrawLayerStyles } from './utils';

const BUILDINGS_DRAW_LAYER_STYLE: DrawLayerStyle = [
  { id: 'gl-draw-polygon-fill', ...BUILDINGS_LAYER_STYLE },
  { id: 'gl-draw-polygon-stroke', ...BUILDINGS_LAYER_STYLE_OUTLINE },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'false']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': BUILDING_BASE_COLOR,
      'line-width': 2,
      'line-opacity': 0.2,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': BUILDING_BASE_COLOR,
      'line-width': 2,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 8,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 5,
      'circle-color': BUILDING_BASE_COLOR,
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'false'], ['==', 'meta', 'feature']],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'false'], ['==', 'meta', 'feature']],
    paint: {
      'circle-radius': 5,
      'circle-color': BUILDING_BASE_COLOR,
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'active', 'true'], ['!=', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 12,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['!=', 'meta', 'midpoint'], ['==', 'active', 'true']],
    paint: {
      'circle-radius': 9,
      'circle-color': BUILDING_BASE_COLOR,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': BUILDING_BASE_COLOR,
    },
  },
];

const createSpecificBuildingsStyleWithColor = (color: string): MergableDrawLayerStyle => [
  {
    id: 'gl-draw-polygon-fill',
    paint: {
      'fill-color': color,
    },
  },
  {
    id: 'gl-draw-polygon-stroke',
    paint: {
      'line-color': color,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    paint: {
      'line-color': color,
    },
  },
  {
    id: 'gl-draw-line-active',
    paint: {
      'line-color': color,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    paint: {
      'circle-color': color,
    },
  },
  {
    id: 'gl-draw-point-inactive',
    paint: {
      'circle-color': color,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    paint: {
      'circle-color': color,
    },
  },
];

const SURROUNDING_BUILDINGS_SPECIFIC_DRAW_LAYER_STYLE: MergableDrawLayerStyle =
  createSpecificBuildingsStyleWithColor(BUILDING_SURROUNDING_COLOR);

export const SURROUNDING_BUILDINGS_DRAW_LAYER_STYLE: DrawLayerStyle = mergeDrawLayerStyles(
  BUILDINGS_DRAW_LAYER_STYLE,
  SURROUNDING_BUILDINGS_SPECIFIC_DRAW_LAYER_STYLE
);

const PROJECT_BUILDINGS_SPECIFIC_DRAW_LAYER_STYLE: MergableDrawLayerStyle =
  createSpecificBuildingsStyleWithColor(BUILDING_PROJECT_COLOR);

export const PROJECT_BUILDINGS_DRAW_LAYER_STYLE: DrawLayerStyle = mergeDrawLayerStyles(
  BUILDINGS_DRAW_LAYER_STYLE,
  PROJECT_BUILDINGS_SPECIFIC_DRAW_LAYER_STYLE
);
