import { Box, Stack, Switch, ToggleButton, Tooltip } from '@mui/material';
import { useGetProjectByIdQuery, useUpdateProjectMutation } from '@dbel/react-commons/api';
import { MapBoxOverlayContainer } from '@dbel/react-commons/components';
import { PropsWithSx } from '@dbel/react-commons/types';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Project, ProjectAppMode, ProjectCharacteristic } from '@dbel/shared/types';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { ReactComponent as AppModeNoiseEmissionIcon } from '../../../icons/app-modes/AppModeNoiseEmission.svg';
import { ReactComponent as AppModeOutsideNoiseIcon } from '../../../icons/app-modes/AppModeOutsideNoise.svg';
import { switchToAppMode } from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import ParameterWizardDialog from '../../dialogs/ParameterWizardDialog';

export const AppModeSwitcher = ({ sx }: PropsWithSx<{}>) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projectId = useProjectIdFromUrl();
  const { data: project } = useGetProjectByIdQuery(projectId);
  const [updateProject] = useUpdateProjectMutation();

  const appMode = useSelector((state: RootState) => state.map.appMode);
  const [parameterWizardDialogOpen, setParameterWizardDialogOpen] = useState<boolean>(false);

  const handleShowDialog = useCallback(() => {
    setParameterWizardDialogOpen(true);
  }, [setParameterWizardDialogOpen]);

  const handleCancelDialog = useCallback(() => {
    setParameterWizardDialogOpen(false);
  }, [setParameterWizardDialogOpen]);

  const handleUpdateProject = useCallback(
    (newProject: Project) => {
      updateProject({ projectId, project: newProject });
    },
    [projectId, updateProject]
  );

  useEffect(() => {
    if (project === undefined) return;
    dispatch(switchToAppMode(project.settings.uiState?.lastAppMode ?? 'OUTSIDE_NOISE'));
  }, [dispatch, project]);

  const handleSave = useCallback(
    async (projectCharacteristics: ProjectCharacteristic) => {
      setParameterWizardDialogOpen(false);
      const newProject = cloneDeep(project);
      newProject.settings.uiState = newProject.settings.uiState ?? {};
      newProject.settings.uiState.lastAppMode = 'NOISE_EMISSIONS';
      newProject.settings.projectCharacteristics = [projectCharacteristics];
      handleUpdateProject(newProject);
    },
    [handleUpdateProject, project]
  );

  const handleOnChangeAppMode = useCallback(
    (newMode: ProjectAppMode) => {
      if (newMode === 'NOISE_EMISSIONS' && project.settings.projectCharacteristics.length === 0) {
        handleShowDialog();
        return;
      }
      // if it is the same mode return so one roundtrip to server is saved.
      if (project.settings.uiState.lastAppMode === newMode) return;

      const newProject = cloneDeep(project);
      newProject.settings.uiState = newProject.settings.uiState ?? {};
      newProject.settings.uiState.lastAppMode = newMode;
      handleUpdateProject(newProject);
    },
    [handleShowDialog, handleUpdateProject, project]
  );

  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        handleOnChangeAppMode('NOISE_EMISSIONS');
      } else {
        handleOnChangeAppMode('OUTSIDE_NOISE');
      }
    },
    [handleOnChangeAppMode]
  );

  return (
    <>
      <MapBoxOverlayContainer
        sx={{
          display: 'flex',
          ...sx,
        }}
      >
        <Tooltip title={t('pages.architect.project.mainMenu.appMode.OUTSIDE_NOISE')}>
          <ToggleButton
            value="OUTSIDE_NOISE"
            sx={{
              border: 0,
            }}
            selected={appMode === 'OUTSIDE_NOISE'}
            onChange={() => {
              handleOnChangeAppMode('OUTSIDE_NOISE');
            }}
          >
            <Stack direction="row">
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <AppModeOutsideNoiseIcon />
              </Box>
            </Stack>
          </ToggleButton>
        </Tooltip>
        <Stack direction="row" alignContent="center">
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Switch
              checked={appMode === 'NOISE_EMISSIONS'}
              onChange={handleSwitchChange}
              size="small"
              sx={{ alignSelf: 'center' }}
            />
          </Box>
        </Stack>
        <Tooltip title={t('pages.architect.project.mainMenu.appMode.NOISE_EMISSIONS')}>
          <ToggleButton
            value="NOISE_EMISSIONS"
            sx={{
              border: 0,
            }}
            selected={appMode === 'NOISE_EMISSIONS'}
            onChange={() => {
              handleOnChangeAppMode('NOISE_EMISSIONS');
            }}
          >
            <Stack direction="row">
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <AppModeNoiseEmissionIcon />
              </Box>
            </Stack>
          </ToggleButton>
        </Tooltip>
      </MapBoxOverlayContainer>
      <ParameterWizardDialog open={parameterWizardDialogOpen} onCancel={handleCancelDialog} handleSave={handleSave} />
    </>
  );
};
