import { RootState, useSelector } from '../../../store/store';
import BuildingsAndWallsLayer from '../layers/BuildingsAndWallsLayer';
import { ProjectPlanLayer } from '../layers/ProjectPlanLayer';
import SoundSourcesLayer from '../layers/SoundSourcesLayer';

export const SoundSourcesMapMode = () => {
  const appMode = useSelector((state: RootState) => state.map.appMode);

  return (
    <>
      {appMode === 'NOISE_EMISSIONS' && (
        <>
          <ProjectPlanLayer />
          <SoundSourcesLayer isEditable />
          <BuildingsAndWallsLayer isEditable={false} surroundingBuildingsClickable={false} />
        </>
      )}
      {appMode === 'OUTSIDE_NOISE' && (
        <>
          <ProjectPlanLayer />
          <SoundSourcesLayer projectSoundSources={false} isEditable />
          <BuildingsAndWallsLayer isEditable={false} surroundingBuildingsClickable={false} />
        </>
      )}
    </>
  );
};
