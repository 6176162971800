import { PointSoundSource } from '@dbel/shared/types';

export type OperatingMode = 'SIMPLE' | 'COMPLEX';

export interface CustomSoundSourcePropertiesDialogProps {
  open: boolean;
  onSave: (updatedGenericPointSoundSource: PointSoundSource) => void;
  onCancel: () => void;
  soundSource: PointSoundSource;
}

export const MIN_HEIGHT = 0;
export const MAX_HEIGHT = 999;
export const MIN_DB = -99;
export const MAX_DB = 200;
