import { LayerStyle } from '@dbel/react-commons/types';

// strange behaviour, only when 'raster-fade-duration' AND 'raster-opacity-transition' is set to 0 it functions as expected
export const PROJECT_PLAN_LAYER_STYLE: LayerStyle = {
  type: 'raster',
  paint: {
    'raster-opacity': 1,
    'raster-fade-duration': 0,
    'raster-opacity-transition': { delay: 0, duration: 0 },
  },
};
