import { Auth0Client, GetTokenSilentlyOptions, LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/react';
import {
  useLazyGetOrganistationsForUserQuery,
  removeDBelApiUserCredentials,
  setDBelApiCredentials,
  setDBelAuthToken,
  setDBelAuthorizationType,
} from '@dbel/react-commons/api';

import { createContext, FC, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';

import { DBelPayload, DBelTokenclaim } from '@dbel/react-commons/types';
import { jwtDecode } from 'jwt-decode';
import { auth0Config } from '../config';
import { resetAccountState, setUser } from '../store/slices/account';
import { useDispatch } from '../store/store';
import type { User } from '../types/user';

let auth0Client: Auth0Client | null = null;

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

const hasAuthParams = (searchParams = window.location.search): boolean =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) && STATE_RE.test(searchParams);

const DBEL_PREFIX = 'DBEL_';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  accountStatus: AccountStatus;
  authStatus: AuthStatus;
  user: User | null;
}

export interface AuthContextValue extends State {
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>;
  getTokenForOrganization: (organizationId: string) => Promise<void>;
  logout: (logoutOptions?: LogoutOptions) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

setDBelAuthorizationType('TOKEN');

export type AuthStatus = 'LOGIN' | 'INIT' | 'LOGOUT';
type AccountStatus = 'ACTIVE' | 'PENDING' | 'NONE';

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    accountStatus: AccountStatus;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  accountStatus: 'NONE',
  authStatus: 'INIT',
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action): State => {
    const { isAuthenticated, accountStatus, user } = (action as InitializeAction).payload;
    return {
      ...state,
      isAuthenticated,
      accountStatus,
      isInitialized: true,
      authStatus: 'INIT',
      user,
    };
  },
  LOGIN: (state: State, action): State => {
    const { user } = (action as LoginAction).payload;
    return {
      ...state,
      isAuthenticated: true,
      authStatus: 'LOGIN',
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    accountStatus: 'NONE',
    authStatus: 'LOGOUT',
    user: null,
  }),
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  logout: () => Promise.resolve(),
  loginWithRedirect: () => Promise.resolve(),
  getTokenForOrganization: () => Promise.resolve(),
  getTokenSilently: () => Promise.resolve(''),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const dispatchToReduxStore = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [initialized, setInitialized] = useState(false);

  const [getOrganisationsForUser] = useLazyGetOrganistationsForUserQuery();

  const loginWithRedirect = async (options: RedirectLoginOptions): Promise<void> => {
    await auth0Client.loginWithRedirect({ ...options });
  };

  const getTokenSilently = async (options: GetTokenSilentlyOptions): Promise<string> => {
    let accesToken;
    try {
      accesToken = await auth0Client.getTokenSilently({ ...options });
    } catch (e) {
      if ((e as Error).message === 'Consent required') {
        accesToken = await auth0Client.getTokenWithPopup({ ...options });
      } else {
        throw new Error('Cannot retreive access Token');
      }
    }
    return accesToken;
  };

  const logout = useCallback(
    // to logout with redirect use the logout options -> logoutParams : { returnTo: url}
    async (logoutOptions?: LogoutOptions): Promise<void> => {
      await auth0Client.logout(logoutOptions);
      dispatch({
        type: 'LOGOUT',
      });
      removeDBelApiUserCredentials();
      dispatchToReduxStore(resetAccountState());
    },
    [dispatchToReduxStore]
  );

  const getTokenForOrganization = useCallback(
    async (organizationId: string) => {
      const authToken = await auth0Client
        .getTokenSilently({
          authorizationParams: {
            organization: organizationId,
          },
          cacheMode: 'off',
          detailedResponse: true,
        })
        .catch(async (e) => {
          // localhost can not be a first-party application. therefore it cannot login the user to the org.
          // https://auth0.com/docs/get-started/applications/confidential-and-public-applications/user-consent-and-third-party-applications#skip-consent-for-first-party-applications
          console.error('error during get token, please try login again:', e);
          logout();
        });

      if (!authToken) return;

      const claims = await auth0Client.getIdTokenClaims();

      // there is a possibility that org and user token are not synchronized
      // we need to check if the organisation is the same otherwise we have to relogin
      // more info https://github.com/auth0/auth0-spa-js/issues/1055
      if (organizationId !== claims['org_id']) {
        await loginWithRedirect({});
        return;
      }
      const decoded = jwtDecode<DBelPayload>(authToken.access_token);
      const user = await auth0Client.getUser();

      const accountKey = decoded[DBelTokenclaim];

      const userData: User = {
        id: user.sub,
        accountKey,
        avatar: user.picture,
        email: user.email,
        name: user.name,
        permissions: decoded.permissions,
        token: authToken.access_token,
      };
      // send to API
      setDBelApiCredentials({ accountKey, authToken: authToken.access_token, authorizationType: 'TOKEN' });
      dispatchToReduxStore(setUser(userData));

      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
          accountStatus: 'ACTIVE',
          user: userData,
        },
      });

      localStorage.setItem(`${DBEL_PREFIX}${userData.id}`, organizationId);
    },
    [dispatchToReduxStore, logout]
  );

  useEffect(() => {
    if (initialized) {
      return;
    }

    const initialize = async (): Promise<void> => {
      try {
        auth0Client = new Auth0Client({
          domain: auth0Config.domain,
          clientId: auth0Config.clientId,
          authorizationParams: {
            scope: auth0Config.scope,
            audience: auth0Config.audience,
            redirect_uri: window.location.origin,
          },
        });

        // for a redirect
        if (hasAuthParams()) {
          if (ERROR_RE.test(window.location.search)) {
            console.error('error');
            return;
          }
          await auth0Client.handleRedirectCallback();
        }

        await auth0Client.checkSession();

        const isAuthenticated = await auth0Client.isAuthenticated();

        //  auth0 silently login to organisation
        if (isAuthenticated) {
          const user = await auth0Client.getUser();
          const userData: User = {
            id: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            token: '',
          };

          Sentry.setUser({ email: user.email, username: user.preferred_username });

          // get Token for user, we need this to get the users organisations
          const token = await auth0Client.getTokenSilently({});
          setDBelAuthToken(token);

          // if we have a organisation on the user object for example with a refresh
          if (user['org_id'] !== undefined) {
            await getTokenForOrganization(user['org_id']);
            return;
          }

          const organisationsStoredInLocalStorage = localStorage.getItem(`${DBEL_PREFIX}${user.sub}`);
          if (organisationsStoredInLocalStorage !== null) {
            await getTokenForOrganization(organisationsStoredInLocalStorage);
            return;
          }

          // otherwise we get user from backend.
          const organisationsForUser = await getOrganisationsForUser(user['sub']).unwrap();
          if (organisationsForUser.length === 0) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated,
                accountStatus: 'NONE',
                user: userData,
              },
            });

            return;
          }

          await getTokenForOrganization(organisationsForUser[0].organizationId);
        } else {
          dispatchToReduxStore(resetAccountState());

          removeDBelApiUserCredentials();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated,
              accountStatus: 'NONE',
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        removeDBelApiUserCredentials();
        dispatchToReduxStore(resetAccountState());
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            accountStatus: 'NONE',
            user: null,
          },
        });
      }
    };

    initialize();
    setInitialized(true);
  }, [dispatchToReduxStore, getOrganisationsForUser, getTokenForOrganization, initialized, setInitialized]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginWithRedirect,
        getTokenForOrganization,
        getTokenSilently,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
