import { Button, DialogActions } from '@mui/material';
import { useCallback } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProjectCharacteristic } from '@dbel/shared/types';

interface DialogActionsPanelProps {
  stepBackwards?: () => void;
  stepForwards?: () => void;
  disabled?: boolean;
  handleSubmit?: UseFormHandleSubmit<unknown>;
  onSubmit?: (data: Partial<ProjectCharacteristic>) => void;
  isLastStep?: boolean;
  isFirstStep?: boolean;
}

const DialogActionsPanel = ({
  stepBackwards,
  stepForwards,
  disabled,
  handleSubmit,
  onSubmit,
  isLastStep,
  isFirstStep,
}: DialogActionsPanelProps) => {
  const { t } = useTranslation();

  const stepBackwardsClicked = useCallback(() => {
    if (handleSubmit) {
      handleSubmit(onSubmit)();
    }
    stepBackwards();
  }, [handleSubmit, onSubmit, stepBackwards]);

  const handleNextClicked = useCallback(() => {
    if (handleSubmit) {
      handleSubmit(onSubmit)();
    }
    stepForwards();
  }, [handleSubmit, onSubmit, stepForwards]);

  return (
    <DialogActions>
      {!isFirstStep && <Button onClick={stepBackwardsClicked}>{t('common.buttonLabels.back')}</Button>}
      <Button disabled={disabled} color="primary" onClick={handleNextClicked}>
        {isLastStep ? t('common.buttonLabels.save') : t('common.buttonLabels.next')}
      </Button>
    </DialogActions>
  );
};

export default DialogActionsPanel;
