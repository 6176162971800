import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';

const Main = styled('main')({});

const Content = styled('div')(({ theme }) =>
  theme.unstable_sx({
    padding: '20px',
    flexGrow: 1,
    overflow: 'auto',
  })
);

export const AllTelemetryPagesLayout = () => (
  <>
    <Helmet>
      <title>Traffic Analyzer</title>
    </Helmet>

    <Main>
      <Content>
        <Outlet />
      </Content>
    </Main>
  </>
);
