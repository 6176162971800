import { useEffect, useState } from 'react';
import { DBELDevice, useGetDevicesQuery, useSignTokenQuery } from '@dbel/react-commons/api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { sumDecibel, l_eq } from '@dbel/shared/util';
import { useMqttHook } from '../../../../../react-commons/src/hooks/useMqttHook';
import useAuth from '../../../hooks/useAuth';

const AWSProtocol = 'wss://';
const AWSHost = 'a3q62ydfc2nzao-ats.iot.eu-central-1.amazonaws.com'; // telemetry.test.dbel.cloud
const AWSPort = '443';
const AWSpath = '/mqtt';
const endpoint = `${AWSProtocol}${AWSHost}:${AWSPort}${AWSpath}`;

const AWSCustomAuthorizerName = 'dbel-telemetry-authorizer';

export type TelemetryMeteringData = {
  t: number; // timestamp
  m: number[][]; // measurings
};

export const useTelemetryHook = (active: boolean, clientId: string, accountKey: string, token: string) => {
  const { connect, message, status, subscribeToTopic, unsubscribeFromTopic } = useMqttHook({
    endPointUrl: endpoint,
  });

  const [values, setValues] = useState<number[]>([]);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [initValue, setInitValue] = useState(0);
  const [telemetryToken, setTelemetryToken] = useState<string>();
  const [requested, setRequested] = useState<boolean>(false);

  const { getTokenSilently } = useAuth();

  const { data: signature } = useSignTokenQuery(telemetryToken ? { token: telemetryToken } : skipToken);
  const { data: devices } = useGetDevicesQuery(active ? undefined : skipToken);
  const [selectedDevice, setSelectedDevice] = useState<DBELDevice>();

  useEffect(() => {
    if (selectedDevice) return;
    if (!devices || devices.length === 0) return;
    setSelectedDevice(devices[0]);
  }, [devices, selectedDevice]);

  useEffect(() => {
    if (status !== 'connected') return () => {};
    if (!selectedDevice || !active) return () => {};
    subscribeToTopic(`dt/dbel/${accountKey}/${selectedDevice.client_id}/audio`);
    return () => {
      unsubscribeFromTopic(`dt/dbel/${accountKey}/${selectedDevice.client_id}/audio`);
    };
  }, [selectedDevice, status, accountKey, subscribeToTopic, unsubscribeFromTopic, active]);

  useEffect(() => {
    if (!active) return;
    if (requested) return;
    setRequested(true);
    const asyncGetToken = async () => {
      const asynctoken = await getTokenSilently({
        cacheMode: 'off',
        authorizationParams: {
          audience: 'https://telemetry.test.dbel.cloud',
        },
      });
      if (asynctoken) {
        setTelemetryToken(asynctoken);
      }
    };
    asyncGetToken();
  }, [requested, getTokenSilently, active]);

  useEffect(() => {
    if (!message) return;

    const bands = JSON.parse(message) as TelemetryMeteringData;

    const secondValues: number[] = bands.m.map((band) => sumDecibel(...band));
    const currentLeqValue = l_eq(secondValues, secondValues.length);
    setCurrentValue(currentLeqValue);
    setValues((oldValues) => {
      if (oldValues.length >= 60) {
        const slicedValues = oldValues.slice(1, 60);
        return [...slicedValues, currentLeqValue];
      }
      return [...oldValues, currentLeqValue];
    });
  }, [message]);

  useEffect(() => {
    if (!active) return;
    if (!accountKey) return;
    if (!signature) return;
    if (!telemetryToken) return;

    if (status !== 'connected') {
      const username = `username?Authorization=${telemetryToken}&x-amz-customauthorizer-name=${AWSCustomAuthorizerName}&x-amz-customauthorizer-signature=${encodeURIComponent(
        signature.signedToken
      )}`;

      connect(username, clientId);
    }
  }, [accountKey, active, clientId, connect, initValue, signature, status, token, telemetryToken]);

  useEffect(() => {
    if (currentValue !== 0 && initValue === 0) setInitValue(currentValue);
  }, [currentValue, initValue]);

  return { values, status, currentValue, initValue, setSelectedDevice, devices, selectedDevice };
};
