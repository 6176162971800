import { RootState, useSelector } from '../../../store/store';
import BuildingsAndWallsLayer from '../layers/BuildingsAndWallsLayer';
import { ProjectPlanLayer } from '../layers/ProjectPlanLayer';
import SoundSourcesLayer from '../layers/SoundSourcesLayer';

export const ProjectBuildingsMapMode = () => {
  const appMode = useSelector((state: RootState) => state.map.appMode);

  return (
    <>
      <ProjectPlanLayer />
      <BuildingsAndWallsLayer drawProjectBuildings />
      <SoundSourcesLayer isEditable={false} projectSoundSources={appMode === 'NOISE_EMISSIONS'} isSelectable={false} />
    </>
  );
};
