import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogActionsPanel from './DialogActionsPanel';
import { ParameterSlideProps } from './ParameterSlideProps';

const slideTranslationsPrefix = 'pages.architect.project.create.parameterSlides';

const BooleanSlide = ({ store, stepBackwards, stepForwards, onValueChange, step }: ParameterSlideProps<boolean>) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<boolean>(store[step.id as keyof typeof store] as unknown as boolean);

  const handleSelect = useCallback(() => {
    setValue(!value);
    onValueChange(!value, step);
  }, [onValueChange, step, value]);

  return (
    <>
      <form noValidate autoComplete="off">
        <Stack direction="column">
          <FormControlLabel
            label={`${t(`${slideTranslationsPrefix}.${step.id}.inputLabel`)}`}
            control={<Checkbox onChange={() => handleSelect()} checked={value} />}
          />
        </Stack>
        <DialogActionsPanel stepBackwards={stepBackwards} stepForwards={stepForwards} />
      </form>
    </>
  );
};

export default BooleanSlide;
