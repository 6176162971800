import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatHour } from '../../../utils/formatters';

interface HourSelectorProps {
  labelString: string;
  name: string;
  disabled?: boolean;
  timeOffset?: number;
}

const HourSelector = ({ labelString, name, disabled, timeOffset = 0 }: HourSelectorProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel>{t(`${labelString}`)}</InputLabel>
      <Controller
        render={({ field }) => (
          <Select {...field} variant="standard" label={t(`${labelString}`)} disabled={disabled}>
            {[...Array(24)].map((_, index) => (
              <MenuItem key={index + timeOffset} value={index + timeOffset}>
                {formatHour(index + timeOffset)}
              </MenuItem>
            ))}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};

export default HourSelector;
