import type { Action } from '@reduxjs/toolkit';
import { dBelApi, mapBoxApi, telemetryApi } from '@dbel/react-commons/api';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import type { ThunkAction } from 'redux-thunk';
import accountReducer from './slices/account';
import mapReducer from './slices/map';
import telemetryReducer from './slices/telemetry';

// TODO: Don't use combineReducers directly in configureStore until this bug is fixed
// see https://github.com/reduxjs/redux-toolkit/issues/1831
const rootReducer = combineReducers({
  account: accountReducer,
  map: mapReducer,
  telemetry: telemetryReducer,
  [dBelApi.reducerPath]: dBelApi.reducer,
  [mapBoxApi.reducerPath]: mapBoxApi.reducer,
  [telemetryApi.reducerPath]: telemetryApi.reducer,
});
const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false, actionCreatorCheck: false }).concat(
      dBelApi.middleware,
      mapBoxApi.middleware,
      telemetryApi.middleware
    ),
  devTools: import.meta.env['VITE_ENABLE_REDUX_DEV_TOOLS'] === 'true',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export default store;
