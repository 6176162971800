import { InputAdornment, TextField } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProjectCharacteristic } from '@dbel/shared/types';
import { formatValueUnitText } from '@dbel/react-commons/components';
import * as Yup from 'yup';
import DialogActionsPanel from './DialogActionsPanel';
import { ParameterSlideProps } from './ParameterSlideProps';

const NumberSlide = ({ store, stepBackwards, stepForwards, step, onValueChange }: ParameterSlideProps<number>) => {
  const { t } = useTranslation();

  const label = t(`pages.architect.project.create.parameterSlides.${step.id}.inputLabel`);

  const formValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        [step.id]: Yup.number()
          .label(label)
          .typeError(t('errors.formInvalidType', { property: label }))
          .required(t('errors.formRequired', { property: label }))
          .min(step.data.range.min, t('errors.formMinValue', { property: label, value: step.data.range.min }))
          .max(step.data.range.max, t('errors.formMaxValue', { property: label, value: step.data.range.max })),
      }),
    [label, step.data.range.max, step.data.range.min, step.id, t]
  );

  const { handleSubmit, register, formState, reset } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    shouldUnregister: true,
  });

  useEffect(() => {
    if (store) {
      reset({
        [step.id]: store[step.id as keyof typeof store],
      });
    }
  }, [store, reset, step.id]);

  const onSubmit = useCallback(
    (data: Partial<ProjectCharacteristic>) => {
      const value = data[step.id as keyof ProjectCharacteristic] as unknown as number;
      onValueChange(value, step);
    },
    [onValueChange, step]
  );

  return (
    <>
      <form noValidate autoComplete="off">
        <TextField
          {...register(`${step.id}`)}
          type="number"
          variant="standard"
          label={label}
          fullWidth
          error={formState.errors[`${step.id}`] !== undefined}
          helperText={
            formState.errors[`${step.id}`] ? (formState.errors[`${step.id}`]?.['message'] as unknown as string) : ' '
          }
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: step.data?.valueUnit ? (
              <InputAdornment position="end">{formatValueUnitText(step.data.valueUnit)}</InputAdornment>
            ) : undefined,
          }}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSubmit(onSubmit);
              stepForwards();
            }
          }}
        />
      </form>
      <DialogActionsPanel
        onSubmit={onSubmit}
        stepBackwards={stepBackwards}
        handleSubmit={handleSubmit}
        stepForwards={stepForwards}
        disabled={!formState.isValid}
      />
    </>
  );
};

export default NumberSlide;
