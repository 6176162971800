import RotatePlanControlIcon from './RotateControlIcon.svg';
import ScalePlanControlIcon from './ScaleControlIcon.svg';
import SoundSourceSymbol from './SoundSourceSymbol.svg';
import SoundSourceSymbolHighlighted from './SoundSourceSymbolHighlighted.svg';
import SoundSourceSymbolSelected from './SoundSourceSymbolSelected.svg';

export const LayerSymbols = {
  soundSourceSymbol: SoundSourceSymbol,
  soundSourceSymbolSelected: SoundSourceSymbolSelected,
  soundSourceSymbolHighlighted: SoundSourceSymbolHighlighted,
  rotatePlanControlIcon: RotatePlanControlIcon,
  scalePlanControlIcon: ScalePlanControlIcon,
} as const;
