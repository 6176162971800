import { Box } from '@mui/material';
import { ReactComponent as DBelLogoBig } from '../../../icons/dBelLogoBig.svg';

const DBELHeader = () => (
  <>
    <Box sx={{ height: '60px', position: 'absolute', top: 0, left: 0, right: 0, width: '100%', background: '#F0F0EB' }}>
      <Box sx={{ width: '1024px', m: 'auto' }}>
        <DBelLogoBig sx={{ fontSize: 60, ml: 2 }} />
      </Box>
    </Box>
  </>
);

export default DBELHeader;
