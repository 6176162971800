import { PropertyFieldUnit } from '@dbel/react-commons/types';
import {
  CommerceCharacteristics,
  HotelCharacteristics,
  OfficeCharacteristics,
  PrivateBuildingsCharacteristics,
  ProjectCharacteristic,
  projectCharacteristicsEnvironmentValues,
  ProjectCharacteristicTypes,
  RestaurantBarEventCharacteristics,
  SchoolCharacteristics,
  shopTypeValues,
} from '@dbel/shared/types';

export const teaserProjectCharacteristicTypes = ['TEASER1', 'TEASER2'];
type TeaserProjectCharacteristicTypes = (typeof teaserProjectCharacteristicTypes)[number];

type ParameterWizardProjectType = ProjectCharacteristicTypes | TeaserProjectCharacteristicTypes;

const parameterWizardProjectTypes: ParameterWizardProjectType[] = [
  'COMMERCE',
  'SCHOOL',
  'HOTEL',
  'RESTAURANT_BAR_EVENT',
  'OFFICE',
  'PRIVATE_BUILDINGS',
  'TEASER1',
  'TEASER2',
];
type Range = { min: number; max: number };

type SlideData<T> = {
  items?: T[];
  valueUnit?: PropertyFieldUnit;
  range?: Range;
};

export type ParameterWizardStep = {
  type: 'PROJECTSWITCH' | 'TEXT' | 'NUMBER' | 'HOURS' | 'CHECKBOX' | 'RADIOBOX' | 'BOOLEAN';
  data?: SlideData<string>;
  id: string;
};

export const introStep: ParameterWizardStep = {
  type: 'TEXT',
  id: 'intro',
};

export const projectTypeStep: ParameterWizardStep = {
  type: 'PROJECTSWITCH',
  id: 'projectCharacteristicType',
  data: {
    items: parameterWizardProjectTypes, // Object.entries(parameterWizardProjectTypes).map((key) => key[1].type as string),
  },
};

export const closingStep: ParameterWizardStep = {
  type: 'TEXT',
  id: 'closing',
};

const commerceSteps: ParameterWizardStep[] = [
  {
    type: 'NUMBER',
    id: 'totalSalesArea',
    data: {
      valueUnit: 'm2',
      range: { min: 100, max: 5000 },
    },
  },
  {
    type: 'HOURS',
    id: 'openingHours',
  },
  {
    type: 'CHECKBOX',
    id: 'includedShopTypes',
    data: {
      items: shopTypeValues,
    },
  },
];

const schoolSteps: ParameterWizardStep[] = [
  {
    type: 'NUMBER',
    id: 'totalPeople',
    data: {
      range: { min: 10, max: 5000 },
    },
  },
  {
    type: 'RADIOBOX',
    id: 'environment',
    data: {
      items: projectCharacteristicsEnvironmentValues,
    },
  },
];

const hotelSteps: ParameterWizardStep[] = [
  {
    type: 'NUMBER',
    id: 'hotelBeds',
    data: {
      range: { min: 1, max: 5000 },
    },
  },
];

const restaurantBarEventSteps: ParameterWizardStep[] = [
  {
    type: 'NUMBER',
    id: 'guestArea',
    data: {
      valueUnit: 'm2',
      range: { min: 1, max: 5000 },
    },
  },
  {
    type: 'NUMBER',
    id: 'outdoorSeats',
    data: {
      range: { min: 1, max: 5000 },
    },
  },
];

const officeSteps: ParameterWizardStep[] = [
  {
    type: 'NUMBER',
    id: 'parkingSpaces',
    data: {
      range: { min: 1, max: 5000 },
    },
  },
  {
    type: 'BOOLEAN',
    id: 'nightUsage',
  },
];

const privateBuildingSteps: ParameterWizardStep[] = [
  {
    type: 'NUMBER',
    id: 'aboveGroundParkingSpaces',
    data: {
      range: { min: 0, max: 5000 },
    },
  },
  {
    type: 'NUMBER',
    id: 'underGroundParkingSpaces',
    data: {
      range: { min: 0, max: 5000 },
    },
  },
];

export const projectCharacteristicTypesValues: Record<ProjectCharacteristicTypes, ParameterWizardStep[]> = {
  COMMERCE: commerceSteps,
  SCHOOL: schoolSteps,
  HOTEL: hotelSteps,
  RESTAURANT_BAR_EVENT: restaurantBarEventSteps,
  OFFICE: officeSteps,
  PRIVATE_BUILDINGS: privateBuildingSteps,
};

export const defaultCommerceProperties: CommerceCharacteristics = {
  type: 'COMMERCE',
  totalSalesArea: 750,
  includedShopTypes: [],
  openingHours: {
    weekdays: {
      from: 8,
      to: 20,
    },
    sundays: {
      from: 8,
      to: 20,
    },
  },
};

export const defaultSchoolProperties: SchoolCharacteristics = {
  type: 'SCHOOL',
  totalPeople: 20,
  environment: 'URBAN',
};

export const defaultHotelProperties: HotelCharacteristics = {
  type: 'HOTEL',
  hotelBeds: 20,
};

export const defaultOfficeProperties: OfficeCharacteristics = {
  type: 'OFFICE',
  nightUsage: false,
  parkingSpaces: 20,
};

export const defaultRestaurantBarEventProperties: RestaurantBarEventCharacteristics = {
  type: 'RESTAURANT_BAR_EVENT',
  guestArea: 20,
  outdoorSeats: 20,
};

export const defaultPrivateBuildingsProperties: PrivateBuildingsCharacteristics = {
  type: 'PRIVATE_BUILDINGS',
  underGroundParkingSpaces: 0,
  aboveGroundParkingSpaces: 0,
};

export function getDefaultPropertiesForItem(item: ProjectCharacteristicTypes) {
  switch (item) {
    case 'COMMERCE':
      return defaultCommerceProperties;
    case 'SCHOOL':
      return defaultSchoolProperties;
    case 'HOTEL':
      return defaultHotelProperties;
    case 'OFFICE':
      return defaultOfficeProperties;
    case 'RESTAURANT_BAR_EVENT':
      return defaultRestaurantBarEventProperties;
    case 'PRIVATE_BUILDINGS':
      return defaultPrivateBuildingsProperties;
    default:
      return {} as ProjectCharacteristic;
  }
}
