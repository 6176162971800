import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectAreaGeometry } from '@dbel/shared/types';
import { MapBoxAlertMessage } from '@dbel/react-commons/components';
import { useProjectAreaValidator } from '@dbel/react-commons/hooks';

export interface ProjectAreaStatusMessageProps {
  projectArea: ProjectAreaGeometry;
}

export const ProjectAreaStatusMessage = ({ projectArea }: ProjectAreaStatusMessageProps) => {
  const { t } = useTranslation();
  const { validationResult } = useProjectAreaValidator(projectArea);

  const messageParams = useMemo(() => {
    if (!validationResult.meta) return {};

    return {
      areaInSquareKilometers: validationResult.meta.areaInSquareKilometers,
      buildingsCount: validationResult.meta.buildings.length,
      maxBuildingCount: validationResult.meta.maxBuildingCount,
      maxAreaInSquareKilometers: validationResult.meta.maxAreaInSquareKilometers,
    };
  }, [validationResult.meta]);

  return (
    <>
      {validationResult?.areaIsValid && (
        <MapBoxAlertMessage severity="info">
          {t('pages.architect.project.create.projectAreaIsValid', { ...messageParams })}
        </MapBoxAlertMessage>
      )}

      {validationResult?.areaIsValid === false && validationResult.reason === 'AREA_TO_BIG' && (
        <MapBoxAlertMessage severity="warning">
          {t('pages.architect.project.create.projectAreaToBig', { ...messageParams })}
        </MapBoxAlertMessage>
      )}

      {validationResult?.areaIsValid === false && validationResult.reason === 'TO_MANY_BUILDINGS' && (
        <MapBoxAlertMessage severity="warning">
          {t('pages.architect.project.create.projectAreaToManyBuildings', { ...messageParams })}
        </MapBoxAlertMessage>
      )}
    </>
  );
};
