import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { RoomAcousticCalculatorProvider } from './RoomAcousticCalculatorContext';

const Content = styled('div')(({ theme }) =>
  theme.unstable_sx({
    height: '100%',
    width: '1024px',
    margin: 'auto',
  })
);

export const AllRoomAcousticsPagesLayout = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('pages.roomAcousticCalculator.pageTitle')}</title>
      </Helmet>
      <RoomAcousticCalculatorProvider>
        <Content>
          <Outlet />
        </Content>
      </RoomAcousticCalculatorProvider>
    </>
  );
};
