import { useDebug } from '@dbel/react-commons/components';
import { SimpleSoundSourcePropertiesDialog } from './SimpleSoundSourcePropertiesDialog';
import { ComplexSoundSourcePropertiesDialog } from './ComplexSoundSourcesPropertiesDialog';
import { CustomSoundSourcePropertiesDialogProps, OperatingMode } from './types';

/* 
we have a Simple & complex Sound Source properties dialog.
They have the same interface but the complex is only shown in debug modus. 
We keep the complex as legacy as it is, and we added the simple one on top. 
*/
export const CustomSoundSourcePropertiesDialog = ({
  open,
  onSave,
  onCancel,
  soundSource,
}: CustomSoundSourcePropertiesDialogProps) => {
  const debug = useDebug();

  const mode: OperatingMode = debug.enabled ? 'COMPLEX' : 'SIMPLE';

  return (
    <>
      {mode === 'SIMPLE' && (
        <SimpleSoundSourcePropertiesDialog open={open} onSave={onSave} onCancel={onCancel} soundSource={soundSource} />
      )}
      {mode === 'COMPLEX' && (
        <ComplexSoundSourcePropertiesDialog open={open} onSave={onSave} onCancel={onCancel} soundSource={soundSource} />
      )}
    </>
  );
};
