import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CriticalityTimeSpan } from '@dbel/react-commons/types';
import { SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TimeSpanDayIcon } from '../../icons/TimeSpanDay.svg';
import { ReactComponent as TimeSpanNightIcon } from '../../icons/TimeSpanNight.svg';
import { ReactComponent as TimeSpanOverallIcon } from '../../icons/TimeSpanOverall.svg';

const SwitchGroup = styled(ToggleButtonGroup)(({ theme }) =>
  theme.unstable_sx({
    bgcolor: 'grey.200',
    borderRadius: '6px',
  })
);

const Switch = styled(ToggleButton)(({ theme }) =>
  theme.unstable_sx({
    border: 0,
    p: 1,
    m: 0.5,
    boxShadow: 3,

    '&:not(.Mui-selected)': {
      borderRadius: '0',
      boxShadow: 'none',
    },

    '&.MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected, &.MuiToggleButtonGroup-grouped:not(:last-of-type).Mui-selected':
      {
        bgcolor: 'common.white',
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      },
  })
);

const SwitchText = styled(Typography)(({ theme }) =>
  theme.unstable_sx({ ml: 0.5, typography: 'body2', textTransform: 'none', fontWeight: 'bold' })
);

export interface CriticalityTimeSpanSwitcherProps {
  criticalityTimeSpan: CriticalityTimeSpan;
  onChange?: (newCriticalityTime: CriticalityTimeSpan) => void;
  modus?: 'ALL' | 'DAY_NIGHT'; // if we show all buttons or just day/ night
}

export const CriticalityTimeSpanSwitcher = ({
  criticalityTimeSpan,
  onChange,
  modus = 'ALL',
}: CriticalityTimeSpanSwitcherProps) => {
  const { t } = useTranslation();

  const handleChangeCriticalityTime = useCallback(
    (_: SyntheticEvent, newCriticalityTime: CriticalityTimeSpan) => {
      if (!newCriticalityTime) return;
      if (onChange) {
        onChange(newCriticalityTime);
      }
    },
    [onChange]
  );

  return (
    <>
      <SwitchGroup value={criticalityTimeSpan} onChange={handleChangeCriticalityTime} exclusive fullWidth>
        {modus === 'ALL' && (
          <Switch value="OVERALL">
            <TimeSpanOverallIcon />
            <SwitchText>{t('pages.architect.project.panels.criticalElementsSwitchOverall')}</SwitchText>
          </Switch>
        )}
        <Switch value="DAY">
          <TimeSpanDayIcon />
          <SwitchText>{t('pages.architect.project.panels.criticalElementsSwitchDay')}</SwitchText>
        </Switch>
        <Switch value="NIGHT">
          <TimeSpanNightIcon />
          <SwitchText>{t('pages.architect.project.panels.criticalElementsSwitchNight')}</SwitchText>
        </Switch>
      </SwitchGroup>
    </>
  );
};
