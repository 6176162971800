import { Box, FormControlLabel, Radio, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectCharacteristic } from '@dbel/shared/types';
import { teaserProjectCharacteristicTypes } from '.';
import DialogActionsPanel from './DialogActionsPanel';
import { ParameterSlideProps } from './ParameterSlideProps';

export interface RadioSlideProps<T> extends Omit<ParameterSlideProps<T>, 'store'> {
  checkedItem?: T; // when no store provided we can set it manually
  store?: ProjectCharacteristic;
}

const RadioBoxSlide = <T extends string>({
  stepBackwards,
  stepForwards,
  onValueChange,
  store,
  checkedItem,
  step,
}: RadioSlideProps<T>) => {
  const { t } = useTranslation();

  const currentCheckedItem = useMemo(() => {
    if (checkedItem) return checkedItem;
    return store[step.id as keyof typeof store];
  }, [checkedItem, step.id, store]);

  const selectItem = useCallback(
    (item: T) => {
      onValueChange(item, step);
    },
    [onValueChange, step]
  );

  return (
    <>
      <Box>
        <Stack direction="column">
          {step.data.items.map((item, index) => (
            <FormControlLabel
              key={index}
              control={<Radio onChange={() => selectItem(item as T)} checked={item === currentCheckedItem} />}
              label={
                <>
                  <Typography display="inline">
                    {t(`pages.architect.project.create.parameterSlides.${step.id}.data.${item}`)}
                  </Typography>
                  {teaserProjectCharacteristicTypes.indexOf(item) !== -1 && (
                    <Typography
                      display="inline"
                      sx={{
                        backgroundColor: 'background.default',
                        p: 1,
                        ml: 1,
                        borderRadius: '6px',
                      }}
                    >
                      {t(`pages.architect.project.create.parameterSlides.${step.id}.comingSoonLabel`)}
                    </Typography>
                  )}
                </>
              }
              disabled={teaserProjectCharacteristicTypes.indexOf(item) !== -1}
            />
          ))}
        </Stack>
        <DialogActionsPanel stepBackwards={stepBackwards} stepForwards={stepForwards} />
      </Box>
    </>
  );
};

export default RadioBoxSlide;
