import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawModeChangeEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw';
import GlobalStyles from '@mui/material/GlobalStyles';
import { coordAll, featureCollection } from '@turf/turf';
import { useMapBox, useDebug, DebugLogger, MapBoxDrawContextProvider } from '@dbel/react-commons/components';
import { LayerStyle } from '@dbel/react-commons/types';
import { useDebouncedCallback } from 'use-debounce';
import { Feature } from 'geojson';
// @ts-ignore because no typings are available as of now
import { TxRectMode } from 'mapbox-gl-draw-rotate-scale-rect-mode';
import { forwardRef, ReactElement, ReactNode, Ref, useEffect, useImperativeHandle, useState } from 'react';
import {
  closeProjectItemPropertiesPanel,
  openProjectItemPropertiesPanel,
  resetCurrentLiveEditedMapBoxDrawFeatureId,
  resetToolbar,
  setCurrentLiveEditedMapBoxDrawFeatureId,
  switchToMapDrawMode,
} from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';

import {
  isMapBoxDrawDirectSelectMode,
  isMapBoxDrawDrawLineStringMode,
  isMapBoxDrawDrawPointMode,
  isMapBoxDrawDrawPolygonMode,
  isMapBoxDrawScaleRotateMode,
  isMapBoxDrawSimpleSelectMode,
} from '../types';
import { disableShiftClickInMapBoxDrawSimpleSelectMode } from './MapBoxDrawSimpleSelectModePatch';

const ON_UPDATE_FEATURE_DEBOUNCE_THRESHOLD_IN_MILLIS = 300;

export type DrawLayerStyle = Array<LayerStyle & { id: string }>;
export type MergableDrawLayerStyle = Array<Omit<LayerStyle, 'type'> & { id: string }>;

export interface DrawLayerProps {
  children?: ReactNode | JSX.Element | ReactElement;
  data: Feature | Feature[];
  selectedFeature?: Feature;
  layerStyle?: DrawLayerStyle;
  useSelectOnlyMode?: boolean;
  onCreateFeature?: (feature: Feature) => void;
  onUpdateFeature?: (feature: Feature) => void;
  onUpdateFeatureDebounced?: (feature: Feature) => void;
  onDeleteFeature?: (feature: Feature) => void;
  openProjectPropertiesOnClick?: boolean;
}

function MapBoxMapDrawLayer(
  {
    children,
    data,
    selectedFeature,
    layerStyle,
    useSelectOnlyMode,
    onCreateFeature,
    onUpdateFeature,
    onUpdateFeatureDebounced,
    onDeleteFeature,
    openProjectPropertiesOnClick = true,
  }: DrawLayerProps,
  ref: Ref<MapboxDraw>
) {
  const dispatch = useDispatch();
  const { mapBox } = useMapBox();
  const debug = useDebug();
  const [addedToMapBox, setAddedToMapBox] = useState<boolean>(false);

  const [mapBoxDraw] = useState<MapboxDraw>(() => {
    disableShiftClickInMapBoxDrawSimpleSelectMode();

    return new MapboxDraw({
      boxSelect: false,
      userProperties: true,
      styles: layerStyle,
      modes: {
        ...MapboxDraw.modes,
        tx_poly: TxRectMode,
      },
    });
  });

  const drawMode = useSelector((state: RootState) => state.map.mapDrawMode);

  const handleDebouncedOnUpdateFeature = useDebouncedCallback((feature: Feature) => {
    if (onUpdateFeatureDebounced) {
      onUpdateFeatureDebounced(feature);
    }
  }, ON_UPDATE_FEATURE_DEBOUNCE_THRESHOLD_IN_MILLIS);

  useImperativeHandle(ref, () => mapBoxDraw);

  useEffect(() => {
    mapBox.addControl(mapBoxDraw);

    setAddedToMapBox(true);

    return () => {
      mapBox.removeControl(mapBoxDraw);
    };
  }, [mapBox, mapBoxDraw]);

  useEffect(() => {
    if (data) {
      mapBoxDraw.set(featureCollection(Array.isArray(data) ? data : [data]));
    } else {
      mapBoxDraw.deleteAll();
    }
  }, [data, mapBoxDraw]);

  useEffect(() => {
    const onDrawCreate = (event: DrawCreateEvent) => {
      if (useSelectOnlyMode) return;

      if (onCreateFeature) {
        onCreateFeature(event.features[0]);
      }
    };

    mapBox.on('draw.create', onDrawCreate);

    return () => {
      mapBox.off('draw.create', onDrawCreate);
    };
  }, [mapBox, onCreateFeature, useSelectOnlyMode]);

  useEffect(() => {
    const onDrawUpdate = (event: DrawUpdateEvent) => {
      if (onUpdateFeature || onUpdateFeatureDebounced) {
        const [firstFeature] = event.features;

        // handle the special case if we delete a vertex from (multi)polygon with 3 vertices
        // this results in 2 draw events in succession: update and delete
        // and we want to filter out the update event because a polygon with 2 vertices (=line) should
        // not be saved to the BE, just deleted
        if (firstFeature.geometry.type === 'Polygon' || firstFeature.geometry.type === 'MultiPolygon') {
          if (coordAll(firstFeature.geometry).length > 0) {
            if (onUpdateFeature) onUpdateFeature(firstFeature);
            if (onUpdateFeatureDebounced) handleDebouncedOnUpdateFeature(firstFeature);
          }
        } else {
          if (onUpdateFeature) onUpdateFeature(firstFeature);
          if (onUpdateFeatureDebounced) handleDebouncedOnUpdateFeature(firstFeature);
        }
      }
    };

    mapBox.on('draw.update', onDrawUpdate);

    return () => {
      mapBox.off('draw.update', onDrawUpdate);
    };
  }, [handleDebouncedOnUpdateFeature, mapBox, onUpdateFeature, onUpdateFeatureDebounced]);

  useEffect(() => {
    const onDrawDelete = (event: DrawDeleteEvent) => {
      if (onDeleteFeature) {
        dispatch(closeProjectItemPropertiesPanel());
        onDeleteFeature(event.features[0]);
      }
    };

    mapBox.on('draw.delete', onDrawDelete);

    return () => {
      mapBox.off('draw.delete', onDrawDelete);
    };
  }, [dispatch, mapBox, onDeleteFeature]);

  useEffect(() => {
    const onDrawModeChange = (event: DrawModeChangeEvent) => {
      switch (event.mode) {
        case 'simple_select': {
          dispatch(switchToMapDrawMode({ mode: event.mode }));
          dispatch(resetToolbar());
          break;
        }
        case 'direct_select': {
          dispatch(
            switchToMapDrawMode({
              mode: event.mode,
              selectedFeatureId: String(mapBoxDraw.getSelected().features[0].id),
            })
          );
          break;
        }
        case 'static': {
          break;
        }
        default: {
          dispatch(switchToMapDrawMode({ mode: event.mode }));
        }
      }
    };

    mapBox.on('draw.modechange', onDrawModeChange);

    return () => {
      mapBox.off('draw.modechange', onDrawModeChange);
    };
  }, [dispatch, mapBox, mapBoxDraw]);

  useEffect(() => {
    const onDrawSelectionChange = (event: DrawSelectionChangeEvent) => {
      if (useSelectOnlyMode) return;

      if (event.features.length === 1 && openProjectPropertiesOnClick) {
        dispatch(openProjectItemPropertiesPanel(event.features[0]));

        if (debug.enabled) {
          DebugLogger.logToConsole(event.features[0]);
        }
      } else {
        dispatch(closeProjectItemPropertiesPanel());
      }
    };

    mapBox.on('draw.selectionchange', onDrawSelectionChange);

    return () => {
      mapBox.off('draw.selectionchange', onDrawSelectionChange);
    };
  }, [debug.enabled, dispatch, mapBox, openProjectPropertiesOnClick, useSelectOnlyMode]);

  useEffect(() => {
    const modeIsLiveEditMode = mapBoxDraw.getMode() === 'simple_select' || mapBoxDraw.getMode() === 'direct_select';

    const onMouseDown = () => {
      const [selectedMapBoxDrawFeature] = mapBoxDraw.getSelected().features;

      if (selectedMapBoxDrawFeature) {
        dispatch(setCurrentLiveEditedMapBoxDrawFeatureId(String(selectedMapBoxDrawFeature.id)));
      }
    };

    const onMouseUp = () => {
      dispatch(resetCurrentLiveEditedMapBoxDrawFeatureId());
    };

    // track mouse up/down to get if user is actively drag'n'droping objects or vertices
    if (modeIsLiveEditMode) {
      mapBox.on('mousedown', onMouseDown);
      mapBox.on('mouseup', onMouseUp);
    }

    return () => {
      if (modeIsLiveEditMode) {
        mapBox.off('mousedown', onMouseDown);
        mapBox.off('mouseup', onMouseUp);
      }
    };
  }, [dispatch, mapBox, mapBoxDraw]);

  useEffect(() => {
    const currentMode = mapBoxDraw.getMode();
    if (selectedFeature === undefined) {
      if (currentMode === 'simple_select') {
        mapBoxDraw.changeMode(currentMode, { featureIds: [] });
      }
      if (currentMode === 'direct_select') {
        mapBoxDraw.changeMode(currentMode, { featureId: undefined });
      }
    } else if (currentMode === 'simple_select') {
      // set a timeout while the feature can only be set to selected after the mapboxdraw is added to the map
      setTimeout(() => {
        mapBoxDraw.changeMode(currentMode, { featureIds: [String(selectedFeature.id)] });
      }, 1);
    }
  }, [mapBoxDraw, selectedFeature]);

  useEffect(() => {
    if (isMapBoxDrawSimpleSelectMode(drawMode)) {
      mapBoxDraw.changeMode(drawMode.mode, { featureIds: drawMode.selectedFeatureIds });
    }

    if (isMapBoxDrawDirectSelectMode(drawMode)) {
      mapBoxDraw.changeMode(drawMode.mode, { featureId: drawMode.selectedFeatureId });
    }

    if (isMapBoxDrawDrawPointMode(drawMode) || isMapBoxDrawDrawPolygonMode(drawMode)) {
      mapBoxDraw.changeMode(drawMode.mode);
    }

    if (isMapBoxDrawDrawLineStringMode(drawMode)) {
      mapBoxDraw.changeMode(drawMode.mode);
    }

    if (isMapBoxDrawScaleRotateMode(drawMode)) {
      // @ts-ignore because the there's no other changeMode() overload to use with custom modes like tx_poly
      mapBoxDraw.changeMode(drawMode.mode, {
        featureId: drawMode.selectedFeatureId,
        ...drawMode.options,
      });
    }
  }, [drawMode, mapBoxDraw]);

  return (
    <>
      <GlobalStyles
        styles={{
          // TODO: this is needed because we cannot use the hide controls option displayControlsDefault: false from mapbox draw plugin
          // if we set this to false, due to a bug, also the keybindings (i.e. DEL) doesn't work anymore
          // so we have to hide the controls via CSS manually
          // see https://github.com/mapbox/mapbox-gl-draw/issues/805
          '.mapbox-gl-draw_ctrl-draw-btn': {
            display: 'none !important',
          },
        }}
      />

      <MapBoxDrawContextProvider contextValue={mapBoxDraw}>{addedToMapBox && children}</MapBoxDrawContextProvider>
    </>
  );
}

export default forwardRef(MapBoxMapDrawLayer);
