import { DrawLayerStyle } from '../MapBoxMapDrawLayer';
import { PROJECT_AREA_BORDER_COLOR } from './colors/colors';

export const PROJECT_AREA_DRAW_LAYER_STYLE: DrawLayerStyle = [
  {
    id: 'area-fill',
    type: 'fill',
    filter: ['all', ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#fff',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'area-border',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': PROJECT_AREA_BORDER_COLOR,
      'line-width': 1,
    },
  },
  {
    id: 'vertex-border',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['!has', 'heading'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 8,
      'circle-color': '#fff',
    },
  },
  {
    id: 'vertex-fill',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['!has', 'heading'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 5,
      'circle-color': PROJECT_AREA_BORDER_COLOR,
    },
  },
  {
    id: 'border-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['!has', 'heading'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': PROJECT_AREA_BORDER_COLOR,
    },
  },
  {
    id: 'rotate-icon',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint'], ['has', 'heading']],
    layout: {
      'icon-image': 'rotatePlanControlIcon',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading'],
    },
  },
  {
    id: 'scale-icon',
    type: 'symbol',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex'], ['has', 'heading']],
    layout: {
      'icon-image': 'scalePlanControlIcon',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading'],
    },
  },
];
