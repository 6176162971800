import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OpeningHours, ProjectCharacteristic } from '@dbel/shared/types';
import DialogActionsPanel from './DialogActionsPanel';
import HourSelector from './HourSelector';
import { ParameterSlideProps } from './ParameterSlideProps';

export interface HoursSlideProps extends ParameterSlideProps<OpeningHours> {
  sundayChecked?: boolean;
  switchSunday?: Dispatch<SetStateAction<boolean>>;
}

const HoursSlide = ({
  store,
  onValueChange,
  sundayChecked,
  switchSunday,
  step,
  stepBackwards,
  stepForwards,
}: HoursSlideProps) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: store,
    shouldUnregister: true,
  });

  const { handleSubmit } = methods;

  const handleSundayClicked = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      switchSunday(e.target.checked);
    },
    [switchSunday]
  );

  const onSubmit = useCallback(
    (data: Partial<ProjectCharacteristic>) => {
      const value = data[step.id as keyof ProjectCharacteristic] as unknown as OpeningHours;

      onValueChange(value, step);
    },
    [onValueChange, step]
  );

  return (
    <>
      <FormProvider {...methods}>
        <form noValidate autoComplete="off">
          <Stack direction="column" spacing={1}>
            <Box>
              <HourSelector labelString="common.labels.from" name="openingHours.weekdays.from" />
              <HourSelector labelString="common.labels.to" name="openingHours.weekdays.to" timeOffset={1} />
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sundayChecked}
                  onChange={handleSundayClicked}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={`${t('pages.architect.project.create.parameterSlides.openingHours.sundayOpenTitle')}`}
            />
            <Box>
              <HourSelector
                labelString="common.labels.from"
                name="openingHours.sundays.from"
                disabled={!sundayChecked}
              />
              <HourSelector
                labelString="common.labels.to"
                name="openingHours.sundays.to"
                disabled={!sundayChecked}
                timeOffset={1}
              />
            </Box>
          </Stack>
        </form>
      </FormProvider>
      <DialogActionsPanel
        stepForwards={stepForwards}
        stepBackwards={stepBackwards}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default HoursSlide;
