import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {
  useMapBox,
  MapBoxMapScaleControl,
  MapBoxMapToolbar,
  MapBoxMapControlsToolbar,
} from '@dbel/react-commons/components';

import { PropsWithSx, ViewMode } from '@dbel/react-commons/types';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useRef } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { ToolbarsContextProvider } from '../../contexts/ToolbarContext';
import { setViewMode } from '../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../store/store';
import MapSurroundingMapMode from './modes/MapSurroundingMapMode';
import { ProjectBuildingsMapMode } from './modes/ProjectBuildingsMapMode';
import { ResultsMapMode } from './modes/ResultsMapMode';
import { SoundSourcesMapMode } from './modes/SoundSourcesMapMode';
import {
  ProjectItemPropertiesRightPanel,
  PROJECT_ITEM_PROPERTIES_PANEL_WIDTH,
} from './panels/ProjectItemPropertiesRightPanel';
import CreateProjectMapMode from './modes/CreateProjectMapMode';
import { useProjectIdFromUrl } from '../../hooks/useProjectIdFromUrl';

interface MainProps {
  open?: boolean;
}

const Main = styled('div', { shouldForwardProp: (prop) => (prop as keyof MainProps) !== 'open' })<MainProps>(
  ({ open, theme }) =>
    theme.unstable_sx({
      flexGrow: 1,
      position: 'static',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
    })
);

export const MapBoxWrapper = ({ children }: PropsWithSx<PropsWithChildren<{}>>): ReactNode => {
  const dispatch = useDispatch();

  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);
  const mapMode = useSelector((state: RootState) => state.map.mapMode);
  const viewMode = useSelector((state: RootState) => state.map.viewMode);

  const { mapBox, initiallyLoaded } = useMapBox();
  const projectToolbarRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (mapBox && !projectItemForPropertiesPanel) {
      mapBox.resize();
    }
  }, [mapBox, projectItemForPropertiesPanel]);

  useEffect(() => {
    if (!mapBox || !viewMode) return;

    if (viewMode === '2D') {
      mapBox.dragRotate.disable();
    }

    if (viewMode === 'transitTo3D') {
      mapBox.easeTo({ pitch: 60 });
      mapBox.dragRotate.disable();
      mapBox.once('pitchend', () => {
        dispatch(setViewMode('3D'));
        mapBox.dragRotate.enable();
      });
    } else if (viewMode === 'transitTo2D') {
      mapBox.easeTo({ pitch: 0 });
      mapBox.dragRotate.disable();
      mapBox.once('pitchend', () => {
        dispatch(setViewMode('2D'));
      });
    }
  }, [mapBox, viewMode, dispatch]);

  const projectId = useProjectIdFromUrl();
  const { data: project } = useGetProjectByIdQuery(projectId ?? skipToken);

  const centerMap = () => {
    if (project?.boundingBox.bbox.length === 4) {
      mapBox.fitBounds(project.boundingBox.bbox);
    }
  };

  const changeViewMode = useCallback(
    (newViewMode: ViewMode) => {
      dispatch(setViewMode(newViewMode));
    },
    [dispatch]
  );

  return (
    <>
      <ToolbarsContextProvider projectToolbarRef={projectToolbarRef}>
        <Main open={projectItemForPropertiesPanel !== undefined}>
          {initiallyLoaded && (
            <>
              {mapMode === 'CREATE_PROJECT' && <CreateProjectMapMode />}
              {mapMode === 'MAP_SURROUNDING' && <MapSurroundingMapMode />}
              {mapMode === 'PROJECT_BUILDINGS' && <ProjectBuildingsMapMode />}
              {mapMode === 'SOUND_SOURCES' && <SoundSourcesMapMode />}
              {mapMode === 'RESULTS' && <ResultsMapMode />}

              <MapBoxMapScaleControl />

              <Box
                sx={{ position: 'absolute', top: 120, right: projectItemForPropertiesPanel !== undefined ? 310 : 10 }}
              >
                <MapBoxMapToolbar ref={projectToolbarRef} />
              </Box>

              <MapBoxMapControlsToolbar
                centerMap={project ? centerMap : undefined}
                viewMode={viewMode}
                changeViewMode={project ? changeViewMode : undefined}
                sx={{ position: 'absolute', bottom: 70, right: projectItemForPropertiesPanel !== undefined ? 310 : 10 }}
              />
              {children}
            </>
          )}
        </Main>
        <ProjectItemPropertiesRightPanel width={PROJECT_ITEM_PROPERTIES_PANEL_WIDTH} />
      </ToolbarsContextProvider>
    </>
  );
};
