import { Box } from '@mui/material';
// @ts-ignore
import NProgress from 'nprogress';
import { useEffect } from 'react';

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        minHeight: '100%',
      }}
    />
  );
};

export default LoadingScreen;
