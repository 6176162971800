import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DialogActionsPanel from './DialogActionsPanel';
import { ParameterSlideProps } from './ParameterSlideProps';

const CheckBoxSlide = ({ store, stepBackwards, stepForwards, onValueChange, step }: ParameterSlideProps<string[]>) => {
  const { t } = useTranslation();

  const selectedItems = useMemo(() => store[step.id as keyof typeof store] as unknown as string[], [step.id, store]);

  const handleSelect = useCallback(
    (item: string) => {
      const isPresent = selectedItems.indexOf(item) !== -1;
      if (isPresent) {
        onValueChange(
          selectedItems.filter((x) => x !== item),
          step
        );
      } else {
        selectedItems.push(item);
        onValueChange(selectedItems, step);
      }
    },
    [onValueChange, selectedItems, step]
  );

  return (
    <>
      <form noValidate autoComplete="off">
        <Stack direction="column">
          {step.data.items.map((item) => (
            <FormControlLabel
              key={item}
              label={`${t(`common.shopTypes.${item.toLowerCase()}`)}`}
              control={<Checkbox onChange={() => handleSelect(item)} checked={selectedItems.indexOf(item) !== -1} />}
            />
          ))}
        </Stack>
        <DialogActionsPanel stepBackwards={stepBackwards} stepForwards={stepForwards} />
      </form>
    </>
  );
};

export default CheckBoxSlide;
