import { TabPanel } from '@mui/lab';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { useUpdateBuildingMutation, useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Building, TaLaermAreaType } from '@dbel/shared/types';
import { useProjectIdFromUrl } from '../../../../hooks/useProjectIdFromUrl';
import { openProjectItemPropertiesPanel } from '../../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../../store/store';
import { PropertyLabel } from '../common/PropertyLabel';
import { ReceiverAccordion } from './immissions-tab/ReceiverAccordion';
import { DrawerPropertiesTabsType } from './index';
import { TaLaermPresets } from '../../../../NoiseProtectionEvaluationPresets';

export interface ImmissionsTabProps {
  building: Building;
  forTab: DrawerPropertiesTabsType;
}

export const ImmissionsTab = ({ building, forTab }: ImmissionsTabProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [updateBuilding, { isLoading }] = useUpdateBuildingMutation();
  const projectId = useProjectIdFromUrl();
  const { data: project } = useGetProjectByIdQuery(projectId);

  const mapMode = useSelector((state: RootState) => state.map.mapMode);
  const projectSettings = project?.settings;
  const taLaermAreaFromProject = projectSettings.taLaerm?.defaultAreaType;
  const mapIsInResultsMode = mapMode === 'RESULTS';
  const mapIsInSurroundingsMode = mapMode === 'MAP_SURROUNDING';

  const sendUpdatedBuilding = useCallback(
    (buildingForUpdate: Building) => {
      updateBuilding({ projectId, building: buildingForUpdate })
        .unwrap()
        .then((updatedBuilding: Building) => {
          dispatch(openProjectItemPropertiesPanel(updatedBuilding));
        });
    },
    [dispatch, projectId, updateBuilding]
  );

  const handleOnTaLearmDropDownChanged = useCallback(
    (event: SelectChangeEvent<TaLaermAreaType>) => {
      const taLaermAreaType = event.target.value as TaLaermAreaType;
      const buildingForUpdate = cloneDeep(building);

      if (!taLaermAreaType) {
        delete buildingForUpdate.properties.taLaerm;
      } else {
        buildingForUpdate.properties.taLaerm = {
          areaType: taLaermAreaType,
        };
      }
      sendUpdatedBuilding(buildingForUpdate);
    },
    [building, sendUpdatedBuilding]
  );

  const onSwitchChange = useCallback(() => {
    const buildingForUpdate = cloneDeep(building);

    buildingForUpdate.properties.ignoreInEvaluation = !building.properties.ignoreInEvaluation;

    sendUpdatedBuilding(buildingForUpdate);
  }, [building, sendUpdatedBuilding]);

  return (
    <>
      <TabPanel value={forTab} sx={{ width: '100%', m: 0, p: 0, mt: 2 }}>
        {(mapIsInResultsMode || mapIsInSurroundingsMode) && (
          <>
            <FormControlLabel
              disableTypography
              sx={{
                ml: 0,
                justifyContent: 'space-between',
                display: 'flex',
                fontSize: 14,
                pointerEvents: 'none',
              }}
              control={
                <Switch
                  disabled={isLoading}
                  onChange={onSwitchChange}
                  checked={building.properties.ignoreInEvaluation ?? false}
                  sx={{ mr: '4px', pointerEvents: 'auto' }}
                />
              }
              label={`${t('pages.architect.project.drawer.excludeSwitchTitle')}`}
              labelPlacement="start"
            />
            <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
          </>
        )}
        <FormControl variant="standard" fullWidth>
          <InputLabel id="ta-laerm-area-label" shrink>
            {t('pages.architect.project.drawer.taLaermAreaLabel')}
          </InputLabel>

          <Select
            value={building.properties.taLaerm?.areaType ?? taLaermAreaFromProject}
            onChange={handleOnTaLearmDropDownChanged}
            labelId="ta-laerm-area-label"
            displayEmpty
          >
            <MenuItem value="">{t('pages.architect.project.drawer.taLaermAreaDefaultEntryText')}</MenuItem>
            <ListSubheader>
              {taLaermAreaFromProject && (
                <Typography variant="subtitle2">({t(`common.taLaermArea.types.${taLaermAreaFromProject}`)})</Typography>
              )}
              <Divider sx={{ my: 1 }} />
            </ListSubheader>
            {Object.keys(TaLaermPresets).map((areaType) => (
              <MenuItem key={areaType} value={areaType}>
                {t(`common.taLaermArea.types.${areaType}`)}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>&nbsp;</FormHelperText>
        </FormControl>

        {mapIsInResultsMode && (
          <>
            <PropertyLabel label={t('pages.architect.project.drawer.receiverLabel')} />
            <ReceiverAccordion building={building} disabled={building.properties.ignoreInEvaluation} />
          </>
        )}
      </TabPanel>
    </>
  );
};
