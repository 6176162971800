import { AbsorptionPreset, DEFAULT_ABSORPTION_PRESET } from './AborptionPreset';
import { AbsorberType, AbsorptionHeight } from './Absorptions';
import { FurnishingType } from './Furnishing';
import { MaterialType } from './Materials';
import { UsageScenarioType, UsageScenarioUseCase } from './UsageScenarios';

export type Surface = {
  firstMaterial: MaterialType;
  secondMaterial?: MaterialType;
  materialDistribution?: number;
};

export enum CustomAbsorberTypes {
  CEILING = 'ceilingAbsorptionType',
  WALL = 'wallAbsorptionType',
}

export enum SurfaceProperty {
  wallSurface = 'wallSurface',
  floorSurface = 'floorSurface',
  ceilingSurface = 'ceilingSurface',
}

export const ROOM_ACOUSTIC_CALCULATION_VERSION_1_1_0 = '1.1.0';

/**
  version 1.0 
  length: number;
  width: number;
  height: number;
  windowArea: number;
  wallSurface: Surface;
  floorSurface: Surface;
  ceilingSurface: Surface;
  usageScenarios: UsageScenarioType[];
  usageScenarioUseCase: UsageScenarioUseCase;
  numberOfPeople: number;
  furnishingType: FurnishingType;
  ceilingAbsorptionType: AbsorberType;
  ceilingAborptionHeight: AbsorptionHeight;
  ceilingAbsorptionAmount: number;
  ceilingCustomAbsorption: Absorption;
  wallAbsorptionType: AbsorberType;
  wallAbsorptionAmount: number;
  wallCustomAbsorption: Absorption;

  version 1.1
  removed   
  ceilingCustomAbsorption: Absorption;
  wallCustomAbsorption: Absorption;
  ceilingAbsorptionType: AbsorberType;
  wallAbsorptionType: AbsorberType;

  added 
  version:1.1.0
  wallCustomAborptionPreset
  ceilingCustomAborptionPreset
*/

export interface RoomCalculation {
  length: number;
  width: number;
  height: number;
  windowArea: number;
  wallSurface: Surface;
  floorSurface: Surface;
  ceilingSurface: Surface;
  usageScenarios: UsageScenarioType[];
  usageScenarioUseCase: UsageScenarioUseCase;
  numberOfPeople: number;
  furnishingType: FurnishingType;

  ceilingAbsorptionType: AbsorberType;
  ceilingAborptionHeight: AbsorptionHeight;
  ceilingAbsorptionAmount: number;
  //  ceilingCustomAbsorption: Absorption;
  ceilingCustomAbsorptionPreset: AbsorptionPreset;
  wallAbsorptionType: AbsorberType;
  wallAbsorptionAmount: number;
  // wallCustomAbsorption: Absorption;
  wallCustomAbsorptionPreset: AbsorptionPreset;
  version: '1.1.0';
}

export const DEFAULT_ROOM_CALCULATION: RoomCalculation = {
  length: 10,
  height: 3,
  width: 7,
  windowArea: 20,
  wallSurface: {
    firstMaterial: MaterialType.MASSIVE,
    secondMaterial: MaterialType.WOOD,
    materialDistribution: 0,
  },
  floorSurface: {
    firstMaterial: MaterialType.PARQUET,
    secondMaterial: MaterialType.LINOLEUM,
    materialDistribution: 0.5,
  },
  ceilingSurface: {
    firstMaterial: MaterialType.MASSIVE,
    secondMaterial: null,
    materialDistribution: 0.5,
  },
  numberOfPeople: 6,
  furnishingType: FurnishingType.EMPTY,
  usageScenarios: [UsageScenarioType.MUSIC_ACOUSTIC],
  usageScenarioUseCase: UsageScenarioUseCase.COMMUNICATION,

  ceilingAbsorptionType: AbsorberType.STANDARD,
  ceilingAbsorptionAmount: 0,
  ceilingAborptionHeight: AbsorptionHeight.MM200,
  ceilingCustomAbsorptionPreset: DEFAULT_ABSORPTION_PRESET,

  wallAbsorptionType: AbsorberType.STANDARD,
  wallAbsorptionAmount: 0,
  wallCustomAbsorptionPreset: DEFAULT_ABSORPTION_PRESET,
  version: ROOM_ACOUSTIC_CALCULATION_VERSION_1_1_0,
} as RoomCalculation;
