import { createContext, MutableRefObject, PropsWithChildren, useContext, useEffect, useState } from 'react';

export interface ToolbarsContextValue {
  projectToolbarRef: MutableRefObject<HTMLDivElement>;
}

const ToolbarsContext = createContext<ToolbarsContextValue>(undefined);

export const useToolbars = () => useContext<ToolbarsContextValue>(ToolbarsContext);

export interface ToolbarsContextProps {
  projectToolbarRef: MutableRefObject<HTMLDivElement>;
}

export const ToolbarsContextProvider = ({ children, projectToolbarRef }: PropsWithChildren<ToolbarsContextProps>) => {
  const [contextValue, setContextValue] = useState<ToolbarsContextValue>();

  useEffect(() => {
    setContextValue({
      projectToolbarRef,
    });
  }, [projectToolbarRef]);

  return (
    <>
      <ToolbarsContext.Provider value={contextValue}>{children}</ToolbarsContext.Provider>
    </>
  );
};
