import { RootState, useSelector } from '../../../store/store';
import BuildingsAndWallsLayer from '../layers/BuildingsAndWallsLayer';
import { ProjectPlanLayer } from '../layers/ProjectPlanLayer';
import SoundSourcesLayer from '../layers/SoundSourcesLayer';

const MapSurroundingMapMode = () => {
  const appMode = useSelector((state: RootState) => state.map.appMode);

  return (
    <>
      <ProjectPlanLayer />
      <BuildingsAndWallsLayer drawProjectBuildings={false} />
      <SoundSourcesLayer isEditable={false} projectSoundSources={appMode === 'NOISE_EMISSIONS'} isSelectable={false} />
    </>
  );
};

export default MapSurroundingMapMode;
