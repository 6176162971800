import { Expression } from 'mapbox-gl';
import { LayerStyle } from '@dbel/react-commons/types';
import { BUILDING_PROJECT_COLOR, BUILDING_SURROUNDING_COLOR } from './colors/colors';
import { BUILDING_FILL_COLOR, BUILDING_OUTLINE_OPACITY } from './expressions/buildingExpressions';
import { HEIGHT_FEATURE_STATE_VALUE } from './expressions/featureStateExpressions';
import { IS_PROJECT_WALL } from './expressions/propertyExpressions';

export const WALL_BASE_COLOR: Expression = [
  'case',
  IS_PROJECT_WALL,
  BUILDING_PROJECT_COLOR,
  BUILDING_SURROUNDING_COLOR,
];

export const WALLS_3D_LAYER_STYLE: LayerStyle = {
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-color': BUILDING_FILL_COLOR,
    'fill-extrusion-height': HEIGHT_FEATURE_STATE_VALUE,
    'fill-extrusion-opacity': 0.5,
    'fill-extrusion-vertical-gradient': true,
  },
};

export const WALLS_LAYER_STYLE: LayerStyle = {
  type: 'line',
  paint: {
    'line-color': WALL_BASE_COLOR,
    'line-width': 2,
    'line-opacity': BUILDING_OUTLINE_OPACITY,
  },
};
