import { TabPanel } from '@mui/lab';
import { Building, isBuilding, isWall, Wall } from '@dbel/shared/types';
import { DrawerPropertiesTabsType } from './index';
import { BuildingPropertiesForm } from './properties-tab/BuildingPropertiesForm';
import { WallPropertiesForm } from './properties-tab/WallPropertiesForm';

export interface PropertiesTabProps {
  feature: Building | Wall;
  forTab: DrawerPropertiesTabsType;
}

export const PropertiesTab = ({ feature, forTab }: PropertiesTabProps) => (
  <TabPanel value={forTab} sx={{ width: '100%', m: 0, p: 0, mt: 2 }}>
    {isBuilding(feature) && <BuildingPropertiesForm building={feature} />}
    {isWall(feature) && <WallPropertiesForm wall={feature} />}
  </TabPanel>
);
