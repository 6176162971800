export enum MaterialType {
  MASSIVE = 'MASSIVE',
  CONCRETE = 'CONCRETE',
  WOOD = 'WOOD',
  PLASTER = 'PLASTER',
  CARPET = 'CARPET',
  PARQUET = 'PARQUET',
  WINDOW = 'WINDOW',
  LINOLEUM = 'LINOLEUM',
  PLASTER_PERFORATED = 'PLASTER_PERFORATED',
}

export const WallMaterials: MaterialType[] = [
  MaterialType.MASSIVE,
  MaterialType.CONCRETE,
  MaterialType.WOOD,
  MaterialType.PLASTER,
];

export const CeilingMaterials: MaterialType[] = [
  MaterialType.MASSIVE,
  MaterialType.PLASTER,
  MaterialType.CONCRETE,
  MaterialType.WOOD,
  MaterialType.PLASTER_PERFORATED,
];

export const FloorMaterials: MaterialType[] = [
  MaterialType.CONCRETE,
  MaterialType.PARQUET,
  MaterialType.CARPET,
  MaterialType.LINOLEUM,
];
