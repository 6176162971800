import { Expression } from 'mapbox-gl';

// TODO: use typings for property names
export const IS_PROJECT_BUILDING: Expression = [
  'boolean',
  ['coalesce', ['get', 'isProjectBuilding'], ['get', 'user_isProjectBuilding']],
  false,
];

export const IS_PROJECT_WALL: Expression = [
  'boolean',
  ['coalesce', ['get', 'isProjectWall'], ['get', 'user_isProjectWall']],
  false,
];

export const BUILDING_HAS_HEIGHT: Expression = [
  'any',
  ['has', 'heightAboveGroundInMeters'],
  ['has', 'user_heightAboveGroundInMeters'],
  ['has', 'heightStoryLevels'],
  ['has', 'user_heightStoryLevels'],
];

export const IS_IGNORED_IN_EVALUATION: Expression = [
  'boolean',
  ['coalesce', ['get', 'ignoreInEvaluation'], ['get', 'user_ignoreInEvaluation']],
  false,
];

export const IS_ACTIVE_IN_DRAW_MODE: Expression = ['boolean', ['==', ['get', 'active'], 'true'], false];

export const ISO_BAND_SOUND_PRESSURE_LEVEL: Expression = [
  'number',
  ['coalesce', ['get', 'step'], ['get', 'soundPressureLevel']],
  0,
];
