import { Stack, Button } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommerceCharacteristics, SchoolCharacteristics } from '@dbel/shared/types';
import { useGetProjectByIdQuery } from '@dbel/react-commons/api';
import { PropertyField } from '@dbel/react-commons/components';
import { useNavigate } from 'react-router-dom';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { formatCommerceCharacteristics } from '../../../utils/formatters';

const OPENINGHOURS_LOW = 6;
const OPENINGHOURS_HIGH = 22;

export const AssumptionsSummaryPanel = () => {
  const { t } = useTranslation();
  const projectId = useProjectIdFromUrl();
  const navigate = useNavigate();
  const { data: project } = useGetProjectByIdQuery(projectId);

  const handleOnChangeSettingsClicked = useCallback(() => {
    navigate(`/architect/project/${projectId}/settings/info`);
  }, [navigate, projectId]);

  const projectSettings = project.settings;
  const areaType = projectSettings.taLaerm?.defaultAreaType ?? '';

  const projectCharacteristic = useMemo(() => {
    const firstProjectCharacteristic = projectSettings.projectCharacteristics[0] ?? null;
    if (firstProjectCharacteristic.type === 'COMMERCE') {
      return firstProjectCharacteristic as CommerceCharacteristics;
    }
    return firstProjectCharacteristic as SchoolCharacteristics;
  }, [projectSettings.projectCharacteristics]);

  const [nightActivityString, rows] = useMemo(() => {
    if (!projectCharacteristic || !(projectCharacteristic as CommerceCharacteristics).openingHours)
      return [t('common.notAvailable'), 1];

    const commerceCharacteristics = projectCharacteristic as CommerceCharacteristics;
    if (
      commerceCharacteristics.openingHours.weekdays.from >= OPENINGHOURS_LOW &&
      commerceCharacteristics.openingHours.weekdays.to <= OPENINGHOURS_HIGH &&
      !commerceCharacteristics.openingHours.sundays?.to &&
      !commerceCharacteristics.openingHours.sundays?.from
    ) {
      return [t('common.abverbs.no'), 1];
    }
    const [openingHoursWeekdays, openingHoursSunday] = formatCommerceCharacteristics(commerceCharacteristics);
    return [`${openingHoursWeekdays}\n${openingHoursSunday}`, 2];
  }, [projectCharacteristic, t]);

  return (
    <Stack direction="column">
      <PropertyField
        label={t('pages.architect.project.summaryPanel.evaluationBasisLabel')}
        value={t('common.calculationBasis.ISO9613')}
      />
      <PropertyField label={t('common.labels.emissionsApproach')} value={t('common.evaluationGuideline.TA_LEARM')} />
      <PropertyField
        label={t('common.labels.areaClassification')}
        value={t(`common.taLaermArea.types.${areaType}`)}
        textFieldProps={{ multiline: true, maxRows: Infinity }}
      />
      <PropertyField
        label={t('pages.architect.project.summaryPanel.nightActivityLabel')}
        value={nightActivityString}
        textFieldProps={{ multiline: true, rows }}
      />
      <PropertyField
        label={t('common.labels.defaultBuildingHeight')}
        value={`${projectSettings.model.buildings.defaultBuildingHeight}${t('common.units.meter')}`}
      />
      <Button variant="text" onClick={handleOnChangeSettingsClicked}>
        {t('pages.architect.project.summaryPanel.changeSettingsButton')}
      </Button>
    </Stack>
  );
};
