import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Box, Divider, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { useGetAccountByIdQuery, useGetOrganistationsForUserQuery, dBelApi } from '@dbel/react-commons/api';
import { useDebug } from '@dbel/react-commons/components';
import { PropsWithSx } from '@dbel/react-commons/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { ReactComponent as DBelLogoBig } from '../../../icons/dBelLogoBig.svg';
import { useDispatch } from '../../../store/store';
import { BITBUCKET_COMMIT } from '../../../version';
import { PERMISSIONS_READ_ACCOUNT, PERMISSIONS_READ_DBEL_ACCOUNTS } from '../../AuthGuard';
import { MainMenuEntry } from './MainMenuEntry';
import { MainMenuSection } from './MainMenuSection';
import { SubMenuEntry } from './SubMenuEntry';
import { baseConfig } from '../../../config';

export const AppMenu = ({ sx }: PropsWithSx<{}>) => {
  const { t } = useTranslation();
  const debug = useDebug();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const { data: availableAccounts } = useGetAvailableAccountsQuery();
  //const isArchitectRoute = useMatch({ path: '/architect/*' }) !== null;
  //const isTelemetryRoute = useMatch({ path: '/telemetry/*' }) !== null;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user, getTokenForOrganization } = useAuth();

  // we use the user Account name for now
  const { data: myAccount } = useGetAccountByIdQuery(user.accountKey);
  const { data: myAccounts } = useGetOrganistationsForUserQuery(user.id);

  const open = Boolean(anchorEl);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (index: number) => {
    if (myAccounts[index].accountKey === myAccount.accountKey) return;
    setAnchorEl(null);
    navigate(`/projects`);
    await getTokenForOrganization(myAccounts[index].organizationId);

    dispatch(dBelApi.util.invalidateTags(['Project']));
    dispatch(dBelApi.util.invalidateTags(['Subscription']));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canReadAccount = user.permissions.includes(PERMISSIONS_READ_ACCOUNT);
  const canReadDBEL = user.permissions.includes(PERMISSIONS_READ_DBEL_ACCOUNTS);

  return (
    <>
      <Box sx={sx} component="nav">
        <DBelLogoBig sx={{ fontSize: 70, ml: 2 }} />
        <MainMenuSection title={t('pages.menus.appMenu.sections.account')}>
          {myAccounts && myAccount && (
            <>
              <ListItemButton onClick={handleClickListItem} sx={{ flexFlow: 'row-reverse' }}>
                <ListItemIcon>
                  <UnfoldMoreIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontWeight: 'bold' }}>{myAccount?.displayName ?? ''}</Typography>
                </ListItemText>
              </ListItemButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose} variant="selectedMenu" disablePortal>
                {myAccounts.map((account, index) => (
                  <MenuItem
                    key={index}
                    disabled={!account.active}
                    selected={account.accountKey === myAccount.accountKey}
                    onClick={() => handleMenuItemClick(index)}
                  >
                    {account.displayName ?? ''}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </MainMenuSection>
        <Divider />
        <MainMenuSection title={t('pages.menus.appMenu.sections.navigation')}>
          <MainMenuEntry title={t('pages.menus.appMenu.architect.appName')} expanded>
            <SubMenuEntry
              title={t('pages.menus.appMenu.architect.nav.myProjects')}
              navTo="/projects"
              // icon={<LocationOnIcon />}
            />
            <SubMenuEntry
              title={t('pages.menus.appMenu.architect.nav.noiseEmission')}
              navTo="/architect/projects"
              // icon={<LocationOnIcon />}
            />
            <SubMenuEntry
              title={t('pages.menus.appMenu.architect.nav.roomAcoustics')}
              navTo="/roomacoustics/projects"
              // icon={<LocationOnIcon />}
            />
          </MainMenuEntry>
          {/*
          <MainMenuEntry title={t('pages.menus.project.appMenu.telemetry.appName')} expanded={isTelemetryRoute}>
            <SubMenuEntry
              title={t('pages.menus..telemetry.nav.locations')}
              navTo="/telemetry/locations"
              // icon={<LocationOnIcon />}
            />
            <SubMenuEntry
              title={t('pages.menus.project.appMenu.telemetry.nav.devices')}
              navTo="/telemetry/devices"
              // icon={<MicIcon />}
            />
            <SubMenuEntry
              title={t('pages.menus.project.appMenu.telemetry.nav.reports')}
              navTo="/telemetry/reports"
              // icon={<TextSnippetIcon />}
            />
          </MainMenuEntry>
               */}
        </MainMenuSection>
        <Divider />
        <MainMenuSection title={t('pages.menus.appMenu.sections.help')}>
          {/*<SubMenuEntry
            title={t('pages.menus.project.appMenu.help.nav.documentation')}
            navTo="/architect/projects"
              />*/}
          <SubMenuEntry title={t('pages.menus.appMenu.help.nav.contact')} navTo="mailto:bundschuh@woelfel.de" />
        </MainMenuSection>
        <Divider />
        {(canReadAccount || canReadDBEL) && (
          <MainMenuSection title={t('pages.menus.appMenu.sections.administration')}>
            {canReadAccount && (
              <SubMenuEntry title={t('pages.menus.appMenu.administration.nav.accountSettings')} navTo="/account" />
            )}
            {canReadDBEL && (
              <>
                <SubMenuEntry
                  title={t('pages.menus.appMenu.administration.nav.platformAdministration')}
                  navTo="/admin"
                />

                <ListItemButton
                  component={RouterLink}
                  onClick={(event) => {
                    event.preventDefault();
                    const navTo = `https://www.${baseConfig.domain}/hvac/index.html?token=${user.token}`;
                    window.location.href = navTo;
                  }}
                  to=""
                >
                  <ListItemText>HVAC Widget</ListItemText>
                </ListItemButton>
              </>
            )}
          </MainMenuSection>
        )}
        {debug.enabled && (
          <>
            <Divider />
            <Typography sx={{ fontSize: 10, color: 'gray', opacity: 0.4, ml: 2 }}>{BITBUCKET_COMMIT}</Typography>
          </>
        )}
      </Box>
    </>
  );
};
