import { MapBoxOverlayContainer } from '@dbel/react-commons/components';
import { PropsWithSx } from '@dbel/react-commons/types';
import { ToggleButtonGroup } from '@mui/material';
import { Children, cloneElement, isValidElement, MouseEvent, PropsWithChildren, useCallback } from 'react';

export interface ModeSwitcherProps<T> {
  value: T;
  onChange: (mode: T) => void;
  itemDivider?: boolean;
}

export const ModeSwitcher = <T,>({
  children,
  sx,
  value,
  itemDivider,
  onChange,
}: PropsWithSx<PropsWithChildren<ModeSwitcherProps<T>>>) => {
  const handleOnChangeMapMode = useCallback(
    (_: MouseEvent<HTMLElement>, newMode: T) => {
      onChange(newMode);
    },
    [onChange]
  );

  return (
    <>
      <MapBoxOverlayContainer
        sx={{
          display: 'flex',
          ...sx,
        }}
      >
        <ToggleButtonGroup onChange={handleOnChangeMapMode} exclusive size="small" value={value}>
          {Children.toArray(children).map((child, index) =>
            isValidElement(child)
              ? cloneElement(child as React.ReactElement<any>, {
                  itemDivider: itemDivider && index < Children.count(children) - 1,
                })
              : child
          )}
        </ToggleButtonGroup>
      </MapBoxOverlayContainer>
    </>
  );
};
