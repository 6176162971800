import { useMemo } from 'react';
import { useGetProductSubscriptionQuery } from '@dbel/react-commons/api';
import { MapBoxMap } from '@dbel/react-commons/components';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import { MapBoxWrapper } from '../../../components/mapbox/MapBoxWrapper';
import { MAIN_MENU_HEIGHT } from '../../AllDBelPagesLayout';
import { BANNER_HEIGHT } from '../../ProductSubscriptionBanner';
import { MAP_BOX_OPTIONS } from '../../../mapBoxOptions';

export const AllMapBasedPagesLayout = () => {
  const { data: productSubscriptions } = useGetProductSubscriptionQuery();

  const bannerHeight = useMemo(() => {
    if (productSubscriptions === undefined) return 0;

    const productSubscription = productSubscriptions[0];
    if (productSubscription?.status === 'trialing') return BANNER_HEIGHT;
    return 0;
  }, [productSubscriptions]);

  return (
    <>
      <Box
        sx={{
          flex: '1 1 100%',
          display: 'flex',
          position: 'relative',
          mt: `-${MAIN_MENU_HEIGHT}px`,
          height: `calc(100vh - ${bannerHeight}px)`,
        }}
      >
        <MapBoxMap mapBoxOptions={MAP_BOX_OPTIONS} sx={{ height: '100%', width: '100%' }}>
          <MapBoxWrapper>
            <Outlet />
          </MapBoxWrapper>
        </MapBoxMap>
      </Box>
    </>
  );
};
