export interface Auth0ConfigOptions {
  clientId: string;
  domain: string;
  scope: string;
  audience: string;
  baseUrl: string;
}

export const auth0Config: Auth0ConfigOptions = {
  clientId: import.meta.env['VITE_AUTH0_CLIENT_ID'],
  domain: import.meta.env['VITE_AUTH0_DOMAIN'],
  scope:
    'openid profile email read:projects write:projects read:account write:account read:debug read:dbel-accounts write:dbel-accounts read:telemetry-devices write:telemetry-devices',
  audience: import.meta.env['VITE_AUTH0_AUDIENCE'],
  baseUrl: import.meta.env['VITE_AUTH0_BASE_URL'],
};

export interface MapBoxConfigOptions {
  api: { accessToken: string; endpointBaseUrl: string };
}

export const mapBoxConfig: MapBoxConfigOptions = {
  api: {
    accessToken: import.meta.env['VITE_MAPBOX_API_ACCESS_TOKEN'],
    endpointBaseUrl: import.meta.env['VITE_MAPBOX_API_BASE_URL'],
  },
};

export interface StripePricingTableOptions {
  productKey: string;
  priceKey: string;
}

export const stripeConfig: StripePricingTableOptions = {
  productKey: import.meta.env['VITE_STRIPE_PRODUCT_KEY'],
  priceKey: import.meta.env['VITE_STRIPE_PRICE_KEY'],
};

export interface BaseConfigOptions {
  domain: string;
}

export const baseConfig: BaseConfigOptions = {
  domain: import.meta.env['VITE_BASE_DOMAIN'],
};

export interface ApiConfigOptions {
  endpointBaseUrl: string;
}

export const apiConfig: ApiConfigOptions = {
  endpointBaseUrl: import.meta.env['VITE_API_ENDPOINT_BASE_URL'],
};

export interface WebSocketConfigOptions {
  endpointBaseUrl: string;
}

export const websocketConfig: WebSocketConfigOptions = {
  endpointBaseUrl: import.meta.env['VITE_WEBSERVICE_ENDPOINT_BASE_URL'],
};

export interface SentryConfigOptions {
  baseUrl: string;
  env: 'test' | 'prod';
  sampleRate: number;
}

export const sentryConfig: SentryConfigOptions = {
  baseUrl: import.meta.env['VITE_SENTRY_BASE_URL'],
  env: import.meta.env['VITE_SENTRY_ENV'],
  sampleRate: import.meta.env['VITE_SENTRY_SAMPLE_RATE'],
};
