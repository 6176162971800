import { Accordion, Box, IconButton, Stack, Typography } from '@mui/material';
import {
  useGetNoiseProtectionAreaReceiversQuery,
  useGetProjectByIdQuery,
  useGetBuildingsQuery,
} from '@dbel/react-commons/api';
import { Building, NoiseProtectionAreaReceiver } from '@dbel/shared/types';
import { MapBoxOverlayContainer, AccordionHeader, AccordionContent, ColoredIcon } from '@dbel/react-commons/components';
import { taLaermCalculatorService } from '@dbel/react-commons/hooks';
import { Criticality, CriticalitySeverity, CriticalityTimeSpan } from '@dbel/react-commons/types';
import i18n from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { ReactComponent as ResultsSummaryIcon } from '../../../icons/ResultsSummary.svg';
import { RootState, useSelector, useDispatch } from '../../../store/store';
import { CriticalityTimeSpanSwitcher } from '../../criticality/CriticalityTimeSpanSwitcher';
import { TA_LAERM_COLOR_CRITICALITY_UNKNOWN } from '../layers/styles/colors/colors';
import { AssumptionsSummaryPanel } from './AssumptionsSummaryPanel';
import { setNoiseEmissionsCriticalityTimeSpan } from '../../../store/slices/map';
import { TaLaermPresets } from '../../../NoiseProtectionEvaluationPresets';

type SummaryColorPreset = Record<CriticalitySeverity, string>;

type SummaryColorPresets = {
  iconAndText: SummaryColorPreset;
  headerBackground: SummaryColorPreset;
};

const SUMMARY_COLORS: SummaryColorPresets = {
  iconAndText: {
    UNKNOWN: '#2C2E42',
    HIGH: '#F44336',
    MEDIUM: '#FF9800',
    LOW: '#4CAF50',
  },
  headerBackground: {
    UNKNOWN: '#83848B',
    HIGH: '#FFEEED',
    MEDIUM: '#FFF3E2',
    LOW: '#E6FCE7',
  },
};

const getEvaluationSummaryTextForCriticality = (criticality: Criticality): string =>
  i18n.t(`evaluationMessages.summary.${criticality.severity}`);

export const ResultsSummary = () => {
  const { t } = useTranslation();
  const [arePanelsOpen, setArePanelsOpen] = useState(true);
  const projectId = useProjectIdFromUrl();
  const dispatch = useDispatch();

  const [criticality, setCriticality] = useState<Criticality | undefined>(undefined);

  const noiseEmissionsCriticalityTimeSpan = useSelector(
    (state: RootState) => state.map.noiseEmissionsCriticalityTimeSpan
  );

  const { data: noiseProtectionAreaReceivers } = useGetNoiseProtectionAreaReceiversQuery(projectId);
  const { data: project } = useGetProjectByIdQuery(projectId);
  const { data: buildings } = useGetBuildingsQuery(projectId);

  const handleClickSummaryIcon = useCallback(() => {
    setArePanelsOpen(!arePanelsOpen);
  }, [arePanelsOpen]);

  const criticalityTimeSpanChanged = useCallback(
    (criticalityTimeSpan: CriticalityTimeSpan) => {
      dispatch(setNoiseEmissionsCriticalityTimeSpan(criticalityTimeSpan));
    },
    [dispatch]
  );

  useMemo(() => {
    if (!noiseProtectionAreaReceivers || noiseProtectionAreaReceivers.length === 0) {
      setCriticality(undefined);
      return;
    }

    let highestCriticality: Criticality = {
      period: 'day',
      severity: 'UNKNOWN',
      offset: 0,
      differenceToLowCriticality: 0,
      evaluatedDbLevel: 0,
      color: TA_LAERM_COLOR_CRITICALITY_UNKNOWN,
    };

    noiseProtectionAreaReceivers.forEach((receiver: NoiseProtectionAreaReceiver) => {
      const buildingForReceiver = buildings.find(
        (b: Building) => b.properties.key === receiver.properties.relations.buildingKey
      );

      // TODO: ignore receiver which do not have an associated valid building for now (remove this later if BE returns valid data?)
      if (!buildingForReceiver) return;

      // don't display receivers whose buildings are ignored
      if (buildingForReceiver.properties.ignoreInEvaluation) return;

      const taLaermAreaType =
        buildingForReceiver.properties.taLaerm?.areaType ?? project.settings.taLaerm?.defaultAreaType;

      const evaluationResult = taLaermCalculatorService.evaluateReceiver(
        TaLaermPresets[taLaermAreaType].fassade,
        [receiver],
        noiseEmissionsCriticalityTimeSpan
      );

      const evaluationSeverity = evaluationResult?.highestCriticality?.severity;

      if (evaluationSeverity === 'LOW' && highestCriticality.severity === 'UNKNOWN') {
        highestCriticality = evaluationResult?.highestCriticality;
      }

      if (
        evaluationSeverity === 'MEDIUM' &&
        (highestCriticality.severity === 'LOW' || highestCriticality.severity === 'UNKNOWN')
      ) {
        highestCriticality = evaluationResult?.highestCriticality;
      }

      if (
        evaluationSeverity === 'HIGH' &&
        (highestCriticality.severity === 'LOW' ||
          highestCriticality.severity === 'MEDIUM' ||
          highestCriticality.severity === 'UNKNOWN')
      ) {
        highestCriticality = evaluationResult?.highestCriticality;
      }
    });
    setCriticality(highestCriticality);
  }, [
    buildings,
    noiseEmissionsCriticalityTimeSpan,
    project.settings.taLaerm?.defaultAreaType,
    noiseProtectionAreaReceivers,
  ]);

  return (
    <>
      {criticality && (
        <Stack
          direction="column"
          spacing={2}
          sx={{ position: 'absolute', top: 60, left: 10, maxWidth: 300, minWidth: 300 }}
        >
          <Box>
            <IconButton onClick={handleClickSummaryIcon} sx={{ ml: '-6px' }}>
              <ColoredIcon
                color={SUMMARY_COLORS.iconAndText[criticality.severity]}
                icon={<ResultsSummaryIcon htmlColor="white" />}
              />
            </IconButton>
          </Box>
          {arePanelsOpen && (
            <Stack direction="column" spacing={1}>
              <MapBoxOverlayContainer>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ backgroundColor: SUMMARY_COLORS.headerBackground[criticality.severity], p: 1 }}
                  color={SUMMARY_COLORS.iconAndText[criticality.severity]}
                >
                  {getEvaluationSummaryTextForCriticality(criticality)}
                </Typography>
                <Box sx={{ p: 1 }}>
                  <Accordion defaultExpanded>
                    <AccordionHeader>{t('pages.architect.project.panels.resultsAssumptionPanelTitle')}</AccordionHeader>
                    <AccordionContent>
                      <AssumptionsSummaryPanel />
                    </AccordionContent>
                  </Accordion>
                </Box>
              </MapBoxOverlayContainer>
              <MapBoxOverlayContainer sx={{ overflow: 'hidden' }}>
                <Box sx={{ p: 1 }}>
                  <Accordion defaultExpanded>
                    <AccordionHeader>{t('pages.architect.project.panels.criticalElementsTitle')}</AccordionHeader>
                    <AccordionContent>
                      <CriticalityTimeSpanSwitcher
                        criticalityTimeSpan={noiseEmissionsCriticalityTimeSpan}
                        onChange={criticalityTimeSpanChanged}
                      />
                    </AccordionContent>
                  </Accordion>
                </Box>
              </MapBoxOverlayContainer>
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
};
