import { useDebug } from '@dbel/react-commons/components';
import { uniqBy } from 'lodash';
import { useState } from 'react';
import { InfluencerEvaluation, Receiver, ReceiverInfluencer, TaLaermPeriod } from '@dbel/shared/types';

export interface ReceiverToolsType {
  getInfluencersForReceiver: (receiver: Receiver, period: TaLaermPeriod) => ReceiverInfluencer[];
  getAllInfluencersForReceiver: (receiver: Receiver) => ReceiverInfluencer[];
}

export const useReceiverTools = () => {
  const debug = useDebug();
  const influencerKey: InfluencerEvaluation['key'] = 'influencer';
  const [tools] = useState<ReceiverToolsType>({
    getInfluencersForReceiver(receiver: Receiver, period: TaLaermPeriod): ReceiverInfluencer[] {
      const influencers = receiver.properties.evaluations?.[influencerKey] as InfluencerEvaluation['resultType'];
      if (!influencers) return [];
      // TODO this should not happen there is sometimes an influencer without his period.
      if (!influencers[period]) return [];
      if (debug.enabled) return influencers[period];
      // return all influencers with >0 db(A) rating
      return influencers[period].filter((influencer) => influencer.ratingLevel >= 0);
    },
    getAllInfluencersForReceiver(receiver: Receiver): ReceiverInfluencer[] {
      const influencers = receiver.properties.evaluations?.[influencerKey] as InfluencerEvaluation['resultType'];

      if (!influencers) return [];

      return uniqBy(Object.values(influencers).flat(), (influencer) => influencer.soundSourceKey);
    },
  });

  return tools;
};
