import { LayerIds, SourceIds, MapBoxMapOptions } from '@dbel/react-commons/types';
import { formatLanguage } from '@dbel/react-commons/components';
import i18next from 'i18next';
import { LngLatBoundsLike } from 'mapbox-gl';
import { mapBoxConfig } from './config';
import { LayerSymbols } from './components/mapbox/layers/styles/symbols/symbols';

// whole germany
// bbox from here: https://gist.github.com/graydon/11198540

const INITIAL_MAP_ZOOM_BOUNDING_BOX: LngLatBoundsLike = [5.98865807458, 47.3024876979, 15.0169958839, 54.983104153];
const { language } = i18next;
export const layerIds: LayerIds = {
  Buildings: 'buildings',
  BuildingsBackground: 'buildings-background',
  BuildingsOutline: 'buildings-outline',
  Walls: 'walls',
  SoundSourcesTypePoint: 'sound-sources-point',
  SoundSourcesTypePointSelected: 'sound-sources-point-selected',
  SoundSourcesTypePointHighlighted: 'sound-sources-point-highlighted',
  SoundSourcesTypeLine: 'sound-sources-line',
  SoundSourcesTypePolygon: 'sound-sources-polygon',
  SoundSourcesTypePolygonOutline: 'sound-sources-polygon-outline',
  SoundSourcesCenterOfMassPoints: 'sound-sources-center-of-mass-points',
  SoundSourcesCenterOfMassPointsSelected: 'sound-sources-center-of-mass-points-selected',
  SoundSourcesCenterOfMassPointsHighlighted: 'sound-sources-center-of-mass-points-highlighted',
  Receivers: 'receivers',
  ProjectPlan: 'project-plan',
  OutsideNoiseIsoBands: 'outside-noise-iso-bands',
  OutsideNoiseGridPoints: 'outside-noise-iso-bands-grid-points',
};

export const sourceIds: SourceIds = {
  Buildings: 'buildings',
  Buildings3D: 'buildings3D',
  Walls: 'walls',
  SoundSources: 'sound-sources',
  SoundSourcesCenterOfMassPoints: 'sound-sources-center-of-mass-points',
  Receivers: 'receivers',
  ProjectPlan: 'project-plan',
  OutsideNoiseIsoBands: 'outside-noise-iso-bands',
  OutsideNoiseGridPoints: 'outside-noise-iso-bands-grid-points',
};

export const MAP_BOX_OPTIONS: MapBoxMapOptions = {
  accessToken: mapBoxConfig.api.accessToken,
  logoPosition: 'bottom-right',
  bounds: INITIAL_MAP_ZOOM_BOUNDING_BOX,
  language: formatLanguage(language),
  symbolImagesForLayers: LayerSymbols,
  layerIds,
};
