import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { MapBoxMapToolbarGroup, MapBoxMapToolbarItem } from '@dbel/react-commons/components';
import { ToolbarTool } from '@dbel/react-commons/types';
import { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useToolbars } from '../../../contexts/ToolbarContext';
import { ReactComponent as DrawPolygonIcon } from '../../../icons/toolbars/DrawPolygon.svg';
import { resetMapDrawMode, switchToMapDrawMode } from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';
import { PROJECT_AREA_FEATURE_ID } from '../layers/ProjectAreaLayer';

type ToolbarToolId = 'edit-area' | 'scale-rotate';

export const CreateProjectToolbar = () => {
  const dispatch = useDispatch();
  const { projectToolbarRef } = useToolbars();

  const { mode: currentDrawMode } = useSelector((state: RootState) => state.map.mapDrawMode);

  const activeTool = useMemo<ToolbarTool<ToolbarToolId>>(() => {
    switch (currentDrawMode) {
      case 'direct_select':
        return 'edit-area';
      case 'tx_poly':
        return 'scale-rotate';
      default:
        return undefined;
    }
  }, [currentDrawMode]);

  const handleChangeTool = useCallback(
    (newTool: ToolbarTool<ToolbarToolId>) => {
      switch (newTool) {
        case 'scale-rotate': {
          dispatch(
            switchToMapDrawMode({
              mode: 'tx_poly',
              selectedFeatureId: PROJECT_AREA_FEATURE_ID,
              options: {
                canTrash: false,
                canScale: true,
                canRotate: true,
                singleRotationPoint: true,
                canSelectFeatures: true,
              },
            })
          );
          break;
        }
        case 'edit-area': {
          dispatch(
            switchToMapDrawMode({
              mode: 'direct_select',
              selectedFeatureId: PROJECT_AREA_FEATURE_ID,
            })
          );
          break;
        }
        default: {
          dispatch(resetMapDrawMode());
        }
      }
    },
    [dispatch]
  );

  return createPortal(
    <>
      <MapBoxMapToolbarGroup<ToolbarToolId> activeTool={activeTool} onChangeTool={handleChangeTool}>
        <MapBoxMapToolbarItem<ToolbarToolId> value="scale-rotate" icon={<AspectRatioIcon />} />
        <MapBoxMapToolbarItem<ToolbarToolId> value="edit-area" icon={<DrawPolygonIcon />} />
      </MapBoxMapToolbarGroup>
    </>,
    projectToolbarRef.current
  );
};
