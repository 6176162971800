import { Feature, LineString, Polygon } from 'geojson';
import { multiPolygon, lineString } from '@turf/turf';
import { MapMouseEvent } from 'mapbox-gl';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  useCreateBuildingMutation,
  useUpdateBuildingMutation,
  useDeleteBuildingMutation,
  useCreateWallMutation,
  useUpdateWallMutation,
  CreateWallData,
  useGetBuildingsQuery,
  useGetWallsQuery,
} from '@dbel/react-commons/api';
import { useMapBox, MapBoxMapSource, MapBoxMapLayer } from '@dbel/react-commons/components';
import {
  Building,
  Wall,
  BuildingProperties,
  DBELFeatureType,
  WallProperties,
  isBuilding,
  isWall,
  DEFAULT_PropagationIso9613Settings,
} from '@dbel/shared/types';
import { calculateBuildingHeight } from '@dbel/shared/util';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { closeProjectItemPropertiesPanel, openProjectItemPropertiesPanel } from '../../../store/slices/map';
import { RootState, useSelector } from '../../../store/store';
import { BuildingsToolbar } from '../toolbars/BuildingsToolbar';
import MapBoxMapDrawLayer from './MapBoxMapDrawLayer';
import {
  PROJECT_BUILDINGS_DRAW_LAYER_STYLE,
  SURROUNDING_BUILDINGS_DRAW_LAYER_STYLE,
} from './styles/BuildingsDrawLayerStyles';
import {
  BUILDINGS_3D_LAYER_STYLE,
  BUILDINGS_LAYER_STYLE,
  BUILDINGS_LAYER_STYLE_BACKGROUND,
  BUILDINGS_LAYER_STYLE_OUTLINE,
} from './styles/BuildingsLayerStyles';
import {
  BUILDING_PROJECT_3D_COLOR,
  BUILDING_PROJECT_3D_SELECTED_COLOR,
  BUILDING_PROJECT_COLOR,
  BUILDING_SURROUNDING_3D_COLOR,
  BUILDING_SURROUNDING_3D_SELECTED_COLOR,
} from './styles/colors/colors';
import { flattenBuildings } from './styles/utils';
import { WALLS_3D_LAYER_STYLE, WALLS_LAYER_STYLE } from './styles/WallsLayerStyles';
import { sourceIds, layerIds } from '../../../mapBoxOptions';

export interface BuildingsLayerProps {
  isEditable?: boolean;
  drawProjectBuildings?: boolean;
  buildings?: Building[];
  semiTransparentBuildings?: boolean;
  surroundingBuildingsClickable?: boolean;
}

const BuildingsAndWallsLayer = ({
  drawProjectBuildings,
  buildings,
  isEditable = true,
  semiTransparentBuildings = true,
  surroundingBuildingsClickable = true,
}: BuildingsLayerProps) => {
  const dispatch = useDispatch();
  const { mapBox } = useMapBox();
  const projectId = useProjectIdFromUrl();

  const [createBuilding] = useCreateBuildingMutation();
  const [updateBuilding] = useUpdateBuildingMutation();
  const [deleteBuilding] = useDeleteBuildingMutation();

  const [createWall] = useCreateWallMutation();
  const [updateWall] = useUpdateWallMutation();

  const { data: buildingsFromApi } = useGetBuildingsQuery(projectId ?? skipToken);

  const { data: wallsForProject } = useGetWallsQuery(projectId ?? skipToken);

  const projectItemForPropertiesPanel = useSelector((state: RootState) => state.map.projectItemForPropertiesPanel);
  const projectPlanEditable = useSelector((state: RootState) => state.map.projectPlanEditable);
  const scaleModeProjectPlan = useSelector((state: RootState) => state.map.scaleModeProjectPlan);
  const viewMode = useSelector((state: RootState) => state.map.viewMode);

  // flatten buildings for map box
  const buildingsForProject = useMemo(() => {
    if (!buildingsFromApi) return [];
    return flattenBuildings(buildingsFromApi);
  }, [buildingsFromApi]);

  // filter out the right buildings
  const buildingsToShow = useMemo(() => {
    if (buildings) return buildings;
    if (!isEditable) return buildingsForProject;

    return (
      buildingsForProject?.filter(
        (building) =>
          (drawProjectBuildings && !building.properties.isProjectBuilding) ||
          (!drawProjectBuildings && building.properties.isProjectBuilding)
      ) || []
    );
  }, [buildings, buildingsForProject, drawProjectBuildings, isEditable]);

  // filter out the walls
  const wallsToShow = useMemo(() => {
    if (!wallsForProject) return [];
    if (!isEditable) return wallsForProject;

    const filteredWalls =
      wallsForProject?.filter(
        (wall: Wall) =>
          (drawProjectBuildings && !wall.properties['isProjectWall']) ||
          (!drawProjectBuildings && wall.properties['isProjectWall'])
      ) || [];
    return filteredWalls;
  }, [drawProjectBuildings, isEditable, wallsForProject]);

  // filter the editable items
  const featuresToEdit = useMemo(() => {
    if (!buildingsForProject) return [];
    if (!isEditable) return buildingsForProject;

    const filteredBuildings = buildingsForProject.filter(
      (building) =>
        (drawProjectBuildings && building.properties.isProjectBuilding) ||
        (!drawProjectBuildings && !building.properties.isProjectBuilding)
    );

    const filteredWalls =
      wallsForProject?.filter(
        (wall: Wall) =>
          (drawProjectBuildings && wall.properties.isProjectWall) ||
          (!drawProjectBuildings && !wall.properties.isProjectWall)
      ) || [];
    return [...filteredBuildings, ...filteredWalls];
  }, [buildingsForProject, drawProjectBuildings, isEditable, wallsForProject]);

  // handlers: create building
  const handleCreateBuilding = useCallback(
    (polygon: Polygon) => {
      const buildingToCreateProperties: Omit<BuildingProperties, 'key'> = {
        type: DBELFeatureType.Building,
        source: 'USER',
      };

      buildingToCreateProperties.isProjectBuilding = drawProjectBuildings;

      const building = multiPolygon<typeof buildingToCreateProperties>(
        [polygon.coordinates],
        buildingToCreateProperties
      );

      createBuilding({
        projectId,
        building,
      })
        .unwrap()
        .then((createdBuilding: Building) => {
          dispatch(openProjectItemPropertiesPanel(createdBuilding));
        });
    },
    [createBuilding, dispatch, drawProjectBuildings, projectId]
  );

  // handlers: create wall
  const handleCreateWall = useCallback(
    (wall: LineString) => {
      const wallToCreateProperties: Omit<WallProperties, 'key'> = {
        type: DBELFeatureType.Wall,
        height: 2, //
      };

      wallToCreateProperties.isProjectWall = drawProjectBuildings;

      const wallToCreate = lineString<typeof wallToCreateProperties>(wall.coordinates, wallToCreateProperties);

      createWall({
        projectId,
        wallToCreate,
      } as CreateWallData)
        .unwrap()
        .then((createdWall: Wall) => {
          dispatch(openProjectItemPropertiesPanel(createdWall));
        });
    },
    [createWall, dispatch, drawProjectBuildings, projectId]
  );

  // handlers: create feature
  const handleOnCreateFeature = useCallback(
    (feature: Feature) => {
      // TODO: use type guard here instead
      if (feature.geometry.type === 'Polygon') {
        handleCreateBuilding(feature.geometry);
      } else if (feature.geometry.type === 'LineString') {
        handleCreateWall(feature.geometry);
      }
    },
    [handleCreateBuilding, handleCreateWall]
  );

  // handlers: update feature
  const handleOnUpdateFeature = useCallback(
    (feature: Feature) => {
      if (isBuilding(feature)) {
        updateBuilding({ projectId, building: feature });
      }
      if (isWall(feature)) {
        updateWall({ projectId, wall: feature });
      }
    },
    [projectId, updateBuilding, updateWall]
  );

  // handlers: delete feature
  const handleOnDeleteFeature = useCallback(
    (feature: Feature) => {
      deleteBuilding({ projectId, buildingId: String(feature.id) });
    },
    [projectId, deleteBuilding]
  );

  // handlers: deselect item on Map
  const deselectItem = useCallback(() => {
    // Deselect previous selected Item
    if (projectItemForPropertiesPanel) {
      mapBox.setFeatureState(
        { id: projectItemForPropertiesPanel.id, source: sourceIds.Buildings3D },
        {
          fillColor: projectItemForPropertiesPanel.properties['isProjectBuilding']
            ? BUILDING_PROJECT_COLOR
            : BUILDING_SURROUNDING_3D_COLOR,
        }
      );
    }
  }, [mapBox, projectItemForPropertiesPanel]);

  // handlers: select item on Map
  const handleClickMap = useCallback(() => {
    deselectItem();
    dispatch(closeProjectItemPropertiesPanel());
  }, [deselectItem, dispatch]);

  // handlers: select item on Map
  const handleClickFeatures = useCallback(
    (_: MapMouseEvent, __: string, featureIds: string[]) => {
      deselectItem();
      const [clickedBuildingId] = featureIds;
      const foundBuildingById = featuresToEdit.find((building) => building.id === clickedBuildingId);
      dispatch(openProjectItemPropertiesPanel(foundBuildingById));
    },
    [deselectItem, featuresToEdit, dispatch]
  );

  // enrich the featureState with color & height for 3d
  useEffect(() => {
    if (viewMode === '2D') return;
    featuresToEdit.forEach((building) => {
      if (!isBuilding(building)) return;
      const height = calculateBuildingHeight(building, DEFAULT_PropagationIso9613Settings.barriers);

      mapBox.setFeatureState(
        { id: building.id, source: sourceIds.Buildings3D },
        {
          fillColor: building.properties.isProjectBuilding ? BUILDING_PROJECT_3D_COLOR : BUILDING_SURROUNDING_3D_COLOR,
          height: height - 0.0001, // somehow some builings are not extruded. if i subract a little bit the building is shown
        }
      );
    });
  }, [buildingsToShow, featuresToEdit, mapBox, viewMode]);

  // when there is a project item, select it in 3d viewMode
  useEffect(() => {
    if (!projectItemForPropertiesPanel) return;

    const isProjectBuilding = !!projectItemForPropertiesPanel.properties['isProjectBuilding'];

    mapBox.setFeatureState(
      { id: projectItemForPropertiesPanel.id, source: sourceIds.Buildings3D },
      {
        fillColor: isProjectBuilding ? BUILDING_PROJECT_3D_SELECTED_COLOR : BUILDING_SURROUNDING_3D_SELECTED_COLOR,
      }
    );
  }, [projectItemForPropertiesPanel, mapBox, viewMode, dispatch, featuresToEdit]);

  // remove the enrichments
  useEffect(
    () => () => {
      mapBox.removeFeatureState({ source: sourceIds.Buildings });
    },
    [mapBox]
  );

  // handle visibility when the buildings do not need be transparant
  useEffect(() => {
    mapBox.setLayoutProperty(layerIds.BuildingsBackground, 'visibility', semiTransparentBuildings ? 'none' : 'visible');
  }, [isEditable, mapBox, semiTransparentBuildings]);

  return (
    <>
      <MapBoxMapSource id={sourceIds.Buildings} data={buildings ?? buildingsToShow} />
      <MapBoxMapSource id={sourceIds.Buildings3D} data={featuresToEdit} />

      <MapBoxMapLayer
        id={layerIds.BuildingsBackground}
        sourceId={sourceIds.Buildings}
        layerStyle={BUILDINGS_LAYER_STYLE_BACKGROUND}
      />

      {viewMode !== '2D' && (
        <>
          <MapBoxMapLayer
            id={layerIds.BuildingsOutline}
            sourceId={sourceIds.Buildings}
            layerStyle={BUILDINGS_LAYER_STYLE_OUTLINE}
          />
          {surroundingBuildingsClickable && (
            <MapBoxMapLayer
              id={layerIds.Buildings}
              sourceId={sourceIds.Buildings3D}
              layerStyle={BUILDINGS_3D_LAYER_STYLE}
              onClickFeatures={handleClickFeatures}
              onClickMap={handleClickMap}
              cursorOnHover="pointer"
            />
          )}
          {!surroundingBuildingsClickable && (
            <MapBoxMapLayer
              id={layerIds.Buildings}
              sourceId={sourceIds.Buildings3D}
              layerStyle={BUILDINGS_3D_LAYER_STYLE}
            />
          )}
        </>
      )}

      {viewMode === '2D' && (
        <>
          <MapBoxMapLayer
            id={layerIds.BuildingsOutline}
            sourceId={sourceIds.Buildings}
            layerStyle={BUILDINGS_LAYER_STYLE_OUTLINE}
          />
          <MapBoxMapLayer id={layerIds.Buildings} sourceId={sourceIds.Buildings} layerStyle={BUILDINGS_LAYER_STYLE} />
        </>
      )}

      <MapBoxMapSource id={sourceIds.Walls} data={wallsToShow} />
      <MapBoxMapLayer
        id={layerIds.Walls}
        sourceId={sourceIds.Walls}
        layerStyle={viewMode === '2D' ? WALLS_LAYER_STYLE : WALLS_3D_LAYER_STYLE}
      />

      {!projectPlanEditable && !scaleModeProjectPlan && isEditable && viewMode === '2D' && (
        <MapBoxMapDrawLayer
          data={featuresToEdit}
          layerStyle={
            drawProjectBuildings ? PROJECT_BUILDINGS_DRAW_LAYER_STYLE : SURROUNDING_BUILDINGS_DRAW_LAYER_STYLE
          }
          selectedFeature={projectItemForPropertiesPanel}
          onCreateFeature={handleOnCreateFeature}
          onUpdateFeature={handleOnUpdateFeature}
          onDeleteFeature={handleOnDeleteFeature}
        />
      )}

      {isEditable && viewMode === '2D' && (
        <BuildingsToolbar
          hideUploadPlanTool={!drawProjectBuildings}
          hideEditPlanTool={!drawProjectBuildings}
          hideScalePlanTool={!drawProjectBuildings}
        />
      )}
    </>
  );
};

export default BuildingsAndWallsLayer;
