import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature } from 'geojson';
import { ProjectAppMode } from '@dbel/shared/types';
import { CriticalityTimeSpan, SoundSourceToolId, ViewMode } from '@dbel/react-commons/types';
import { MapBoxDrawModes, MapBoxDrawSimpleSelectMode } from '../../components/mapbox/types';

const SLICE_PREFIX = 'map';

export type MapMode = 'CREATE_PROJECT' | 'MAP_SURROUNDING' | 'PROJECT_BUILDINGS' | 'SOUND_SOURCES' | 'RESULTS';

const MAPBOX_DRAW_DEFAULT_MODE: MapBoxDrawSimpleSelectMode = { mode: 'simple_select', selectedFeatureIds: [] };

interface MapState {
  appMode: ProjectAppMode;
  mapMode?: MapMode;
  viewMode: ViewMode;
  mapDrawMode: MapBoxDrawModes;
  activeSoundSourceDrawTool: SoundSourceToolId;
  projectItemForPropertiesPanel?: Feature;
  focusedReceiverIdInPropertiesPanel?: string | undefined;
  projectPlanEditable: boolean;
  uploadProjectPlanDialogOpen: boolean;
  scaleModeProjectPlan: boolean;
  outsideNoiseCriticalityTimeSpan: CriticalityTimeSpan;
  noiseEmissionsCriticalityTimeSpan: CriticalityTimeSpan;
  currentLiveEditedMapBoxDrawFeatureId?: string;
}

const initialState: MapState = {
  appMode: 'OUTSIDE_NOISE',
  activeSoundSourceDrawTool: undefined,
  mapDrawMode: { ...MAPBOX_DRAW_DEFAULT_MODE },
  projectPlanEditable: false,
  scaleModeProjectPlan: false,
  uploadProjectPlanDialogOpen: false,
  outsideNoiseCriticalityTimeSpan: 'DAY',
  noiseEmissionsCriticalityTimeSpan: 'OVERALL',
  viewMode: '2D',
};

const slice = createSlice({
  name: SLICE_PREFIX,
  initialState,
  reducers: {
    resetState: () => initialState,
    setMapMode: (state, action: PayloadAction<MapMode>) => {
      state.activeSoundSourceDrawTool = undefined;
      state.mapDrawMode = { ...MAPBOX_DRAW_DEFAULT_MODE };
      state.focusedReceiverIdInPropertiesPanel = undefined;
      state.projectItemForPropertiesPanel = undefined;
      state.projectPlanEditable = false;
      state.scaleModeProjectPlan = false;
      state.mapMode = action.payload;
    },
    setAppMode: (state, action: PayloadAction<ProjectAppMode>) => {
      state.activeSoundSourceDrawTool = undefined;
      state.mapDrawMode = { ...MAPBOX_DRAW_DEFAULT_MODE };
      state.focusedReceiverIdInPropertiesPanel = undefined;
      state.projectItemForPropertiesPanel = undefined;
      state.projectPlanEditable = false;
      state.appMode = action.payload;
    },
    resetMapMode: (state) => {
      state.projectItemForPropertiesPanel = undefined;
      state.mapDrawMode = { ...MAPBOX_DRAW_DEFAULT_MODE };
      state.mapMode = undefined;
    },

    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewMode = action.payload;
    },
    setMapDrawMode: (state, action: PayloadAction<MapBoxDrawModes>) => {
      state.mapDrawMode = action.payload;
    },
    resetMapDrawMode: (state) => {
      state.mapDrawMode = { ...MAPBOX_DRAW_DEFAULT_MODE };
    },
    setActiveToolbarTool: (state, action: PayloadAction<SoundSourceToolId>) => {
      state.activeSoundSourceDrawTool = action.payload;
    },
    resetActiveToolbarTool: (state) => {
      state.activeSoundSourceDrawTool = null;
    },
    setProjectItemForPropertiesPanel: (state, action: PayloadAction<Feature>) => {
      state.projectItemForPropertiesPanel = action.payload;
    },
    resetProjectItemForPropertiesPanel: (state) => {
      state.projectItemForPropertiesPanel = undefined;
    },
    setFocusedReceiverIdInPropertiesPanel: (state, action: PayloadAction<string>) => {
      state.focusedReceiverIdInPropertiesPanel = action.payload;
    },
    setProjectPlanEditable: (state, action: PayloadAction<boolean>) => {
      state.projectPlanEditable = action.payload;
    },
    setUploadProjectPlanDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.uploadProjectPlanDialogOpen = action.payload;
    },
    setScaleModeProjectPlan: (state, action: PayloadAction<boolean>) => {
      state.scaleModeProjectPlan = action.payload;
    },
    setOutsideNoiseCriticalityTimeSpan: (state, action: PayloadAction<CriticalityTimeSpan>) => {
      state.outsideNoiseCriticalityTimeSpan = action.payload;
    },
    setNoiseEmissionsCriticalityTimeSpan: (state, action: PayloadAction<CriticalityTimeSpan>) => {
      state.noiseEmissionsCriticalityTimeSpan = action.payload;
    },
    setCurrentLiveEditedMapBoxDrawFeatureId: (state, action: PayloadAction<string>) => {
      state.currentLiveEditedMapBoxDrawFeatureId = action.payload;
    },
    resetCurrentLiveEditedMapBoxDrawFeatureId: (state) => {
      state.currentLiveEditedMapBoxDrawFeatureId = undefined;
    },
  },
});

export const {
  resetState: resetMapState,
  setAppMode: switchToAppMode,
  setMapMode: switchToMapMode,
  setViewMode,
  resetMapMode,
  setMapDrawMode: switchToMapDrawMode,
  resetMapDrawMode,
  setActiveToolbarTool: switchToToolbarTool,
  resetActiveToolbarTool: resetToolbar,
  setProjectItemForPropertiesPanel: openProjectItemPropertiesPanel,
  resetProjectItemForPropertiesPanel: closeProjectItemPropertiesPanel,
  setFocusedReceiverIdInPropertiesPanel: openReceiverDetailsInPropertiesPanel,
  setProjectPlanEditable,
  setUploadProjectPlanDialogOpen,
  setScaleModeProjectPlan,
  setNoiseEmissionsCriticalityTimeSpan,
  setOutsideNoiseCriticalityTimeSpan,
  setCurrentLiveEditedMapBoxDrawFeatureId,
  resetCurrentLiveEditedMapBoxDrawFeatureId,
} = slice.actions;

export default slice.reducer;
