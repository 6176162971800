import { styled } from '@mui/material/styles';
import { ReactComponent as DBelLogoBigIcon } from '../icons/dBelLogoBig.svg';

const SplashScreenBackground = styled('div')(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'background.paper',
    height: '100vh',
  })
);

const SplashScreen = () => (
  <SplashScreenBackground>
    <DBelLogoBigIcon sx={{ fontSize: 100 }} />
  </SplashScreenBackground>
);

export default SplashScreen;
