import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CustomDialogTitle } from '@dbel/react-commons/components';
import i18n from 'i18next';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { emissionModelCalculatorService, isCustomPointEmissionModel } from '@dbel/shared/emission-models';
import { PointSoundSource, SoundPowerLevel } from '@dbel/shared/types';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup.js';
import { calcSumLevel } from '@dbel/shared/util';
import { MIN_HEIGHT, MAX_HEIGHT, MIN_DB, MAX_DB } from './types';
import { toFixedNumber } from '../../../utils/formatters';

type FormFields = {
  height: number;
  dBANight: number;
  dBADay: number;
};

const formValidationSchema = Yup.object().shape({
  height: Yup.number()
    .label(i18n.t('common.labels.height'))
    .transform((value) => (Number.isNaN(value) ? null : value))
    .required(i18n.t('errors.formRequired', { property: i18n.t('common.labels.height') }))
    .min(MIN_HEIGHT, i18n.t('errors.formMinValue', { property: i18n.t('common.labels.height'), value: MIN_HEIGHT }))
    .max(MAX_HEIGHT, i18n.t('errors.formMaxValue', { property: i18n.t('common.labels.height'), value: MAX_HEIGHT }))
    .nullable(),
  dBANight: Yup.number()
    .label(i18n.t('pages.architect.project.panels.dBANight'))
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(
      MIN_DB,
      i18n.t('errors.formMinValue', { property: i18n.t('pages.architect.project.panels.dBANight'), value: MIN_DB })
    )
    .max(
      MAX_DB,
      i18n.t('errors.formMaxValue', { property: i18n.t('pages.architect.project.panels.dBANight'), value: MAX_DB })
    )
    .nullable(),
  dBADay: Yup.number()
    .label(i18n.t('pages.architect.project.panels.dBADay'))
    .transform((value) => (Number.isNaN(value) ? null : value))
    .min(
      MIN_DB,
      i18n.t('errors.formMinValue', { property: i18n.t('pages.architect.project.panels.dBADay'), value: MIN_DB })
    )
    .max(
      MAX_DB,
      i18n.t('errors.formMaxValue', { property: i18n.t('pages.architect.project.panels.dBADay'), value: MAX_DB })
    )
    .nullable(),
});

function getFormDefaultValues(soundSource: PointSoundSource): FormFields {
  const { emissionModel } = soundSource.properties;

  const { day, night } = emissionModel
    ? emissionModelCalculatorService.calculate(emissionModel)
    : { day: undefined, night: undefined };

  return {
    height: soundSource?.geometry.coordinates[2] ?? 1,
    dBADay: day ? toFixedNumber(calcSumLevel(day), 2) : 0,
    dBANight: night ? toFixedNumber(calcSumLevel(night), 2) : 0,
  };
}

interface CustomSoundSourcePropertiesDialogProps {
  open: boolean;
  onSave: (updatedGenericPointSoundSource: PointSoundSource) => void;
  onCancel: () => void;
  soundSource: PointSoundSource;
}

export const SimpleSoundSourcePropertiesDialog = ({
  open,
  onSave,
  onCancel,
  soundSource,
}: CustomSoundSourcePropertiesDialogProps) => {
  const { t } = useTranslation();
  const [useDayValue, setUseDayValue] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid: isFormValid },
  } = useForm<FormFields>({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(formValidationSchema),
    defaultValues: getFormDefaultValues(soundSource),
  });

  const handleOnCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    const { emissionModel } = soundSource.properties;
    if (isCustomPointEmissionModel(emissionModel)) {
      if (emissionModel.fixedSoundPowerLevel?.day === emissionModel.fixedSoundPowerLevel?.night) {
        setUseDayValue(true);
      } else {
        setUseDayValue(false);
      }
    }
  }, [soundSource.properties]);

  const onFormSubmit = useCallback<SubmitHandler<FormFields>>(
    ({ height, dBADay, dBANight }) => {
      const updatedSoundSource = cloneDeep(soundSource);

      // set sound source height according to user input as z dimension
      updatedSoundSource.geometry.coordinates[2] = height;

      const { emissionModel } = updatedSoundSource.properties;

      if (isCustomPointEmissionModel(emissionModel)) {
        emissionModel.fixedSoundPowerLevel = {
          day: dBADay as SoundPowerLevel,
          night: (useDayValue ? dBADay : dBANight) as SoundPowerLevel,
        };
      }
      setIsLoading(true);
      onSave(updatedSoundSource);
    },
    [onSave, soundSource, useDayValue]
  );

  useEffect(() => {
    reset(getFormDefaultValues(soundSource));
  }, [soundSource, reset]);

  return (
    <>
      <Dialog open={open} onClose={handleOnCancel} fullWidth maxWidth="sm" aria-labelledby="form-dialog-title">
        <form noValidate onSubmit={handleSubmit(onFormSubmit)}>
          <CustomDialogTitle onClickClose={handleOnCancel}>
            {t('pages.architect.project.panels.soundSourcePropertiesTitle')}
          </CustomDialogTitle>
          <DialogContent dividers>
            <Stack direction="column" spacing={2}>
              <TextField
                {...register('height', {
                  valueAsNumber: true,
                })}
                id="height"
                label={t('pages.architect.project.panels.heightAboveGroundLabel')}
                required
                type="number"
                size="small"
                error={errors.height !== undefined}
                helperText={errors.height?.message}
                inputProps={{
                  min: MIN_HEIGHT,
                  max: MAX_HEIGHT,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('common.units.meter')}</InputAdornment>,
                }}
              />
              <Typography variant="h6">{t('pages.architect.project.panels.soundPowerLevelsTitle')}</Typography>
              <Typography>{t('pages.architect.project.panels.simpleSoundPowerLevelsBody')}</Typography>

              <TextField
                {...register('dBADay', {
                  valueAsNumber: true,
                })}
                id="dBADay"
                label={t('pages.architect.project.panels.dBADay')}
                type="number"
                size="small"
                error={errors.dBADay !== undefined}
                helperText={errors.dBADay?.message}
                inputProps={{
                  min: MIN_DB,
                  max: MAX_DB,
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('common.units.dBA')}</InputAdornment>,
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={useDayValue}
                    onChange={() => setUseDayValue(!useDayValue)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={`${t('pages.architect.project.panels.nightValueCheck')}`}
              />
              {!useDayValue && (
                <TextField
                  {...register('dBANight', {
                    valueAsNumber: true,
                  })}
                  id="dBANight"
                  label={t('pages.architect.project.panels.dBANight')}
                  type="number"
                  size="small"
                  error={errors.dBANight !== undefined}
                  helperText={errors.dBANight?.message}
                  inputProps={{
                    min: MIN_DB,
                    max: MAX_DB,
                  }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t('common.units.dBA')}</InputAdornment>,
                  }}
                />
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleOnCancel}>
              {t('common.buttonLabels.cancel')}
            </Button>
            <LoadingButton loading={isLoading} color="primary" type="submit" disabled={!isFormValid}>
              {t('common.buttonLabels.save')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
