import {
  Box,
  Stack,
  SvgIcon,
  SvgIconProps,
  Theme,
  ToggleButton,
  ToggleButtonProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { ReactComponent as MainMenuModeSwitcherSeparatorIcon } from '../../../icons/MainMenuModeSwitcherSeparator.svg';

export interface ModeSwitcherButtonProps<T> {
  value: T;
  text: string;
  showAs?: 'ICON' | 'TEXT';
  icon?: ReactElement<SvgIconProps, typeof SvgIcon>;
  itemDivider?: boolean;
}

// TODO: use a proper standard react function component if this is fixed
// see https://github.com/mui/material-ui/issues/21026
export const ModeSwitcherItem = <T,>({
  text,
  icon,
  itemDivider,
  showAs = 'TEXT',
  ...props
}: ModeSwitcherButtonProps<T> & ToggleButtonProps) => (
  <>
    <Tooltip title={showAs === 'ICON' ? text : ''}>
      <ToggleButton
        {...props}
        sx={{
          border: 0,
        }}
      >
        <Stack direction="row">
          {showAs === 'ICON' && (
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {icon}
            </Box>
          )}

          {showAs === 'TEXT' && (
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                textTransform: 'none',
                px: 0.5,
              }}
            >
              {text}
            </Typography>
          )}
        </Stack>

        {itemDivider && (
          <MainMenuModeSwitcherSeparatorIcon
            sx={{
              height: '100%',
              width: '7px',
              stroke: (theme: Theme) => theme.palette.divider,
              color: 'background.paper',
              position: 'absolute',
              right: '-2px',
              top: '0',
              bottom: '0',
            }}
          />
        )}
      </ToggleButton>
    </Tooltip>
  </>
);
