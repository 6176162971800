import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useGetImageFeatureQuery, useUpdateImageFeatureMutation } from '@dbel/react-commons/api';
import { ToolbarTool } from '@dbel/react-commons/types';
import { MapBoxMapToolbarGroup, MapBoxMapToolbarItem } from '@dbel/react-commons/components';
import { useToolbars } from '../../../contexts/ToolbarContext';
import { useProjectIdFromUrl } from '../../../hooks/useProjectIdFromUrl';
import { ReactComponent as DrawBuildingIcon } from '../../../icons/toolbars/DrawBuilding.svg';
import { ReactComponent as DrawLineIcon } from '../../../icons/toolbars/DrawLine.svg';
import { ReactComponent as DrawWallIcon } from '../../../icons/toolbars/DrawWall.svg';
import { ReactComponent as DrawSelectIcon } from '../../../icons/toolbars/DrawSelect.svg';
import { ReactComponent as HideIcon } from '../../../icons/toolbars/HidePlan.svg';
import { ReactComponent as LockIcon } from '../../../icons/toolbars/LockPlan.svg';
import { ReactComponent as ScalePlanIcon } from '../../../icons/toolbars/ScalePlan.svg';
import { ReactComponent as ShowIcon } from '../../../icons/toolbars/ShowPlan.svg';
import { ReactComponent as UnlockIcon } from '../../../icons/toolbars/UnlockPlan.svg';
import { ReactComponent as UploadPlanIcon } from '../../../icons/toolbars/UploadPlan.svg';
import {
  resetMapDrawMode,
  setProjectPlanEditable,
  setScaleModeProjectPlan,
  setUploadProjectPlanDialogOpen,
  switchToMapDrawMode,
} from '../../../store/slices/map';
import { RootState, useDispatch, useSelector } from '../../../store/store';

type PlanToolId = 'upload-plan' | 'lock-plan' | 'view-plan' | 'scale-plan';
type MapToolId = 'select' | 'edit-building' | 'edit-wall';

export interface BuildingsToolbarProps {
  hideUploadPlanTool?: boolean;
  hideEditPlanTool?: boolean;
  hideScalePlanTool?: boolean;
}

export const BuildingsToolbar = ({
  hideUploadPlanTool,
  hideEditPlanTool,
  hideScalePlanTool,
}: BuildingsToolbarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { projectToolbarRef } = useToolbars();

  const { mode: currentDrawMode } = useSelector((state: RootState) => state.map.mapDrawMode);

  const projectId = useProjectIdFromUrl();
  const { data: planImageFeature } = useGetImageFeatureQuery(projectId);
  const [updateImageFeature] = useUpdateImageFeatureMutation();

  const projectPlanEditable = useSelector((state: RootState) => state.map.projectPlanEditable);
  const uploadProjectPlanDialogOpen = useSelector((state: RootState) => state.map.uploadProjectPlanDialogOpen);
  const scaleModeProjectPlan = useSelector((state: RootState) => state.map.scaleModeProjectPlan);

  const activeMapTool = useMemo<ToolbarTool<MapToolId>>(() => {
    if (currentDrawMode === 'draw_line_string') {
      return 'edit-wall';
    }
    if (currentDrawMode === 'draw_polygon') {
      return 'edit-building';
    }
    return 'select';
  }, [currentDrawMode]);

  const handleChangePlanTool = useCallback(
    (newTool: ToolbarTool<PlanToolId>) => {
      switch (newTool) {
        case 'upload-plan': {
          dispatch(setUploadProjectPlanDialogOpen(true));
          break;
        }
        case 'lock-plan': {
          if (!projectPlanEditable) {
            dispatch(setProjectPlanEditable(true));
            dispatch(setScaleModeProjectPlan(false));
          } else {
            dispatch(setProjectPlanEditable(false));
            dispatch(resetMapDrawMode());
          }
          break;
        }
        case 'view-plan': {
          const updatedPlanImageFeature = cloneDeep(planImageFeature);
          updatedPlanImageFeature.properties.visible = !planImageFeature?.properties.visible;
          updateImageFeature({ projectId, imageFeature: updatedPlanImageFeature });
          break;
        }
        case 'scale-plan': {
          dispatch(setProjectPlanEditable(false));
          dispatch(resetMapDrawMode());
          if (!scaleModeProjectPlan) {
            dispatch(setScaleModeProjectPlan(true));
          } else {
            dispatch(setScaleModeProjectPlan(false));
          }
          break;
        }
        default:
          break;
      }
    },
    [dispatch, planImageFeature, projectId, projectPlanEditable, scaleModeProjectPlan, updateImageFeature]
  );

  const handleChangeMapTool = useCallback(
    (newTool: ToolbarTool<MapToolId>) => {
      dispatch(setProjectPlanEditable(false));
      switch (newTool) {
        case 'select': {
          dispatch(
            switchToMapDrawMode({
              mode: 'simple_select',
            })
          );
          break;
        }
        case 'edit-wall': {
          dispatch(
            switchToMapDrawMode({
              mode: 'draw_line_string',
            })
          );
          break;
        }
        case 'edit-building': {
          dispatch(
            switchToMapDrawMode({
              mode: 'draw_polygon',
            })
          );
          break;
        }
        default: {
          dispatch(resetMapDrawMode());
        }
      }
    },
    [dispatch]
  );

  return createPortal(
    <>
      <MapBoxMapToolbarGroup<PlanToolId>
        onChangeTool={handleChangePlanTool}
        caption={t('pages.architect.project.toolbar.groups.map')}
      >
        {!hideUploadPlanTool && (
          <MapBoxMapToolbarItem<PlanToolId>
            value="upload-plan"
            icon={<UploadPlanIcon />}
            selected={uploadProjectPlanDialogOpen}
            tooltip={t('pages.architect.project.map.importPlanToolTip')}
          />
        )}
        {!hideEditPlanTool && planImageFeature && (
          <MapBoxMapToolbarItem<PlanToolId>
            value="lock-plan"
            icon={projectPlanEditable ? <UnlockIcon /> : <LockIcon />}
            tooltip={
              projectPlanEditable
                ? t('pages.architect.project.map.lockPlanToolTip')
                : t('pages.architect.project.map.unlockPlanToolTip')
            }
          />
        )}
        {planImageFeature && (
          <MapBoxMapToolbarItem<PlanToolId>
            value="view-plan"
            icon={planImageFeature?.properties.visible ? <HideIcon /> : <ShowIcon />}
            tooltip={
              planImageFeature?.properties.visible
                ? t('pages.architect.project.map.hidePlanToolTip')
                : t('pages.architect.project.map.showPlanToolTip')
            }
          />
        )}
        {!hideScalePlanTool && planImageFeature && (
          <MapBoxMapToolbarItem<PlanToolId>
            value="scale-plan"
            icon={<ScalePlanIcon />}
            selected={scaleModeProjectPlan}
            tooltip={t('pages.architect.project.map.scalePlanToolTip')}
          />
        )}
      </MapBoxMapToolbarGroup>

      <MapBoxMapToolbarGroup<MapToolId>
        activeTool={activeMapTool}
        onChangeTool={handleChangeMapTool}
        caption={t('pages.architect.project.toolbar.groups.tools')}
      >
        <MapBoxMapToolbarItem<MapToolId>
          value="select"
          icon={<DrawSelectIcon />}
          tooltip={t('pages.architect.project.map.selectToolTip')}
        />
        <MapBoxMapToolbarItem<MapToolId>
          value="edit-wall"
          icon={scaleModeProjectPlan ? <DrawLineIcon /> : <DrawWallIcon />}
          tooltip={
            scaleModeProjectPlan
              ? t('pages.architect.project.map.drawLineToolTip')
              : t('pages.architect.project.map.drawWallToolTip')
          }
        />
        {!scaleModeProjectPlan && (
          <MapBoxMapToolbarItem<MapToolId>
            value="edit-building"
            icon={<DrawBuildingIcon />}
            tooltip={t('pages.architect.project.map.drawBuildingToolTip')}
          />
        )}
      </MapBoxMapToolbarGroup>
    </>,
    projectToolbarRef.current
  );
};
